<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="7" md="6">
        <v-card class="elevation-12">
          <v-card-text>
            <v-col class="pa-0">
              Please enter all required (*) information below. For assistance contact <span style="white-space: nowrap;"><b>+1-321-830-3841 </b></span> or <a href="mailto:wholesale@gizmotrader.com">wholesale@gizmotrader.com</a>.
            </v-col>
          </v-card-text>
        </v-card>
        <v-form
          @keyup.native.enter="handleSubmit()"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>User Information</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      class="mr-3"
                      name="firstName"
                      label="First Name *"
                      type="text"
                      v-model="user.firstName"
                      :rules="rules.firstName"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      class="ml-3"
                      name="lastName"
                      label="Last Name *"
                      type="text"
                      v-model="user.lastName"
                      :rules="rules.lastName"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      class="mr-3"
                      name="email"
                      label="Email *"
                      type="text"
                      v-model="user.email"
                      :rules="rules.email"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      class="ml-3"
                      name="phone"
                      label="Phone *"
                      type="text"
                      v-model="user.phone"
                      :rules="rules.phone"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      class="mr-3"
                      name="whatsAppNumber"
                      label="WhatsApp Number"
                      type="text"
                      v-model="user.whatsAppNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      class="ml-3"
                      name="company"
                      label="Company"
                      type="text"
                      v-model="user.company"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Address Information</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    label="Shipping Address is the same as the Business Address"
                    v-model="shippingSameAsBusiness"
                  />
                </v-col>
                <v-col cols="6">
                  <span><b>
                    *PLEASE NOTE: The address you submit here is only used to evaluate your sales tax exemption status.  Once your account is approved, you will be asked to confirm your address during the checkout process.
                  </b></span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pr-3" style="border-right: 1px solid black">
                  <h2 style="text-align: center">Business</h2>
                  <v-text-field
                      name="address1"
                      label="Address 1 *"
                      type="text"
                      v-model="user.address1"
                      :rules="rules.address"
                      required
                  ></v-text-field>
                  <v-text-field
                      name="address2"
                      label="Address 2"
                      type="text"
                      v-model="user.address2"
                  ></v-text-field>
                  <v-text-field
                      name="address3"
                      label="Address 3"
                      type="text"
                      v-model="user.address3"
                  ></v-text-field>
                  <v-text-field
                      name="city"
                      label="City *"
                      type="text"
                      v-model="user.city"
                      :rules="rules.city"
                      required
                  ></v-text-field>
                  <v-text-field
                      name="state"
                      label="State *"
                      type="text"
                      v-model="user.state"
                      :rules="rules.state"
                      required
                  ></v-text-field>
                  <v-text-field
                      name="zip"
                      label="Zip *"
                      type="text"
                      v-model="user.zip"
                      :rules="rules.zip"
                      required
                  ></v-text-field>
                  <v-autocomplete
                      type="text"
                      id="businessCountry"
                      name="country"
                      label="Country *"
                      v-model="user.country"
                      :rules="rules.country"
                      required
                      :items="countriesList"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="pl-3" style="border-left: 1px solid black">
                  <h2 style="text-align: center">Shipping</h2>
                  <v-text-field
                      name="shippingAddress1"
                      label="Address 1 *"
                      type="text"
                      v-model="user.shippingAddress1"
                      :rules="rules.address"
                      required
                      :disabled="shippingSameAsBusiness"
                  ></v-text-field>
                  <v-text-field
                      name="shippingAddress2"
                      label="Address 2"
                      type="text"
                      v-model="user.shippingAddress2"
                      :disabled="shippingSameAsBusiness"
                  ></v-text-field>
                  <v-text-field
                      name="shippingAddress3"
                      label="Address 3"
                      type="text"
                      v-model="user.shippingAddress3"
                      :disabled="shippingSameAsBusiness"
                  ></v-text-field>
                  <v-text-field
                      name="shippingCity"
                      label="City *"
                      type="text"
                      v-model="user.shippingCity"
                      :disabled="shippingSameAsBusiness"
                      :rules="rules.city"
                      required
                  ></v-text-field>
                  <v-text-field
                      name="shippingState"
                      label="State *"
                      type="text"
                      v-model="user.shippingState"
                      :rules="rules.state"
                      required
                      :disabled="shippingSameAsBusiness"
                  ></v-text-field>
                  <v-text-field
                      name="shippingZip"
                      label="Zip *"
                      type="text"
                      v-model="user.shippingZip"
                      :rules="rules.zip"
                      required
                      :disabled="shippingSameAsBusiness"
                  ></v-text-field>
                  <v-autocomplete
                      name="shippingCountry"
                      id="shippingCountry"
                      label="Country *"
                      v-model="user.shippingCountry"
                      :disabled="shippingSameAsBusiness"
                      :rules="rules.country"
                      required
                      :items="countriesList"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Wholesale Account</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                  name="login"
                  label="Username *"
                  type="text"
                  v-model="user.username"
                  :rules="rules.username"
                  required
              ></v-text-field>
              <v-text-field
                  name="password"
                  label="Password *"
                  id="password"
                  type="password"
                  v-model="user.password"
                  :rules="rules.password"
                  required
              ></v-text-field>
              <v-text-field
                  name="confirmPassword"
                  label="Confirm Password *"
                  id="confirmPassword"
                  type="password"
                  v-model="user.confirmPassword"
                  :rules="rules.confirmPassword"
                  required
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-toolbar color="primary" dark>
            <v-toolbar-title>Questions</v-toolbar-title>
          </v-toolbar>
          <v-card class="elevation-12">
            <v-card-text>
              <div class="form-question">
                  If required, would you be able to supply a reseller certificate for the state where you ship your items (sales tax exemption)? *
                  <v-select
                    class="apply-select"
                    name="questionResellerCert"
                    label="Response"
                    id="questionResellerCert"
                    :items="['Yes', 'No']"
                    v-model="user.application.questionResellerCert"
                    :rules="rules.question"
                    required
                  ></v-select>
              </div>
              <div class="form-question">
                  Are you a reseller or an end-user? *
                  <v-select
                    class="apply-select"
                    name="questionResellerEndUser"
                    label="Response"
                    id="questionResellerEndUser"
                    :items="['Reseller', 'End-user']"
                    v-model="user.application.questionResellerEndUser"
                    :rules="rules.question"
                    required
                  ></v-select>
              </div>
              <div class="form-question">
                  Are you shipping internationally through a freight forwarding company? *
                  <v-select
                    class="apply-select"
                    name="questionFFW"
                    label="Response"
                    id="questionFFW"
                    :items="['Yes', 'No']"
                    v-model="user.application.questionFFW"
                    :rules="rules.question"
                    required
                  ></v-select>
              </div>
              <div class="form-question">
                How many phones do you plan to purchase per month? *
                <v-text-field
                    autocomplete="nope"
                    name="questionNumOfPhones"
                    label="Response"
                    id="questionNumOfPhones"
                    type="number"
                    v-model="user.application.questionNumOfPhones"
                    :rules="rules.question"
                    required
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-text>
                &nbsp;&nbsp;&nbsp;&nbsp;* Required Field
              <v-card-actions>
                <v-btn color="error" @click="resetForm()">Reset Form</v-btn>
                <v-btn color="primary" @click="handleSubmit()">Submit Application</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { router } from '@/router';
import countriesMixin from '@/_mixins/countriesMixin';

export default {
  name: 'application',
  components: {
  },
  mixins: [
    countriesMixin
  ],
  data () {
    return {
      valid: true,
      shippingSameAsBusiness: false,
      rules: {
        username: [
          v => !!v || 'Username is required. Example: johndoe123',
          v => /^\w+$/g.test(v) || 'Username must only contain letters and numbers.'
        ],
        firstName: [
          v => !!v || 'First Name is required. Example: John',
          v => /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(v) || 'First Name must be valid input. Example: John' // https://andrewwoods.net/blog/2018/name-validation-regex/
        ],
        lastName: [
          v => !!v || 'Last Name is required. Example: Doe',
          v => /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(v) || 'Last Name must be valid input. Example: Doe' // https://andrewwoods.net/blog/2018/name-validation-regex/
        ],
        email: [
          v => !!v || 'Email is required. Example: john.doe@company.com',
          v => /.+@.+/.test(v) || 'Email must be valid. Example: john.doe@company.com'
        ],
        address: [
          v => !!v || 'Address Field is required. Example: 123 Anywhere St.'
        ],
        // taxRate: [
        //   v => !!v || 'Tax Rate % is required. Example: 6.5 or 0.0 if Tax Exempt',
        //   v => /^[\d.]+$/.test(v) || 'Tax Rate % must be valid. Example: 6.5 or 0.0 if Tax Exempt'
        // ],
        country: [
          v => !!v || 'Country is required.'
        ],
        city: [
          v => !!v || 'City is required. Example: Orlando'
        ],
        state: [
          v => !!v || 'State is required. Example: FL'
        ],
        zip: [
          v => !!v || 'Zip Code is required. Example: 32826'
        ],
        phone: [
          v => !!v || 'Phone Number is required. Example: +1-321-830-3841 '
        ],
        password: [
          v => !!v || 'Password is required.'
        ],
        confirmPassword: [
          v => !!v || 'Password Confirmation is required.',
          v => v === this.user.password || 'Passwords must match'
        ],
        question: [
          v => !!v || 'Question response is required.'
        ]
      },
      user: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        company: null,
        phone: '',
        whatsAppNumber: '',
        address1: '',
        address2: null,
        address3: null,
        country: '',
        city: '',
        state: '',
        zip: '',
        shippingAddress1: '',
        shippingAddress2: null,
        shippingAddress3: null,
        shippingCountry: '',
        shippingCity: '',
        shippingState: '',
        shippingZip: '',
        password: '',
        confirmPassword: '',
        application: {
          questionResellerCert: '',
          questionResellerEndUser: '',
          questionFFW: '',
          questionNumOfPhones: ''
        },
        overrideSettings: {
          cartMaxLimit: null,
          directPaymentMaxLimit: null,
          minimumOrderQtyThreshold: null,
          minimumOrderQtyShppingFee: null
        }
      },
      submitted: false
    };
  },
  mounted () {
    // Workaround for a bug in vuetify. Normally vuetify puts autocomplete: off on v-autocomplete
    // components but chrome changed the word to "nope" so this is just setting that attribute
    document.getElementById('businessCountry').setAttribute('autocomplete', 'nope');
    document.getElementById('shippingCountry').setAttribute('autocomplete', 'nope');
  },
  watch: {
    shippingSameAsBusiness () {
      if (this.shippingSameAsBusiness) {
        this.user.shippingAddress1 = this.user.address1;
        this.user.shippingAddress2 = this.user.address2;
        this.user.shippingAddress3 = this.user.address3;
        this.user.shippingCity = this.user.city;
        this.user.shippingState = this.user.state;
        this.user.shippingZip = this.user.zip;
        this.user.shippingCountry = this.user.country;
      }
    },
    /**
     * Watchers for address. If business same as shipping is
     * selected then update the business fields as the others
     * get updated
     */
    'user.address1': function (newValue) {
      if (this.shippingSameAsBusiness) {
        this.user.shippingAddress1 = this.user.address1;
      }
    },
    'user.address2': function (newValue) {
      if (this.shippingSameAsBusiness) {
        this.user.shippingAddress2 = this.user.address2;
      }
    },
    'user.address3': function (newValue) {
      if (this.shippingSameAsBusiness) {
        this.user.shippingAddress3 = this.user.address3;
      }
    },
    'user.country': function (newValue) {
      if (this.shippingSameAsBusiness) {
        this.user.shippingCountry = this.user.country;
      }
    },
    'user.city': function (newValue) {
      if (this.shippingSameAsBusiness) {
        this.user.shippingCity = this.user.city;
      }
    },
    'user.state': function (newValue) {
      if (this.shippingSameAsBusiness) {
        this.user.shippingState = this.user.state;
      }
    },
    'user.zip': function (newValue) {
      if (this.shippingSameAsBusiness) {
        this.user.shippingZip = this.user.zip;
      }
    }
  },
  methods: {
    ...mapActions('users', ['submitApplication']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    clearUser () {
      this.user = {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        company: null,
        phone: '',
        whatsAppNumber: '',
        address1: '',
        address2: null,
        address3: null,
        country: '',
        city: '',
        state: '',
        zip: '',
        shippingAddress1: '',
        shippingAddress2: null,
        shippingAddress3: null,
        shippingCountry: '',
        shippingCity: '',
        shippingState: '',
        shippingZip: '',
        password: '',
        confirmPassword: '',
        application: {
          questionResellerCert: '',
          questionResellerEndUser: '',
          questionFFW: '',
          questionNumOfPhones: ''
        },
        overrideSettings: {
          cartMaxLimit: null,
          directPaymentMaxLimit: null,
          minimumOrderQtyThreshold: null,
          minimumOrderQtyShppingFee: null
        }
      };
    },
    resetForm () {
      this.clearUser();
      this.resetValidation();
      // this.$refs.form.reset();
    },
    clearErrors () {
      this.clearAlert();
      this.resetValidation();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    handleSubmit (e) {
      this.submitted = true;
      if (this.$refs.form.validate()) {
        this.submitApplication(this.user).then((response) => {
          this.clearUser();
          router.push('/apply-success');
        }).catch((err) => {
          // this.errorAlert("Error: " + err);
        });
      } else {
        this.errorAlert('Invalid inputs! Please resolve and try again.');
      }
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

.form-question {
  margin-top: 16px;
  margin-bottom: 16px;
}

.apply-select {
  min-width: 200px;
}

</style>
