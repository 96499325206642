<template>
  <v-container fluid id="accessories-page">
    <!-- Invalid Products Dialog data is provided by cart mixin-->
    <InvalidProductsDialog :invalidProducts="invalidProducts" @updateDialog_invalidProducts="handleInvalidDialog($event)"/>

    <ProductsDialog
      :showDialog="showMediaDialog"
      width="1000px"
      type="media"
      :product="mediaProduct"
      @updateDialog_media="showMediaDialog = $event"
      @updateQuantity="updateQuantityFromDialog($event)"
      :fullscreen="$vuetify.breakpoint.mobile"
    />

    <FilterProductsDialog
      v-if="showFilterDialog"
      :showDialog="showFilterDialog"
      title='Filter Accessories'
      :fullscreen="$vuetify.breakpoint.mobile"
      @filterDialog_apply="onFiltersUpdate($event)"
      @filterDialog_clear="onFiltersClear()"
      @filterDialog_cancel="onFiltersCancel()"
      :products="allProducts"
      :filterModel="filterModel"
      type="accessories"
    />

    <div id="mobileControls" class="px-3 mt-2" v-if="$vuetify.breakpoint.mobile">
      <v-row class="px-3">
        <v-text-field
          label="Search Accessories"
          v-model="searchFilter"
          outlined
          clearable
          dense
        ></v-text-field>
      </v-row>
      <v-row class="px-3">
        <v-select
          class="mt-1"
          style="min-width: 100%;"
          v-model="cardSort"
          :items="cardSorts"
          label="Sort"
          outlined
          chips
          deletable-chips
          dense
        ></v-select>
      </v-row>
      <v-row class="justify-space-around mt-0">
        <v-btn
          v-if="!userIsReadOnly"
          :disabled="countSelected === 0"
          color="pink"
          class="white--text"
          @click="onAddToCart">
            <span class="d-flex d-md-none"><v-icon left>add_shopping_cart</v-icon>{{ formattedPriceSelected }}</span>
        </v-btn>
        <v-btn :disabled="countSelected === 0" outlined @click="onClearSelection">Clear</v-btn>
        <v-btn outlined @click="showFilterDialog = true">
          <v-badge
            :content="filterModel.appliedFilters"
            :value="filterModel.appliedFilters"
            color="pink"
            offset-y="10px"
          >
            Filters
          </v-badge>
        </v-btn>
        <v-btn fab dark small color="pink" @click="loadAccessoryData">
            <v-icon dark>refresh</v-icon>
        </v-btn>
      </v-row>
      <v-row v-if="rowData && rowData.length > 0">
        <v-spacer></v-spacer>
        Showing {{rowData.length}} products.&nbsp;<a href="#" @click="onSelectAll()">Select All&nbsp;({{filteredProductsQuantity}})</a>
        <v-spacer></v-spacer>
      </v-row>
    </div>
    <div v-else id="desktopControls">
      <v-row class="justify-space-around">
        <v-col cols="6">
          <v-text-field
            label="Search Accessories"
            v-model="searchFilter"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-btn
          v-if="!userIsReadOnly"
          :disabled="countSelected === 0"
          color="pink"
          class="white--text mt-5"
          @click="onAddToCart">
            <span class="d-none d-md-flex">{{ addToCartText }}</span>
            <span class="d-flex d-md-none"><v-icon left>add_shopping_cart</v-icon>{{ formattedPriceSelected }}</span>
        </v-btn>
        <v-btn :disabled="countSelected === 0" outlined @click="onClearSelection" class="mt-5">Clear Selection</v-btn>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab dark small color="pink" @click="loadAccessoryData"  v-bind="attrs" v-on="on" class="mt-5">
                    <v-icon dark>refresh</v-icon>
                </v-btn>
            </template>
            <span>Clear All Filters</span>
        </v-tooltip>
      </v-row>
    </div>

    <v-row>
      <v-col cols="12" class="pt-0">
        <div id="accessories-table"  v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
          <ag-grid-vue
            style="width: 100%; height: 100%;"
            class="ag-theme-material"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :rowClassRules="rowClassRules"
            :rowData="rowData"
            :autoGroupColumnDef="autoGroupColumnDef"
            rowSelection="multiple"
            groupSelectsChildren="true"
            animateRows="true"
            suppressRowClickSelection="true"
            @selection-changed="onSelectionChanged"
            @cell-clicked="onCellClicked"
            @first-data-rendered="onFirstDataRendered"
            @row-group-opened="onRowGroupOpened"
          ></ag-grid-vue>
          <!-- @model-updated="onModelUpdated" -->
          <!-- @animation-queue-empty="onAnimationQueueEmpty" -->
        </div>
        <div id="mobileContent" class="pr-5" v-else>
          <div v-if="rowData && rowData.length > 0">
            <div v-for="(product, i) in rowData" :key="i">
              <v-lazy
                min-height="50"
                transition="fade-transition"
              >
                <v-card class="mb-5" cols="12">
                  <v-carousel
                    :cycle="cycle"
                    v-model="slider[product.sku]"
                    height="200px"
                    hide-delimiters
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                      v-for="(item,j) in carouselItems(product)"
                      :key="j"
                      :src="item.src"
                      :transition="cycle"
                      :reverse-transition="cycle"
                      @error="onErrorCarouselItem(product)"
                    >
                      <v-container class="centered" fill-height v-if="isMediaMissing(item.src)">
                        <v-row align-center>
                          <v-col cols="12">
                            <h1>
                              <span class="media-message">Photo No Longer Available</span>
                            </h1>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                  </v-carousel>
                  <v-card-title>
                    {{product.description}}
                  </v-card-title>
                  <v-card-subtitle>
                    <v-row class="px-1">
                      Price:{{formatCurrency(product.price)}}
                      <v-spacer></v-spacer>
                      <b>Quantity</b>:<v-select class="ml-1" v-model="product.quantity" :items="getQuantitySelectOptions(product.stock)" @change="onQuantyChange(product)" dense></v-select>
                      <v-spacer></v-spacer>
                      Available:{{product.stock}}
                    </v-row>
                    <v-row class="px-1">
                      Type:{{product.type}}
                    </v-row>
                  </v-card-subtitle>
                  <v-card-actions>
                    <input class="card-selected-box" type="checkbox" :checked="isCardSelected(product)" @click="onSelectCard(product)">
                    <v-spacer></v-spacer>

                    <v-btn
                      icon
                      @click="product.expanded = !product.expanded"
                    >
                      <v-icon>{{ product.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-actions>

                  <v-expand-transition>
                    <div v-show="product.expanded">
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-row>
                          <v-col cols="6">Brand: {{product.brand}}</v-col>
                          <v-col cols="6">Model: {{product.model}}</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">Color: {{product.color}}</v-col>
                          <v-col cols="6">Condition: {{product.condition}}</v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-lazy>
            </div>
          </div>
          <div class="text-center"
            v-if="rowData && rowData.length === 0">
            No products. Please update your <a href="#" @click="showFilterDialog = true">filters</a>
          </div>
        </div>
      </v-col>
    </v-row>
    <div id="mobilePagination" v-if="$vuetify.breakpoint.mobile">
      <v-pagination
        v-model="page"
        :length="paginationLength"
        :total-visible="paginationTotalVisible"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';

import { router } from '@/router';
import { crawlAndExpandRows } from '@/_helpers/grid-helper';

import formatterMixin from '@/_mixins/formatterMixin';
import cartMixin from '@/_mixins/cartMixin';
import ProductsDialog from '@/components/dialogs/ProductsDialog';
import InvalidProductsDialog from '@/components/dialogs/InvalidProductsDialog';
import FilterProductsDialog from '@/components/dialogs/FilterProductsDialog.vue';
import deepEqual from 'deep-equal';
import MobileFilterModel from '@/_resources/js/MobileFilterModel';

export default {
  name: 'accessories',
  components: {
    InvalidProductsDialog,
    ProductsDialog,
    FilterProductsDialog,
    AgGridVue
  },
  props: {
    source: String
  },
  mixins: [
    formatterMixin,
    cartMixin
  ],
  data () {
    return {
      searchFilter: '', // The global search filter
      filterModel: {}, // The filter model
      allProducts: null,
      drawer: true,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      columnFields: null,
      rowClassRules: null,
      rowData: null,
      mediaProduct: null,
      mediaProductRow: null,
      autoGroupColumnDef: null,
      countSelected: 0,
      priceSelected: 0,
      showMediaDialog: false,
      timer: null,
      window: {
        width: 0,
        height: 0
      },
      currentExpandedRows: {},
      showFilterDialog: false,
      skuToSelectedCard: {},
      cardSort: '',
      cardSorts: [
        'Price: High to Low',
        'Price: Low to High',
        'Availability: High to Low',
        'Availability: Low to High',
        'Storage: High to Low',
        'Storage: Low to High'
      ],
      carouselItemErrors: [],
      cycle: false,
      slider: {},
      filteredProductsQuantity: 0,
      paginationLength: 0,
      paginationTotalVisible: 6,
      pageSize: 25,
      page: 1
    };
  },
  created () {
    window.addEventListener('resize', this.handleResize);
    // this.handleResize();
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount () {
    this.gridOptions = {
      // turn on floating filters
      defaultColDef: {
        floatingFilter: true
      }

      // turn off column virtualization
      // https://www.ag-grid.com/javascript-grid-resizing/
      // suppressColumnVirtualisation: true
    };
    this.columnDefs = [
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        filter: true,
        rowGroup: true,
        hide: true
        // width: 160
      },
      {
        headerName: 'Description',
        field: 'description',
        sortable: true,
        filter: true,
        hide: true
        // width: 600
      },
      {
        headerName: 'Price',
        field: 'price',
        sortable: true,
        filter: true,
        valueFormatter: this.currencyFormatter,
        cellStyle: { textAlign: 'right' },
        width: 120
        // pinned: "left"
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        sortable: true,
        filter: true,
        // pinned: "left",
        cellStyle: { textAlign: 'center' },
        width: 120,
        cellRenderer: quantityCellRenderer,
        editable: (params) => {
          return parseInt(params.data.stock) > 1;
        },
        singleClickEdit: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          const stock = parseInt(params.data.stock);
          const quantityArray = [...Array(stock + 1).keys()].slice(1);
          return {
            values: quantityArray
          };
        },
        onCellValueChanged: () => {
          // recalculate selected QTY / Total.
          this.updateTotals();
        }
      },
      // {
      //   headerName: "Available",
      //   field: "stock",
      //   sortable: true,
      //   filter: true,
      //   // pinned: "left",
      //   cellStyle: {textAlign: "center"},
      //   width: 120
      // },
      {
        headerName: 'Media',
        field: 'sku',
        sortable: false,
        filter: false,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressMenu: true,
        width: 120,
        colId: 'media',
        cellRenderer: mediaCellRenderer
        // pinned: "left"
      }
    ];

    this.columnFields = this.columnDefs.map(columnDef => columnDef.field);
    this.columnFields.push('ag-Grid-AutoColumn');

    this.rowClassRules = {
      'media-product-row': (params) => {
        if (params.data !== undefined && params.data.isMediaProductRow !== undefined) {
          return params.data.isMediaProductRow;
        }
        return '';
      }
    };

    this.rowData = [];

    this.autoGroupColumnDef = {
      headerName: 'Select',
      field: 'description',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: true,
      sort: 'asc',
      sortingOrder: ['asc', 'desc'],
      width: 700,
      // pinned: "left",
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
        suppressCount: true,
        // Controls how the text in the cell will render. We want to customize it to control what "count" shows up.
        // the default count for ag grid is for the number of rows. We want the total stock for each row
        innerRenderer: (node) => {
          // If there are leaf nodes then get the total count
          if (node.node.allLeafChildren && node.node.allLeafChildren.length > 0) {
            const qty = node.node.allLeafChildren.reduce((accumulator, childNode) => accumulator + childNode.data.stock, 0);
            return `${node.value} (${qty})`;
          } else {
            return node.value;
          }
        }
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return caseInsensitiveSort(valueA, valueB, nodeA, nodeB, isInverted);
      }
    };
  },
  activated () {
    setTimeout(() => {
      this.autosizeColumns();
    }, 10);

    if (router.currentRoute.path === '/accessories' && this.$vuetify.breakpoint.mobile) {
      this.showFilterDialog = this.rowData && this.rowData.length === 0;
    }
  },
  computed: {
    ...mapGetters('account', ['userIsReadOnly']),
    greeting () {
      if (this.account && this.account.user.firstName !== null && this.account.user.lastName !== null) {
        return 'Hello, ' + this.account.user.firstName + ' ' + this.account.user.lastName + '!';
      }
      return '';
    },
    username () {
      return this.account.username;
    },
    mediaProductTitle () {
      if (this.mediaProduct !== null) {
        // return this.mediaProduct.model + " (" + this.mediaProduct.dbitemnum + ")";
        return this.mediaProduct.description;
      }
      return '';
    },
    mediaProductPrice () {
      if (this.mediaProduct !== null) {
        return this.formatCurrency(this.mediaProduct.price);
      }
      return '';
    },
    mediaProductNotes () {
      if (this.mediaProduct !== null) {
        return this.accessoriesBySku[this.mediaProduct.sku].notes;
      }
      return '';
    },
    addToCartText () {
      if (this.countSelected === 1) {
        return 'Add 1 Item To Cart: ' + this.formattedPriceSelected;
      }
      return 'Add ' + this.formattedCountSelected + ' Items To Cart: ' + this.formattedPriceSelected;
    },
    formattedCountSelected () {
      return this.numberWithCommas(this.countSelected);
    },
    formattedPriceSelected () {
      return this.formatCurrency(this.priceSelected);
    }
  },
  methods: {
    ...mapActions('products', ['loadProducts']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    onFirstDataRendered (params) {
      setTimeout(() => {
        this.autosizeColumns();
      }, 10);
    },
    onGridReady (params) {
      setTimeout(() => {
        this.autosizeColumns();
      }, 10);
    },
    /**
     * loads the expanded rows based on the current expanded rows object
     */
    loadExpandedRows () {
      if (this.gridApi) {
        // pass in root node which is the root of the grid. recursively crawl the grid and set expanded
        // where necessary
        crawlAndExpandRows(this.gridApi.rowModel.rootNode, this.currentExpandedRows);

        // "refresh" whether or not the rows are expanded. This allows
        // us to do it as a bulk update
        this.gridOptions.api.onGroupExpandedOrCollapsed();
        setTimeout(() => {
          this.autosizeColumns();
        }, 100);
      }
    },
    onRowGroupOpened (params) {
      // update object and write to local storage
      this.currentExpandedRows[params.node.key] = params.node.expanded;
      localStorage.setItem('accessoriesExpandedRows', JSON.stringify(this.currentExpandedRows));

      setTimeout(() => {
        this.autosizeColumns();
      }, 10);
    },
    autosizeColumns () {
      if (router.currentRoute.path === '/accessories') {
        this.autoSizeAll(false);
        if (this.window.width === 0) {
          this.window.width = window.innerWidth;
        }
        if (this.window.height === 0) {
          this.window.height = window.innerHeight;
        }
        if (this.window.width !== null && this.window.width >= 1920) {
          // this.sizeToFit();
        }
      }
    },
    autoSizeAll (skipHeader) {
      if (this.gridColumnApi) {
        this.gridColumnApi.autoSizeColumns(this.columnFields, skipHeader);
      }
    },
    sizeToFit () {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    handleResize () {
      if (router.currentRoute.path === '/accessories') {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        setTimeout(() => {
          this.autosizeColumns();
        }, 100);
      }
    },
    onModelUpdated (event) {
      this.autoSizeColumns();
    },
    loadAccessoryData () {
      if (this.gridApi) {
        // Clear any previously applied column filters
        this.gridApi.setFilterModel(null);
        this.gridApi.forEachNode(node => {
          if (node && node.group) {
            node.setExpanded(false);
          }
        });
      } else {
        // mobile, we need to clear filter model
        this.filterModel = new MobileFilterModel();
      }

      localStorage.removeItem('accessoriesExpandedRows');
      this.loadProducts();
    },
    updateQuantityFromDialog ({ sku, quantity }) {
      if (this.mediaProductRow !== null) {
        this.mediaProductRow.setDataValue('quantity', quantity);
      } else {
        // mobile devices done't have mediaProductRow
        const product = this.allProducts.filter(product => product.sku === sku)[0];
        if (product) {
          product.quantity = quantity;
        }
      }
    },
    processProducts () {
      // Clear accessories grid
      this.allProducts = [];

      // Get VALUES (products) from the accessoriesBySku map
      const products = Object.values(this.accessoriesBySku);

      products.forEach(obj => {
        // CHECK IF PRODUCT IS ALREADY IN CART
        // YES = IGNORE PRODUCT
        // NO = DISPLAY PRODUCT IN GRID
        const index = this.cartProducts.findIndex((e) => e.sku === obj.sku);

        let quantityInCart = 0;
        const serverStock = parseInt(obj.stock);
        if (index !== -1) {
          const cartProduct = this.cartProducts[index];
          quantityInCart = parseInt(cartProduct.quantity);
        }

        const stockAvailable = serverStock - quantityInCart;

        if (stockAvailable > 0) {
          const rowDataObj = {};

          rowDataObj.isMediaProductRow = false; // GOOD
          rowDataObj.sku = obj.sku;
          rowDataObj.type = obj.category3;
          rowDataObj.description = obj.genericdescription;
          rowDataObj.price = obj.wholesaleprice;
          rowDataObj.stock = stockAvailable;
          rowDataObj.quantity = 1; // Default to 1.
          rowDataObj.brand = obj.brand;
          rowDataObj.color = obj.color;
          rowDataObj.condition = obj.condition;
          rowDataObj.model = obj.model;
          rowDataObj.expanded = false;

          this.allProducts.push(rowDataObj);
          this.slider[rowDataObj.sku] = 0;
        }
      });

      this.refreshData();
      if (router.currentRoute.path === '/accessories') {
        this.loadingOverlay(false);
      }
    },
    onCellClicked (event) {
      // Clear OLD highlighted row
      if ((event.column.getColId() === 'media') && event.data !== undefined) {
        // Clear OLD highlighted row
        if (this.mediaProductRow !== null && this.mediaProduct !== null) {
          this.mediaProduct.isMediaProductRow = false;
          this.gridApi.redrawRows({ rowNodes: [this.mediaProductRow] });
          this.mediaProductRow = null;
          this.mediaProduct = null;
        }

        // Highlight NEW Row
        this.mediaProductRow = event.node;
        this.mediaProduct = this.mediaProductRow.data;
        this.mediaProduct.isMediaProductRow = true;
        this.gridApi.redrawRows({ rowNodes: [this.mediaProductRow] });

        if (event.column.getColId() === 'media') {
          this.showMediaDialog = true;
        }
      }
    },
    onAddToCart () {
      const selectedItems = this.gridApi ? this.gridApi.getSelectedRows() : Object.values(this.skuToSelectedCard);

      // create input array for addToCart method
      const skuQuantityObjArray = selectedItems.map(p => {
        return {
          sku: p.sku,
          qty: p.quantity
        };
      });

      this.addToCart(skuQuantityObjArray);
      this.onClearSelection();
    },
    removeSelectedProducts () {
      if (this.gridApi) {
        // REMOVE FROM GRID
        const selectedData = this.gridApi.getSelectedRows();

        this.gridApi.updateRowData({ remove: selectedData });

        // REMOVE FROM ALL PRODUCTS
        selectedData.forEach((product) => {
          this.allProducts.splice(this.allProducts.findIndex(item => item.sku === product.sku), 1);
        });
      }

      this.countSelected = 0;
      this.priceSelected = 0;
    },
    onClearSelection () {
      this.countSelected = 0;
      this.priceSelected = 0;
      if (this.gridApi) {
        this.gridApi.deselectAll();
      } else {
        this.skuToSelectedCard = {};
      }
    },
    onSelectionChanged () {
      this.updateTotals();
    },
    updateTotals () {
      // get all selected rows
      const selectedRows = this.gridApi ? this.gridApi.getSelectedRows() : Object.values(this.skuToSelectedCard);

      // initialize selected values
      // let countSelected = selectedRows.length;
      let countSelected = 0;
      let priceSelected = 0.0;

      // // Sum 'price' field * qtyToOrder for all selected rows
      // priceSelected = selectedRows.reduce(function(a, b) {
      //   return b["price"] === null ? a : a + (b["qtyToOrder"] * b["price"]);
      // }, 0.0);

      // let selectedTotal = 0.0;
      selectedRows.forEach(selectedProduct => {
        countSelected += selectedProduct.quantity;
        priceSelected += selectedProduct.quantity * selectedProduct.price;
      });

      // set data selected values
      this.countSelected = countSelected;
      this.priceSelected = priceSelected;
    },
    onInvalidProductsDialogClickHandler (confirm) {
      this.invalidProductsDialog.displayed = false;
    },
    searchProducts (products) {
      let filteredProducts = (products && products.length > 0) ? [...products] : [...this.allProducts];

      if (this.searchFilter !== undefined && this.searchFilter !== null && this.searchFilter !== '') {
        // SEARCH FILTER: RETURN FILTERED RESULTS
        const delimiter = '|';

        // Split searchFilter on delimiter
        const splits = this.searchFilter.split(delimiter);

        for (const split in splits) {
          // Trim searchFilterItem
          const searchFilterItem = splits[split].trim();

          if (searchFilterItem !== '') {
            // Check if searchFilterItem is a SKU search:
            if (searchFilterItem.toLowerCase().substring(0, 4) === 'sku:') {
              const skuSearchFilter = searchFilterItem.substring(4, searchFilterItem.length).replace(/['"]+/g, '');
              filteredProducts = this.filterBySku(filteredProducts, skuSearchFilter);
            } else {
              filteredProducts = this.filterByValue(filteredProducts, searchFilterItem);
            }
          }
        }
      }

      return filteredProducts;
    },
    getFilteredProducts () {
      if (!this.allProducts) {
        return;
      }

      let filteredProducts = this.searchProducts();
      if (this.$vuetify.breakpoint.mobile && this.filterModel && this.filterModel.appliedFilters > 0) {
        filteredProducts = this.filterModel.filterFunc(filteredProducts, this.filterModel);
      }

      this.sortProducts(filteredProducts);

      if (filteredProducts.length > 0) {
        this.paginationLength = Math.ceil(filteredProducts.length / this.pageSize);
      }

      return filteredProducts;
    },
    refreshData () {
      this.slider = {};
      // Return products that matched all search items
      const filteredProducts = this.getFilteredProducts();
      if (this.gridApi) {
        this.rowData = filteredProducts;
        // Save existing fitler model
        this.filterModel = this.gridApi.getFilterModel();

        // Restore / Reapply filter model
        if (Object.keys(this.filterModel).length !== 0) {
          setTimeout(() => {
            this.gridApi.setFilterModel(this.filterModel);
            this.gridApi.onFilterChanged();
            this.autosizeColumns();
          }, 100);
        } else {
          // Resize columns
          setTimeout(() => {
            this.autosizeColumns();
          }, 100);
        }

        // reload expanded rows
        setTimeout(() => {
          this.loadExpandedRows();
        }, 1000);
      } else {
        this.paginateProducts(filteredProducts);
        this.filteredProductsQuantity = 0;
        this.rowData.forEach(product => {
          this.filteredProductsQuantity += product.quantity;
        });
      }
    },
    // isCartThresholdExceeded(selectedTotal) {
    //   // Sum selected products total and cart total and see if it exceeds threshold of $10,000.00 (or whatever value is set to)
    //   let pendingTotal = parseFloat(this.cartTotal) + selectedTotal;
    //   return (pendingTotal > this.cartMaximumLimit);
    // },
    filterByValue (array, string) {
      // Search all properties for value
      return array.filter(o =>
        Object.keys(o).some(
          (k) => {
            // IGNORE SKU PROP WHEN SEARCHING
            if (k !== 'sku') {
              if (typeof o[k] === 'string' || o[k] instanceof String) {
                return o[k].toLowerCase().includes(string.toLowerCase());
              }
            }
          }
        ));
    },
    filterBySku (array, string) {
      if (string.charAt(0) === '!') {
        string = string.substring(1);
        // SKU DOES NOT INCLUDE
        return array.filter(o => {
          return !o.sku.toLowerCase().includes(string.toLowerCase());
        });
      } else {
        // SKU INCLUDES
        return array.filter(o => {
          return o.sku.toLowerCase().includes(string.toLowerCase());
        });
      }
    },
    onFiltersClear () {
      this.page = 1;
      this.showFilterDialog = false;
      this.filteredProductsQuantity = 0;
      this.filterModel = new MobileFilterModel();
      this.loadingOverlay(true);
      this.refreshData();

      setTimeout(() => {
        this.loadingOverlay(false);
      }, 100);
    },
    onFiltersCancel () {
      this.showFilterDialog = false;
    },
    onFiltersUpdate (data) {
      this.showFilterDialog = false;

      if (!deepEqual(this.filterModel, data.model)) {
        this.page = 1;
        this.filteredProductsQuantity = 0;
        this.filterModel = data.model;
        this.loadingOverlay(true);
        const filteredProducts = this.searchProducts(data.products);
        this.paginationLength = Math.ceil(filteredProducts.length / this.pageSize);
        this.sortProducts(filteredProducts);
        this.paginateProducts(filteredProducts);
        this.rowData.forEach(product => {
          this.filteredProductsQuantity += product.quantity;
        });
        setTimeout(() => {
          this.loadingOverlay(false);
        }, 100);
      }
    },
    sortProducts (products) {
      const productsToSort = (products && products.length > 0) ? products : this.rowData;

      if (this.cardSort && productsToSort) {
        let attr = this.cardSort.split(':')[0].toLowerCase();
        attr = attr === 'availability' ? 'stock' : attr;
        attr = attr === 'storage' ? 'storagecapacity' : attr;

        const details = this.cardSort.split(':')[1].trim();
        const ascending = details === 'Low to High';

        const getFormattedValue = (attr, val) => {
          return attr === 'storagecapacity' ? Number(val.replace('GB', '')) : Number(val);
        };

        productsToSort.sort((a, b) => {
          let retVal = 0;
          const aVal = getFormattedValue(attr, a[attr]);
          const bVal = getFormattedValue(attr, b[attr]);

          if (aVal === bVal) {
            retVal = 0;
          } else if (ascending) {
            retVal = aVal > bVal ? 1 : -1;
          } else {
            retVal = aVal < bVal ? 1 : -1;
          }

          return retVal;
        });
      }
    },
    getQuantitySelectOptions (quantity) {
      const stock = parseInt(quantity);
      return [...Array(stock + 1).keys()].slice(1);
    },
    onQuantyChange (product) {
      if (this.skuToSelectedCard[product.sku]) {
        this.updateTotals();
      }

      this.filteredProductsQuantity = 0;
      this.rowData.forEach(product => {
        this.filteredProductsQuantity += product.quantity;
      });
    },
    onSelectCard (product) {
      const selected = this.skuToSelectedCard[product.sku];
      if (selected) {
        delete this.skuToSelectedCard[product.sku];
      } else {
        this.skuToSelectedCard[product.sku] = product;
      }

      this.updateTotals();
    },
    isCardSelected (product) {
      return this.skuToSelectedCard[product.sku] !== undefined;
    },
    onErrorCarouselItem (event) {
      this.carouselItemErrors.push(event);
    },
    isMediaMissing (url) {
      return this.carouselItemErrors.includes(url);
    },
    carouselItems (product) {
      if (product !== null && 'sku' in product) {
        return this.mediaBySku[product.sku];
      }
      return [];
    },
    onSelectAll () {
      this.rowData.forEach(card => this.onSelectCard(card));
    },
    paginateProducts (products) {
      let end = this.pageSize * this.page;
      if (end > products.length) {
        end = products.length;
      }

      // start at the previous page end
      const start = this.pageSize * (this.page - 1);

      if (products.length >= end) {
        this.rowData = products.slice(start, end);
      } else {
        this.rowData = products;
      }
    }
  },
  watch: {
    accessoriesBySku (newValue, oldValue) {
      this.processProducts();
    },
    cartProducts (newValue, oldValue) {
      // Cart products updated. Update grid to reflect changes.
      this.processProducts();
    },
    searchFilter (newValue) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.countSelected = 0;
        this.priceSelected = 0;
        this.refreshData();
      }, 300);
    },
    cardSort (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.refreshData();
      }
    },
    page (newValue, oldValue) {
      this.refreshData();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    // pull expended rows from local storage if it exists
    if (localStorage.getItem('accessoriesExpandedRows') !== null) {
      this.currentExpandedRows = JSON.parse(localStorage.getItem('accessoriesExpandedRows'));
    }

    if (this.$vuetify.breakpoint.mobile) {
      this.filterModel = new MobileFilterModel();
    }

    this.processProducts();
  }
};

function quantityCellRenderer (params) {
  if (params !== undefined && params.value !== undefined) {
    if (parseInt(params.data.stock) > 1) {
      return '<a class="v-btn v-btn--flat v-btn--small theme--light primary--text media-button" href="#">' + params.value + '<i class="material-icons">arrow_drop_down</i></a>';
    }
    return '1';
  }
  return '';
}

function mediaCellRenderer (params) {
  if (params !== undefined && params.value !== undefined) {
    return '<a class="v-btn v-btn--flat v-btn--small theme--light primary--text media-button" href="#"><i class="material-icons">photo_library</i></a>';
  }
  return '';
}

function caseInsensitiveSort (valueA, valueB, nodeA, nodeB, isInverted) {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}

</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#accessories-table {
  margin-top: 10px;
  height: calc(100vh - 250px);
}

.functionality-button {
  text-decoration: none;
}

.invalid-reason {
  color: red;
  font-weight: 900;
}

.incorrect-price {
  color: red;
}

.correct-price {
  color: green;
}

.centered {
  text-align: center;
}

.media-button {
  min-width: 0;
}

.media-product-header {
  padding: 7px;
}

.media-product-price {
  margin-left: 20px;
}

.media-cart-button {
  margin-left: 30px;
}

.media-product-row {
  background-color: #BBDEFB !important;
}

</style>
