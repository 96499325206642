<template>
  <v-container fluid class="mt-16 mx-auto">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>GizmoTrader Wholesale Password Reset</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="lock"
                name="new-password"
                label="New Password"
                type="password"
                v-model="newPassword"
              ></v-text-field>
              <v-text-field
                prepend-icon="lock"
                name="confirm-new-password"
                label="Confirm New Password"
                id="confirm-new-password"
                type="password"
                v-model="confirmNewPassword"
                :rules="rules.confirmPassword"
              ></v-text-field>
                <v-btn style="width:100%" class="ma-0 mt-2" color="primary" @click="resetPassword">Reset</v-btn>
                <div class="mt-2" align="center">
                    <router-link to="/login">Return to login</router-link>
                </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { resetPasswordService } from '@/_services';
import { router } from '@/router';

export default {
  props: {
    source: String
  },
  data () {
    return {
      newPassword: '',
      confirmNewPassword: '',
      submitted: false,
      rules: {
        confirmPassword: [
          v => v === this.newPassword || 'Passwords must match'
        ]
      }
    };
  },
  computed: {
  },
  created () {
    // reset login status
    this.clearAlert();
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    resetPassword () {
      if (this.newPassword !== this.confirmNewPassword) {
        this.errorAlert('Passwords must match!');
        return;
      } else if (this.newPassword === '' || this.confirmPassword === '') {
        this.errorAlert('Passwords must not be empty!');
        return;
      }

      const body = {
        newPassword: this.newPassword,
        uuid: this.$route.query.uuid
      };

      resetPasswordService.resetPassword(body)
        .then(res => {
          this.successAlert(res);
          this.loadingOverlay(true);
          setTimeout(() => {
            this.loadingOverlay(false);
            router.push('/login');
          }, 2000);
        })
        .catch(err => {
          console.error(err);
          this.errorAlert(err);
        });
    }
  }
};
</script>
