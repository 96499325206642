<template>
  <v-container fluid id="users-table-page">

    <ConfirmationDialog
      :displayed.sync="showDialog"
      title="Delete Users?"
      text="Are you sure you want to permanently delete the selected users?"
      :onYes="deleteUsers"
      :onNo="() => showDialog=false"
      @input="showDialog = false"
      />

    <ConfirmationDialog
      :displayed.sync="showDeletePendingDialog"
      title="Delete Pending Orders?"
      text="Are you sure you want to permanently delete all orders with PENDING_PAYPAL_CHECKOUT and PENDING_PAYMENT status for the selected users?"
      :onYes="deletePendingOrders"
      :onNo="() => showDeletePendingDialog=false"
      @input="showDeletePendingDialog = false"
    />

    <div v-if="accountUser.isAdmin">
      <div id="mobileControls" class="px-3 mt-2" v-if="$vuetify.breakpoint.mobile">
        <v-row row class="px-3">
          <v-text-field
            :label="searchFilterLabel"
            v-model="searchFilter"
            outlined
            clearable
            dense
          ></v-text-field>
        </v-row>
        <v-row row class="px-3 justify-space-between">
          <v-btn outlined @click="onClearFilters">Reset Layout</v-btn>
          <v-btn :disabled="countSelected === 0" outlined @click="onClearSelection">Clear</v-btn>
          <v-btn fab dark small color="pink" @click="refreshData">
            <v-icon dark>refresh</v-icon>
          </v-btn>
        </v-row>
        <v-row row class="px-3 mt-5 justify-space-between">
          <v-btn
                :disabled="Object.keys(userUpdateObject).length === 0"
                color="green darken-1"
                class="white--text"
                @click="updateUsers"
              >Update</v-btn>
          <v-btn
            :disabled="countSelected === 0"
            color="cyan"
            class="white--text"
            @click="showDeletePendingDialog = true"
          >Pending</v-btn>
          <v-btn
                :disabled="countSelected === 0"
                color="red darken-1"
                class="white--text"
                @click="showDialog = true"
              >Delete <span v-if="countSelected > 0">({{countSelected}})</span></v-btn>
        </v-row>
      </div>
      <div v-else id="desktopControls">
        <v-row row>
          <v-col cols="3" class="pb-0">
          <v-text-field
            :label="searchFilterLabel"
            v-model="searchFilter"
            outlined
            clearable
          ></v-text-field>
          </v-col>

          <v-col cols="1"  class="pb-0">
          </v-col>

          <v-col cols="8"  class="pb-0">

            <span class="clear-button centered px-2">
              <v-btn style="height: 50px" outlined @click="onClearFilters">Reset Layout</v-btn>
            </span>

            <span class="clear-button centered px-2">
              <v-btn style="height: 50px" :disabled="countSelected === 0" outlined @click="onClearSelection">Clear Selection</v-btn>
            </span>

            <span class="centered px-2">
              <v-btn
                style="height: 50px"
                :disabled="Object.keys(userUpdateObject).length === 0"
                color="green darken-1"
                class="white--text"
                @click="updateUsers"
              >{{ updateUsersText }}</v-btn>
            </span>

            <span id="delete-pending-orders-btn" class="centered px-2">
              <v-btn
                style="height: 50px"
                :disabled="countSelected === 0"
                color="cyan"
                class="white--text"
                @click="showDeletePendingDialog = true"
              ><span style="margin-right: 6px"><v-icon dark>delete_sweep</v-icon></span>&nbsp;Pending</v-btn>
            </span>

            <span id="delete-bulk-users-btn" class="centered px-2">
              <v-btn
                style="height: 50px"
                :disabled="countSelected === 0"
                color="red darken-1"
                class="white--text"
                @click="showDialog = true"
              >{{ deleteUsersText }}</v-btn>
            </span>

            <span id="refresh-users-btn">
              <v-btn fab dark small right absolute color="pink" @click="refreshData">
                <v-icon dark>refresh</v-icon>
              </v-btn>
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row>
      <v-col cols="12"  class="py-0" >
        <div id="users-table" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
          <ag-grid-vue style="width: 100%; height: 100%;" class="ag-theme-material"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs"
            :rowData="tableData"
            rowSelection="multiple"
            suppressRowClickSelection="true"
            @selection-changed="onSelectionChanged"
          ></ag-grid-vue>
        </div>
        <div id="mobileContent" class="pr-5" v-else>
          <div v-if="tableData && tableData.length > 0">
            <div v-for="(user, i) in tableData" :key="i">
              <v-card class="mb-5" cols="12">
                <v-card-title class="mx-5 mb-5">
                  <v-row>
                    Username: <a href="#" @click="onUsernameClick(user.username, $event)">{{user.username}}</a>
                  </v-row>
                  <v-row>
                    Name: {{user.firstName}} {{user.lastName}}
                  </v-row>
                  <v-row>
                    Email: {{user.email}}
                  </v-row>
                  <v-row>
                    Company: {{user.company}}
                  </v-row>
                  <v-row>
                    Phone Number: {{user.phone}}
                  </v-row>
                  <v-row>
                    WhatsApp: {{user.whatsAppNumber}}
                  </v-row>
                </v-card-title>
                <v-card-subtitle class="mx-5">
                  <v-row>
                    Account Status: {{user.accountStatus}}
                    {{user.accountEnabled ? 'Enabled' : 'Disabled'}}
                    {{user.isAdmin ? 'Admin' : ''}}
                  </v-row>
                  <v-row>Direct Payment: {{user.directPaymentEnabled ? "Enabled" : "Disabled"}}</v-row>
                  <v-row>PayPal Payment: {{user.payPayEnabled ? "Enabled" : "Disabled"}}</v-row>
                  <v-row v-if="user.createdDate">Created Date: {{(new Date(user.createdDate)).toLocaleDateString()}}</v-row>
                </v-card-subtitle>
                <v-card-actions>
                  <input class="card-selected-box" type="checkbox" :checked="isCardSelected(user)" @click="onSelectCard(user)">
                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    @click="toggleExpandedUser(user)"
                  >
                    <v-icon>{{ isUserExpanded(user) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="isUserExpanded(user)">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-container>
                        <v-row><b>Address</b></v-row>
                        <v-row>{{user.address1}}</v-row>
                        <v-row>{{user.address2}}</v-row>
                        <v-row>{{user.address3}}</v-row>
                        <v-row>{{user.city}}, {{user.state}} {{user.zip}}</v-row>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <v-row><b>Shipping Address</b></v-row>
                        <v-row>{{user.shippingAddress1}}</v-row>
                        <v-row>{{user.shippingAddress2}}</v-row>
                        <v-row>{{user.shippingAddress3}}</v-row>
                        <v-row>{{user.shippingCity}}, {{user.shippingState}} {{user.shippingZip}}</v-row>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <v-row><b>Settings</b></v-row>
                        <v-row>
                          {{user.taxExempt ? 'Tax Exempt' : 'Not Tax Exempt'}}
                          Tax Rate: {{user.taxRate}}
                        </v-row>
                        <v-row>
                          Cart Limit: {{user.overrideSettings.cartMaxLimit}}
                        </v-row>
                        <v-row>
                          Direct Payment Max Limit: {{user.overrideSettings.directPaymentMaxLimit}}
                        </v-row>
                        <v-row>
                          MOQ: {{user.overrideSettings.minimumOrderQtyThreshold}}
                        </v-row>
                        <v-row>
                          MOQ Ship Fee: {{user.overrideSettings.minimumOrderQtyShppingFee}}
                        </v-row>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <v-row><b>Orders</b></v-row>
                        <v-row>
                          Last Order: <a href="#" @click="onOrderClick(user, $event)">
                            {{user.username in orderInfoGrouped ? new Date(orderInfoGrouped[user.username].lastOrder).toLocaleDateString() : ''}}
                          </a>
                        </v-row>
                        <v-row>
                          Total Orders: {{user.username in orderInfoGrouped ? orderInfoGrouped[user.username].count : 0}}
                        </v-row>
                        <v-row>
                          Total Orders Value: {{user.username in orderInfoGrouped ? orderInfoGrouped[user.username].sum : 0}}
                        </v-row>
                        <v-row>
                          Pending Orders: <a href="#" @click="onOrderClick(user, $event)">
                            {{user.username in orderInfoGrouped ? orderInfoGrouped[user.username].pendingCount : 0}}
                          </a>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import { router } from '@/router';
import formatterMixin from '@/_mixins/formatterMixin';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import countriesMixin from '@/_mixins/countriesMixin';

export default {
  name: 'user_table',
  data () {
    return {
      searchFilter: null,
      gridOptions: {
        onSortChanged: this.saveColumnState,
        onColumnResized: this.saveColumnState,
        onColumnVisible: this.saveColumnState,
        onColumnMoved: this.saveColumnState,
        onColumnPinned: this.saveColumnState,
        onFilterChanged: this.saveFilterModel,
        onFirstDataRendered: this.loadTableState,
        rowClassRules: {
          'row-pending-orders': (params) => {
            const userPendingCount = params.data.username in this.orderInfoGrouped ? this.orderInfoGrouped[params.data.username].pendingCount : 0;
            return userPendingCount > 0;
          }
        }
      },
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      columnFields: null,
      autosizingColumns: false,
      showDialog: false,
      showDeletePendingDialog: false,
      window: {
        width: 0,
        height: 0
      },
      seletectedIds: [], // list of ids that are selected in the table.
      // This object manages the "staged" changes for inline edits. As a user
      // edits the table each edit will appear in this object keyed by username
      // storing the column name and the new data
      userUpdateObject: {},
      expandedUserIds: []
    };
  },
  components: {
    AgGridVue,
    ConfirmationDialog
  },
  mixins: [
    formatterMixin,
    countriesMixin
  ],
  created () {
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount () {
    window.addEventListener('resize', this.handleResize);

    const createTableLink = (displayTxt, clickListner) => {
      const link = document.createElement('a');
      link.href = '#';
      link.innerText = displayTxt;
      link.addEventListener('click', clickListner);
      return link;
    };

    const baseColumnDef = {
      sortable: true,
      filter: true,
      editable: true,

      // When a cell is editted, we want to capture the changes and stage them
      onCellValueChanged: (params) => {
        const colName = params.colDef.field;
        const username = params.data.username;
        const newValue = params.newValue;

        if (newValue === null || newValue === undefined) {
          return;
        }

        // if we dont already have an update object for this user
        if (!(username in this.userUpdateObject)) {
          // Build user object for the backend to update with
          const user = {
            id: params.data.id,
            username: username
          };
          // Update object tracking all staged changes. Vue.set is to create reactivity
          Vue.set(this.userUpdateObject, username, user);
        }

        if (colName.includes('.')) {
          // split into field and sub field. an example is overrideSettings.maxCartLimit
          const splitColumn = colName.split('.');
          const field = splitColumn[0];
          const subField = splitColumn[1];

          const tempObj = {};
          tempObj[subField] = newValue;

          // merge the temp object with the current update object for this field (ex .overrideSettings).
          // Order matters here. If any params are null, object.assign will ignore them
          const mergedObject = Object.assign({}, this.users[username].overrideSettings, this.userUpdateObject[username][field], tempObj);

          // Assign to field so that the database picks it up
          this.userUpdateObject[username][field] = mergedObject;
        } else {
          this.userUpdateObject[username][colName] = newValue;
        }
      }
    };

    this.columnDefs = [
      {
        headerName: 'Username',
        field: 'username',
        ...baseColumnDef,
        pinned: 'left',
        checkboxSelection: true,
        editable: false,
        cellRenderer: (data) => {
          const onClick = this.onUsernameClick.bind(this, data.getValue());
          return createTableLink(data.getValue(), onClick);
        }
      },
      { headerName: 'First Name', field: 'firstName', ...baseColumnDef },
      { headerName: 'Last Name', field: 'lastName', ...baseColumnDef },
      { headerName: 'Email', field: 'email', ...baseColumnDef },
      { headerName: 'Company', field: 'company', ...baseColumnDef },
      { headerName: 'Address 1', field: 'address1', ...baseColumnDef },
      { headerName: 'Address 2', field: 'address2', ...baseColumnDef },
      { headerName: 'Address 3', field: 'address3', ...baseColumnDef },
      { headerName: 'City', field: 'city', ...baseColumnDef },
      { headerName: 'State', field: 'state', ...baseColumnDef },
      { headerName: 'Zip', field: 'zip', ...baseColumnDef },
      {
        headerName: 'Country',
        field: 'country',
        ...baseColumnDef,
        cellRenderer: (data) => {
          const val = data.value;
          if (val in this.countriesMap) {
            return this.countriesMap[val].name;
          } else {
            return val;
          }
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: this.countriesList.map(o => o.value),
            cellRenderer: (data) => {
              const val = data.value;
              if (val in this.countriesMap) {
                return this.countriesMap[val].name;
              } else {
                return val;
              }
            }
          };
        }
      },
      { headerName: 'Shipping Address 1', field: 'shippingAddress1', ...baseColumnDef },
      { headerName: 'Shipping Address 2', field: 'shippingAddress2', ...baseColumnDef },
      { headerName: 'Shipping Address 3', field: 'shippingAddress3', ...baseColumnDef },
      { headerName: 'Shipping City', field: 'shippingCity', ...baseColumnDef },
      { headerName: 'Shipping State', field: 'shippingState', ...baseColumnDef },
      { headerName: 'Shipping Zip', field: 'shippingZip', ...baseColumnDef },
      {
        headerName: 'Shipping Country',
        field: 'shippingCountry',
        ...baseColumnDef,
        cellRenderer: (data) => {
          const val = data.value;
          if (val in this.countriesMap) {
            return this.countriesMap[val].name;
          } else {
            return val;
          }
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: this.countriesList.map(o => o.value),
            cellRenderer: (data) => {
              const val = data.value;
              if (val in this.countriesMap) {
                return this.countriesMap[val].name;
              } else {
                return val;
              }
            }
          };
        }
      },
      { headerName: 'Phone', field: 'phone', ...baseColumnDef },
      { headerName: 'WhatsApp Number', field: 'whatsAppNumber', ...baseColumnDef },
      {
        headerName: 'Tax Exempt',
        field: 'taxExempt',
        ...baseColumnDef,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: [true, false]
          };
        }
      },
      { headerName: 'Tax Rate', field: 'taxRate', ...baseColumnDef },
      {
        headerName: 'Direct Payment Enabled',
        field: 'directPaymentEnabled',
        ...baseColumnDef,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: [true, false]
          };
        }
      },
      {
        headerName: 'PayPal Payment Enabled',
        field: 'payPalEnabled',
        ...baseColumnDef,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: [true, false]
          };
        }
      },
      {
        headerName: 'Account Status',
        pinned: 'left',
        field: 'accountStatus',
        ...baseColumnDef,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: this.availableStatuses
          };
        }
      },
      {
        headerName: 'Account Enabled',
        field: 'accountEnabled',
        ...baseColumnDef,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: [true, false]
          };
        }
      },
      {
        headerName: 'Is Admin',
        field: 'isAdmin',
        ...baseColumnDef,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: [true, false]
          };
        }
      },
      {
        headerName: 'Cart Limit',
        field: 'overrideSettings.cartMaxLimit',
        ...baseColumnDef,
        valueGetter: params => {
          return params.data.overrideSettings.cartMaxLimit;
        },
        valueSetter: params => {
          params.data.overrideSettings.cartMaxLimit = params.newValue;
          return true;
        }
      },
      {
        headerName: 'Direct Payment Max Limit',
        field: 'overrideSettings.directPaymentMaxLimit',
        ...baseColumnDef,
        valueGetter: params => {
          return params.data.overrideSettings.directPaymentMaxLimit;
        },
        valueSetter: params => {
          params.data.overrideSettings.directPaymentMaxLimit = params.newValue;
          return true;
        }
      },
      {
        headerName: 'MOQ Threshold',
        field: 'overrideSettings.minimumOrderQtyThreshold',
        ...baseColumnDef,
        valueGetter: params => {
          return params.data.overrideSettings.minimumOrderQtyThreshold;
        },
        valueSetter: params => {
          params.data.overrideSettings.minimumOrderQtyThreshold = params.newValue;
          return true;
        }
      },
      {
        headerName: 'MOQ Ship Fee',
        field: 'overrideSettings.minimumOrderQtyShppingFee',
        ...baseColumnDef,
        valueGetter: params => {
          return params.data.overrideSettings.minimumOrderQtyShppingFee;
        },
        valueSetter: params => {
          params.data.overrideSettings.minimumOrderQtyShppingFee = params.newValue;
          return true;
        }
      },
      {
        headerName: 'Last Order',
        ...baseColumnDef,
        editable: false,
        // cell render is for a human readable value, valueGetter is for data-first value used in sorting, filtering etc
        cellRenderer: (data) => {
          const displayTxt = data.data.username in this.orderInfoGrouped ? new Date(this.orderInfoGrouped[data.data.username].lastOrder).toLocaleDateString() : null;
          const onClick = this.onOrderClick.bind(this, data.data);
          return createTableLink(displayTxt, onClick);
        },
        valueGetter: (data) => {
          return data.data.username in this.orderInfoGrouped ? this.orderInfoGrouped[data.data.username].lastOrder : null;
        }
      },
      {
        headerName: 'Total Orders',
        ...baseColumnDef,
        editable: false,
        field: 'totalOrders',
        valueGetter: (data) => {
          return data.data.username in this.orderInfoGrouped ? this.orderInfoGrouped[data.data.username].count : 0;
        }
      },
      {
        headerName: 'Total Order Value',
        ...baseColumnDef,
        editable: false,
        valueFormatter: this.currencyFormatter,
        valueGetter: (data) => {
          return data.data.username in this.orderInfoGrouped ? this.orderInfoGrouped[data.data.username].sum : 0;
        }
      },
      {
        headerName: 'Pending Orders',
        ...baseColumnDef,
        editable: false,
        valueGetter: (data) => {
          return data.data.username in this.orderInfoGrouped ? this.orderInfoGrouped[data.data.username].pendingCount : 0;
        },
        cellRenderer: (data) => {
          const onClick = this.onOrderClick.bind(this, data.data);
          return createTableLink(data.getValue(), onClick);
        }
      },
      {
        headerName: 'Store Credit',
        ...baseColumnDef,
        editable: false,
        valueFormatter: this.currencyFormatter,
        valueGetter: (data) => {
          return data.data.username in this.balancesByUsername ? this.balancesByUsername[data.data.username].balance : 0;
        },
        cellStyle: (cell) => {
          return this.creditBalanceCellStyle(cell.value);
        }
      },
      {
        headerName: 'Account Created',
        ...baseColumnDef,
        editable: false,
        field: 'createdDate',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        }
      }
    ];

    this.columnFields = this.columnDefs.map(columnDef => columnDef.field);
  },
  watch: {
    searchFilter: function () {
      if (this.gridApi) {
        this.gridApi.setQuickFilter(this.searchFilter);
        this.saveSearchFilter();
      }
    }
  },
  computed: {
    ...mapState({
      accountUser: state => state.account.user
    }),
    ...mapState('users', ['usernames', 'users', 'usersById']),
    ...mapGetters('users', ['availableStatuses']),
    ...mapState('usersTable', ['orderInfoGrouped']),
    ...mapState('credits', ['balancesByUsername']),
    tableData () {
      if (this.users) {
        return Object.values(this.users);
      } else {
        return [];
      }
    },
    searchFilterLabel () {
      return 'Search Users';
    },
    deleteUsersText () {
      if (this.countSelected === 1) {
        return 'Delete 1 user';
      } else {
        return `Delete ${this.countSelected} users`;
      }
    },
    updateUsersText () {
      const length = Object.keys(this.userUpdateObject).length;
      if (length === 1) {
        return 'Update 1 user';
      } else {
        return `Update ${length} users`;
      }
    },
    countSelected () {
      if (this.seletectedIds && Array.isArray(this.seletectedIds)) {
        return this.seletectedIds.length;
      } else {
        return 0;
      }
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.getAllOrdersGrouped();
    this.getBalances();
  },
  methods: {
    ...mapActions('users', ['updateFields', 'delete', 'loadUsers', 'deleteAllByIds']),
    ...mapActions('usersTable', ['getAllOrdersGrouped']),
    ...mapActions('orders', ['deleteAllPendingByUsername']),
    ...mapActions('credits', ['getBalances']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    autoSizeColumns (source) {
      if (!this.autosizingColumns) {
        if (router.currentRoute.path === '/users-table' || router.currentRoute.path === '/users-table#') {
          this.autosizingColumns = true;

          setTimeout(() => {
            this.autoSizeAll(false);
          }, 100);

          if (this.window.width === 0) {
            this.window.width = window.innerWidth;
          }
          if (this.window.height === 0) {
            this.window.height = window.innerHeight;
          }

          this.autosizingColumns = false;
        }
      }
    },
    autoSizeAll (skipHeader) {
      if (this.gridColumnApi) {
        this.gridColumnApi.autoSizeColumns(this.columnFields, skipHeader);
      }
    },
    sizeToFit () {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    handleResize () {
      if (router.currentRoute.path === '/users-table' || router.currentRoute.path === '/users-table#') {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        setTimeout(() => {
          this.autoSizeColumns('window resize');
        }, 100);
      }
    },
    onSelectionChanged () {
      if (this.gridApi) {
        // get all selected rows
        const selectedRows = this.gridApi.getSelectedRows();

        // map to id
        this.seletectedIds = selectedRows.map(row => row.id);
      }
    },

    onModelUpdated (event) {
      this.autoSizeColumns('model updated');
    },
    refreshData () {
      this.loadingOverlay(true);
      this.userUpdateObject = {};

      this.getAllOrdersGrouped().then(() => {
        this.loadUsers().then(res => {
          if (this.gridApi) {
            this.gridApi.refreshCells();
          }

          this.loadingOverlay(false);
        });
      });
    },
    deleteUsers () {
      this.deleteAllByIds(this.seletectedIds);
      this.showDialog = false;

      // Remove any deleted users from the update object
      for (const id of this.seletectedIds) {
        const user = this.usersById[id];

        if (user && user.username in this.userUpdateObject) {
          Vue.delete(this.userUpdateObject, user.username);
        }
      }
      this.onClearSelection();
    },
    deletePendingOrders () {
      this.loadingOverlay(true);
      const usernames = this.seletectedIds.map(id => this.usersById[id].username);

      this.showDeletePendingDialog = false;
      this.deleteAllPendingByUsername(usernames).then(() => {
        // after deleting get the grouped order info again
        this.getAllOrdersGrouped().then(() => {
          if (this.gridApi) {
            this.gridApi.redrawRows();
          }

          this.onClearSelection();
          this.successAlert('Deleted pending orders');
          this.loadingOverlay(false);
        });
      });
    },
    onClearSelection () {
      this.seletectedIds = [];
      if (this.gridApi) {
        this.gridApi.deselectAll();
      }
    },
    onClearFilters () {
      if (this.gridColumnApi) {
        this.gridColumnApi.resetColumnState();
      }

      if (this.gridApi) {
        this.gridApi.setFilterModel(null);
      }

      this.searchFilter = null;
      localStorage.removeItem('usersTableState');
    },
    updateUsers () {
      for (const username in this.userUpdateObject) {
        this.updateFields(this.userUpdateObject[username]);
      }

      this.userUpdateObject = {};
    },
    saveFilterModel () {
      localStorage.setItem('usersTableFilterModel', JSON.stringify(this.gridApi.getFilterModel()));
    },
    saveSearchFilter () {
      localStorage.setItem('usersTableSearchFilter', JSON.stringify(this.searchFilter));
    },
    saveColumnState () {
      localStorage.setItem('usersTableColumnState', JSON.stringify(this.gridColumnApi.getColumnState()));
    },
    loadTableState () {
      // Loads all of the table state from local storage if it exists
      let filterModel = localStorage.getItem('usersTableFilterModel');
      if (filterModel) {
        filterModel = JSON.parse(filterModel);
        if (this.gridApi) {
          this.gridApi.setFilterModel(filterModel);
          this.gridApi.onFilterChanged();
        }
      }

      let searchFilter = localStorage.getItem('usersTableSearchFilter');
      if (searchFilter) {
        searchFilter = JSON.parse(searchFilter);
        this.searchFilter = searchFilter;
      }

      let columnState = localStorage.getItem('usersTableColumnState');
      if (columnState) {
        columnState = JSON.parse(columnState);
        if (this.gridColumnApi) {
          this.gridColumnApi.applyColumnState({ state: columnState, applyOrder: true });
        }
      }
    },
    isUserExpanded (user) {
      return this.expandedUserIds.includes(user.id);
    },
    toggleExpandedUser (user) {
      const index = this.expandedUserIds.indexOf(user.id);
      if (index >= 0) {
        this.expandedUserIds.splice(index, 1);
      } else {
        this.expandedUserIds.push(user.id);
      }
    },
    isCardSelected (user) {
      return this.seletectedIds.includes(user.id);
    },
    onSelectCard (user) {
      const index = this.seletectedIds.indexOf(user.id);
      if (index >= 0) {
        this.seletectedIds.splice(index, 1);
      } else {
        this.seletectedIds.push(user.id);
      }
    },
    onUsernameClick (username, event) {
      event.preventDefault();
      this.$router.push({ name: 'users', params: { username: username } });
    },
    onOrderClick (user, event) {
      event.preventDefault();
      this.$router.push({ name: 'orders', params: { username: user.username } });
    }
  }
};

</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#users-table {
  height: calc(100vh - 250px);
}

.centered {
  text-align: center;
}

#delete-bulk-users-btn button {
  height: 50px;
}

.clear-button button {
  height: 50px;
  // width: 60px;
}

.row-pending-orders {
  background-color: #B2EBF2 !important;
}

#mobileContent .row{
  width: 100%;
}
</style>
