import config from 'config';
import { authHeader } from '@/_helpers';

export const ordersService = {
  getAll,
  getAllByUsername,
  getAllByUsernameParam,
  getAllByStatus,
  getById,
  getByIdUsernameParam,
  deleteAllPendingByUsername,
  create,
  createDirect,
  createPayPalTransaction,
  capturePayPalTransaction,
  updateStatus,
  verify,
  verifyDirect,
  cancelCheckoutOrder,
  cancelPendingCheckoutOrder
};

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders/all`, requestOptions).then(handleResponse);
}

function getAllByUsername () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders`, requestOptions).then(handleResponse);
}

function getAllByUsernameParam (username) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders/all/${username}`, requestOptions).then(handleResponse);
}

function deleteAllPendingByUsername (usernames) {
  const requestOptions = {
    method: 'POST', // post instead of delete so we can send a body
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ usernames })
  };

  return fetch(`${config.apiUrl}/orders/pending`, requestOptions).then(handleResponse);
}

function getById (orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders/details/${orderId}`, requestOptions).then(handleResponse);
}

function getByIdUsernameParam (orderId, username) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders/details/${orderId}/${username}`, requestOptions).then(handleResponse);
}

function getAllByStatus (orderStatus) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders/status/${orderStatus}`, requestOptions).then(handleResponse);
}

function create (orderParam) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(orderParam)
  };

  return fetch(`${config.apiUrl}/orders`, requestOptions).then(handleResponse);
}

function createDirect (orderParam) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(orderParam)
  };

  return fetch(`${config.apiUrl}/orders/direct`, requestOptions).then(handleResponse);
}

function createPayPalTransaction (orderId, useStoreCredit) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ useStoreCredit })
  };

  return fetch(`${config.apiUrl}/orders/create-paypal-transaction/${orderId}`, requestOptions)
    .then(function (res) {
      return res.json();
    }).then(function (data) {
      return data.orderID; // Use the same key name for order ID on the client and server
    });
}

function capturePayPalTransaction (orderId, paypalOrderId, useStoreCredit) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/orders/capture-paypal-transaction/${orderId}/${paypalOrderId}`, requestOptions)
    .then(function (res) {
      return res.json();
    });
}

function updateStatus (orderId, orderStatus) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/orders/status/${orderId}/${orderStatus}`, requestOptions).then(handleResponse);
}

function verify (orderId, orderParam) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(orderParam)
  };

  return fetch(`${config.apiUrl}/orders/verify/${orderId}`, requestOptions).then(handleResponse);
}

function verifyDirect (orderId, orderParam) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(orderParam)
  };

  return fetch(`${config.apiUrl}/orders/verify/direct/${orderId}`, requestOptions).then(handleResponse);
}

function cancelCheckoutOrder (orderId, orderStatus) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders/cancel-checkout/${orderId}/${orderStatus}`, requestOptions).then(handleResponse);
}

function cancelPendingCheckoutOrder (orderId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/orders/cancel-pending-checkout/${orderId}`, requestOptions).then(handleResponse);
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // store.dispatch('alert/error', "Please login again!", { root: true }).then(() => {
        //     logout();
        //     location.reload(true);
        // });
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
