import Vue from 'vue';
import { cartService } from '@/_services';

const state = {
  products: [],
  count: 0,
  price: 0.0,
  invalidProducts: []
};

const actions = {
  validate ({ commit }) {
    return new Promise((resolve, reject) => {
      cartService.getInvalidByUsername().then(response => {
        // http success, call the mutator and change something in state
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      });
    });
  },
  synchronize ({ commit }) {
    cartService.synchronize()
      .then(
        products => commit('setProductsRequest', products)
        // error => commit('getAllFailure', error)
      );
  },
  checkout ({ dispatch, commit }, orderParam) {
    // Checkout..  Create order, clear cart
    dispatch('orders/createOrder', orderParam, { root: true })
      .then(
        // commit('clearCartRequest')
      );
  },
  checkoutDirect ({ dispatch, commit }, orderParam) {
    return new Promise((resolve, reject) => {
      dispatch('orders/createOrderDirect', orderParam, { root: true }).then((response) => {
        // http success, call the mutator and change something in state
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      });
    });
  },
  refreshCartProducts ({ commit }, args) {
    if (args !== undefined && args !== null) {
      if (args.products !== undefined && args.products !== null) {
        // console.log('IN CART MODULE SETTING CART PRODUCTS TO: ' + args.products);
        commit('setProductsRequest', args.products);
      }
    } else {
      // console.log('IN CART MODULE. NO PRODUCTS IN ARGS, SO MAKING REQUEST TO SERVER TO GET LIST OF PRODUCTS.');
      cartService.getAllByUsername()
        .then(
          products => commit('setProductsRequest', products)
          // error => commit('getAllFailure', error)
        );
    }
  },
  getAllCartProducts () {
    return state.products;
  },
  getCartCount () {
    return this.count;
  },
  getCartTotal () {
    return this.price;
  },
  clearCartProducts ({ commit }) {
    commit('clearCartRequest');
  },
  removeAllCartProducts ({ commit, rootState }) {
    const payload = { username: rootState.account.username, action: 'REMOVE_ALL_CART_PRODUCTS' };

    // REMOVE ALL CART PRODUCT(S) FROM DATABASE
    Vue.prototype.$getSocket().emit('cart', payload, (response) => {});
  },
  removeProductsFromCart ({ commit, rootState }, payload) {
    // Append data to payload
    payload.username = rootState.account.username;
    payload.action = 'REMOVE_CART_PRODUCTS';

    Vue.prototype.$getSocket().emit('cart', payload, (response) => {});
  },
  addProductsToCart ({ dispatch, commit }, items) {
    return new Promise((resolve, reject) => {
      cartService.addToCartBulk(items).then(
        (response) => {
          commit('setProductsRequest', response.cartProducts);
          dispatch('alert/loading', false, { root: true });
          if (response.invalidProducts.length === 0) {
            dispatch('alert/success', 'Products successfully added to cart!', { root: true });
          } else {
            dispatch('alert/error', 'Products added to cart with issues.', { root: true });
          }

          // http success, call the mutator and change something in state
          resolve(response); // Let the calling function know that http is done. You may send some data back
        }, error => {
          // http failed, let the calling function know that action did not work out
          dispatch('alert/error', error, { root: true });
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  removeProductRequest (state, productToRemove) {
    // remove selected item from existing products array
    state.products.splice(state.products.findIndex(item => item.sku === productToRemove.sku), 1);
    // state.count = state.products.length;
    // state.price = getTotalPrice(state.products);

    let productsCount = 0;
    let productsPrice = 0.0;
    state.products.forEach((product) => {
      product.wholesaleprice = parseFloat(product.wholesaleprice);
      productsCount += parseInt(product.quantity);
      productsPrice += parseInt(product.quantity) * product.wholesaleprice;
    });

    // state.products = products;
    state.count = productsCount;
    state.price = productsPrice;
  },
  removeProductsRequest (state, products) {
    // remove selected items from existing products array
    state.products = state.products.filter(x => !products.filter(y => y.sku === x.sku).length);
    // state.count = state.products.length;
    // state.price = getTotalPrice(state.products);
    let productsCount = 0;
    let productsPrice = 0.0;
    state.products.forEach((product) => {
      product.wholesaleprice = parseFloat(product.wholesaleprice);
      productsCount += parseInt(product.quantity);
      productsPrice += parseInt(product.quantity) * product.wholesaleprice;
    });

    // state.products = products;
    state.count = productsCount;
    state.price = productsPrice;
  },
  // addProductRequest(state, product) {
  //     // Convert wholesaleprice from string to numeric float
  //     product.wholesaleprice = parseFloat(product.wholesaleprice);

  //     // check if object already exists and update / push accordingly
  //     const index = state.products.findIndex((e) => e.sku === product.sku);

  //     if (index === -1) {
  //         state.products.push(product);
  //     } else {
  //         state.products[index] = product;
  //     }
  //     state.count = state.products.length;
  //     state.price = getTotalPrice(state.products);
  // },
  // addProductsRequest(state, products) {
  //     state.count = state.products.length;
  //     state.price = getTotalPrice(state.products);
  // },
  setProductsRequest (state, products) {
    // Convert wholesaleprice from string to numeric float
    let productsCount = 0;
    let productsPrice = 0.0;
    products.forEach((product) => {
      product.wholesaleprice = parseFloat(product.wholesaleprice);
      productsCount += parseInt(product.quantity);
      productsPrice += parseInt(product.quantity) * product.wholesaleprice;
    });

    state.products = products;
    state.count = productsCount;
    state.price = productsPrice;
    // state.count = state.products.length;
    // state.price = getTotalPrice(state.products);
  },
  clearCartRequest (state) {
    state.products = [];
    state.count = 0;
    state.price = 0.0;
  },
  setInvalidProductsRequest (state, invalidProducts) {
    state.invalidProducts = invalidProducts;
  }
};

export const cart = {
  namespaced: true,
  state,
  actions,
  mutations
};
