<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-12">
          <v-card-text>
            <v-col class="pa-0">
              <span>Please contact us at <span style="white-space: nowrap;"><b>+1-321-830-3841 </b></span> or <a href="mailto:wholesale@gizmotrader.com">wholesale@gizmotrader.com</a> to make account changes.</span>
            </v-col>
          </v-card-text>
        </v-card>

        <v-form
          @keyup.native.enter="handleSubmit()"
          ref="form"
          v-model="valid"
          lazy-validation
        >
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>User Information</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="6 pb-0">
                <v-text-field
                    class="mr-3"
                    name="firstName"
                    label="First Name"
                    type="text"
                    v-model="user.firstName"
                    :rules="rules.firstName"
                    required
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6 pb-0">
                <v-text-field
                    class="ml-3"
                    name="company"
                    label="Company"
                    type="text"
                    v-model="user.company"
                    disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6 py-0">
                <v-text-field
                    class="mr-3"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    v-model="user.lastName"
                    :rules="rules.lastName"
                    required
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6 py-0">
                <v-text-field
                    class="ml-3"
                    name="phone"
                    label="Phone"
                    type="text"
                    v-model="user.phone"
                    :rules="rules.phone"
                    required
                    disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6 py-0">
                <v-text-field
                    class="mr-3"
                    name="email"
                    label="Email"
                    type="text"
                    v-model="user.email"
                    :rules="rules.email"
                    required
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="6 py-0">
                <v-text-field
                    class="ml-3"
                    name="whatsAppNumber"
                    label="WhatsApp Number"
                    type="text"
                    v-model="user.whatsAppNumber"
                    disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="elevation-12">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Address Information</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <span><b>
                  *PLEASE NOTE: The shipping address on this page is only used for DIRECTPAY orders and to evaluate your sales tax exemption status.  When paying with PayPal, it is YOUR RESPONSIBILITY to confirm the shipping address attached to your payment is correct.  If you have any questions, please contact us on WhatsApp at +1-321-830-3841.
                </b></span>
              </v-col>
            </v-row>
              <v-row>
                <v-col cols="6" class="pr-3" style="border-right: 1px solid black">
                  <h2 style="text-align: center">Business</h2>
                  <v-text-field
                      name="address1"
                      label="Address 1"
                      type="text"
                      v-model="user.address1"
                      :rules="rules.address"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="address2"
                      label="Address 2"
                      type="text"
                      v-model="user.address2"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="address3"
                      label="Address 3"
                      type="text"
                      v-model="user.address3"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="city"
                      label="City"
                      type="text"
                      v-model="user.city"
                      :rules="rules.city"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="state"
                      label="State"
                      type="text"
                      v-model="user.state"
                      :rules="rules.state"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="zip"
                      label="Zip"
                      type="text"
                      v-model="user.zip"
                      :rules="rules.zip"
                      required
                      disabled
                  ></v-text-field>
                  <v-autocomplete
                      name="country"
                      id="businessCountry"
                      label="Country"
                      v-model="user.country"
                      required
                      disabled
                      :items="countriesList"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="pl-3" style="border-left: 1px solid black">
                  <h2 style="text-align: center">Shipping</h2>
                  <v-text-field
                      name="shippingAddress1"
                      label="Address 1"
                      type="text"
                      v-model="user.shippingAddress1"
                      :rules="rules.address"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="shippingAddress2"
                      label="Address 2"
                      type="text"
                      v-model="user.shippingAddress2"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="shippingAddress3"
                      label="Address 3"
                      type="text"
                      v-model="user.shippingAddress3"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="shippingCity"
                      label="City"
                      type="text"
                      v-model="user.shippingCity"
                      :rules="rules.city"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="shippingState"
                      label="State"
                      type="text"
                      v-model="user.shippingState"
                      :rules="rules.state"
                      required
                      disabled
                  ></v-text-field>
                  <v-text-field
                      name="shippingZip"
                      label="Zip"
                      type="text"
                      v-model="user.shippingZip"
                      :rules="rules.zip"
                      required
                      disabled
                  ></v-text-field>
                  <v-autocomplete
                      name="shippingCountry"
                      id="shippingCountry"
                      label="Country"
                      v-model="user.shippingCountry"
                      disabled
                      required
                      :items="countriesList"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Wholesale Account</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                  name="username"
                  label="Username"
                  type="text"
                  v-model="user.username"
                  disabled
              ></v-text-field>
              <v-text-field
                  name="password"
                  label="Set New Password"
                  id="password"
                  type="password"
                  v-model="user.password"
              ></v-text-field>
              <v-text-field
                  name="confirmPassword"
                  label="Confirm New Password"
                  id="confirmPassword"
                  type="password"
                  v-model="user.confirmPassword"
                  :rules="rules.confirmPassword"
                  required
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-actions class="justify-space-around">
              <v-btn color="error" @click="resetForm()">Reset <span class="d-none d-lg-flex">Form</span></v-btn>
              <v-btn @click="clearErrors()">Clear<span class="d-none d-lg-flex">Errors </span></v-btn>
              <v-btn color="primary" @click="handleSubmit()">Save <span class="d-none d-lg-flex">Account</span></v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import countriesMixin from '@/_mixins/countriesMixin';

export default {
  name: 'profile',
  components: {
  },
  mixins: [
    countriesMixin
  ],
  data () {
    return {
      valid: true,
      rules: {
        username: [
          v => !!v || 'Username is required. Example: johndoe123'
        ],
        firstName: [
          v => !!v || 'First Name is required. Example: John',
          v => /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(v) || 'First Name must be valid input. Example: John' // https://andrewwoods.net/blog/2018/name-validation-regex/
        ],
        lastName: [
          v => !!v || 'Last Name is required. Example: Doe',
          v => /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(v) || 'Last Name must be valid input. Example: Doe' // https://andrewwoods.net/blog/2018/name-validation-regex/
        ],
        email: [
          v => !!v || 'Email is required. Example: john.doe@company.com',
          v => /.+@.+/.test(v) || 'Email must be valid. Example: john.doe@company.com'
        ],
        address: [
          v => !!v || 'Address Field is required. Example: 123 Anywhere St.'
        ],
        // taxRate: [
        //   v => !!v || 'Tax Rate % is required. Example: 6.5 or 0.0 if Tax Exempt',
        //   v => /^[\d.]+$/.test(v) || 'Tax Rate % must be valid. Example: 6.5 or 0.0 if Tax Exempt'
        // ],
        city: [
          v => !!v || 'City is required. Example: Orlando'
        ],
        state: [
          v => !!v || 'State is required. Example: FL'
        ],
        zip: [
          v => !!v || 'Zip Code is required. Example: 32826'
        ],
        phone: [
          v => !!v || 'Phone Number is required. Example: +1-321-830-3841 '
        ],
        password: [
          v => !!v || 'Password is required.'
        ],
        confirmPassword: [
          // v => !!v || 'Password Confirmation is required.',
          v => v === this.user.password || 'Passwords must match'
        ],
        question: [
          v => !!v || 'Question response is required.'
        ]
      },
      // TODO: Ask allen about removing this
      // user: {
      //   username: "",
      //   firstName: "",
      //   lastName: "",
      //   email: "",
      //   company: null,
      //   address1: "",
      //   address2: null,
      //   address3: null,
      //   city: "",
      //   state: "",
      //   zip: "",
      //   phone: "",
      //   password: "",
      //   confirmPassword: ""
      // },
      submitted: false,
      dialog: {
        displayed: false,
        title: '',
        text: ''
      }
    };
  },
  created () {

  },
  mounted () {
    // Workaround for a bug in vuetify. Normally vuetify puts autocomplete: off on v-autocomplete
    // components but chrome changed the word to "nope" so this is just setting that attribute
    document.getElementById('businessCountry').setAttribute('autocomplete', 'nope');
    document.getElementById('shippingCountry').setAttribute('autocomplete', 'nope');

    this.loadUserData();
  },
  computed: {
    ...mapState({
      accountUser: state => state.account.user
    }),
    user () {
      // Deep copy the user object so that we can display everything without risking
      // modification to the object in the store
      return JSON.parse(JSON.stringify(this.accountUser));
    },
    isTaxRateDisabled () {
      return this.selectedUsername === null || this.user.taxExempt === true;
    }
  },
  methods: {
    ...mapActions('users', ['updateByUsername']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    clearUser () {
      this.user.password = '';
      this.user.confirmPassword = '';
    },
    loadUserData () {
      // TODO Remove this after talking to allen
      // this.user = {
      //   username: this.accountUser.username,
      //   firstName: this.accountUser.firstName,
      //   lastName: this.accountUser.lastName,
      //   email: this.accountUser.email,
      //   company: (this.accountUser.company === null ? '' : this.accountUser.company),
      //   address1: this.accountUser.address1,
      //   address2: (this.accountUser.address2 === null ? '' : this.accountUser.address2),
      //   address3: (this.accountUser.address3 === null ? '' : this.accountUser.address3),
      //   city: this.accountUser.city,
      //   state: this.accountUser.state,
      //   zip: this.accountUser.zip,
      //   phone: this.accountUser.phone,
      //   password: "",
      //   confirmPassword: ""
      // }
    },
    resetForm () {
      this.clearUser();
      this.resetValidation();
      this.loadUserData();
    },
    clearErrors () {
      this.clearAlert();
      this.resetValidation();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    handleSubmit (e) {
      this.submitted = true;
      if (this.$refs.form.validate()) {
        this.updateByUsername(this.user).then((response) => {
          // this.clearUser();
          // router.push('/apply-success');
        }).catch((err) => {
          // this.errorAlert("Error: " + err);
        });
      } else {
        this.errorAlert('Invalid inputs! Please resolve and try again.');
      }
    }
  },
  watch: {
    accountUser (to, from) {
      if (to === null) {
        this.clearUser();
      } else {
        this.loadUserData();
      }
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#refresh-users-btn {
  margin-left: 20px;
}

</style>
