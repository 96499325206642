import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  data () {
    return {
      /**
             * This object should be used to control the InvalidProductsDialog component
             * It is updated by the addToCart method. It will need to be cleared when the dialog is closed
             */
      invalidProducts: {
        displayed: false,
        title: '',
        text: '',
        items: []
      }
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
      mediaBySku: state => state.products.mediaBySku,
      productsBySku: state => state.products.productsBySku,
      accessoriesBySku: state => state.products.accessoriesBySku,
      cartProducts: state => state.cart.products,
      cartTotal: state => state.cart.price,
      globalSettings: state => state.settings.settings
    }),
    ...mapGetters({
      allItemsBySku: 'products/allItemsBySku'
    }),
    cartMaximumLimit () {
      if (this.account.user.overrideSettings.cartMaxLimit !== null && this.account.user.overrideSettings.cartMaxLimit > 0) {
        return this.account.user.overrideSettings.cartMaxLimit;
      }
      return this.globalSettings.cartMaxLimit;
    }
  },
  methods: {
    ...mapActions('cart', [
      'addProductsToCart',
      'refreshCartProducts'
    ]),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    clearInvalidProducts () {
      this.invalidProducts.displayed = false;
      this.invalidProducts.title = '';
      this.invalidProducts.text = '';
      this.invalidProducts.items = [];
    },
    isCartThresholdExceeded (selectedTotal) {
      // Sum selected products total and cart total and see if it exceeds threshold of $10,000.00 (or whatever value is set to)
      const pendingTotal = parseFloat(this.cartTotal) + selectedTotal;
      return (pendingTotal > this.cartMaximumLimit);
    },
    /**
         * Builds cart items array and invoked cart service. Handles any invalid responses
         * @param skuQuantityObjArray this is an array of objects with the sku to add and the quantity of
         * that sku to add. Object structure is
         * {
         *  sku: "my sku blah blah",
         *  qty: 3
         * }
         * @returns
         */
    addToCart (skuQuantityObjArray) {
      this.loadingOverlay(true);

      const cartItems = [];
      let selectedTotal = 0.0;

      // Loop through each sku-qty pair and create the cart item
      skuQuantityObjArray.forEach(skuQtyObj => {
        const item = this.allItemsBySku[skuQtyObj.sku];
        const quantity = skuQtyObj.qty;

        // Make sure product exists
        if (item) {
          // CHECK IF PRODUCT IS ALREADY IN CART
          // YES = IGNORE PRODUCT
          // NO = DISPLAY PRODUCT IN GRID
          const index = this.cartProducts.findIndex((e) => e.sku === item.sku);
          let quantityInCart = 0;
          if (index !== -1) {
            const existingCartProduct = this.cartProducts[index];
            quantityInCart = parseInt(existingCartProduct.quantity);
          }

          cartItems.push({
            id: item.id,
            sku: item.sku,
            quantity: (quantity + quantityInCart),
            price: item.wholesaleprice
          });

          selectedTotal += (quantity * item.wholesaleprice);
        }
      });

      // Verify that future total will not exceed cart threshold before adding products to cart!
      if (!this.isCartThresholdExceeded(selectedTotal)) {
        this.addProductsToCart(cartItems).then(
          (response) => {
            if (response.invalidProducts.length > 0) {
              this.invalidProducts.title = 'Products out of sync with server!';

              // Build formatted list from response
              this.invalidProducts.items = [];

              for (const invalidProduct of response.invalidProducts) {
                const item = {};
                item.title = invalidProduct.item.title;

                switch (invalidProduct.code) {
                  case 'PRODUCT_NOT_FOUND':
                  case 'OUT_OF_STOCK':
                    item.subtitle = "<div><span class='invalid-reason'>OUT OF STOCK</span>" + ": Product no longer in stock.  <span class='correct-price'>Will be removed from cart.</span></div>";
                    break;

                  case 'INSUFFICIENT_STOCK':
                    item.subtitle = "<div><span class='invalid-reason'>INSUFFICIENT STOCK</span>" + ": Quantity in cart updated from <span class='incorrect-price'>" + invalidProduct.userQuantity + "</span> to <span class='correct-price'>" + invalidProduct.serverStock + '</span>.</div>';
                    break;

                  case 'PRICE_MISMATCH':
                    item.subtitle = "<div><span class='invalid-reason'>PRICE CHANGE</span>" + ": Price in cart updated from <span class='incorrect-price'>" + this.formatCurrency(invalidProduct.userPrice) + "</span> to <span class='correct-price'>" + this.formatCurrency(invalidProduct.serverPrice) + '</span>.</div>';
                    break;
                }

                this.invalidProducts.items.push(item);
              }
              this.invalidProducts.displayed = true;
              return false; // add to cart failed
            }
            return true; // add to cart succeeded
          }
        );
      } else {
        this.loadingOverlay(false);
        this.errorAlert('Unable to add product to cart. Cart cannot exceed ' + this.formatCurrency(this.cartMaximumLimit));
        return false;
      }
    }
  }
};
