<template>
    <v-app-bar app fixed clipped-left dark color="primary">
      <v-app-bar-nav-icon class="d-flex d-lg-none" @click="toggleMenu"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-none d-md-block">{{ titleLeft }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-none d-sm-block" v-if="showCenterTitle">{{ titleCenter }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <span>
        <v-btn :color="creditColor" dark to="/credit" v-if="showHeaderBtns">
          <v-icon dark left>payments</v-icon>
          <span class="d-none d-md-flex">Store Credit</span>
          <span id="cart-price">{{ formattedCredit }}</span>
        </v-btn>
      </span>
      <span id="cart-info" v-if="!userIsReadOnly && showHeaderBtns">
        <v-btn color="pink" dark to="/cart">
          <v-icon dark left>shopping_cart</v-icon>
          <span class="d-none d-md-flex">{{ formattedCartCount }} Items:</span>
          <span id="cart-price">{{ formattedCartPrice }}</span>
        </v-btn>
      </span>
      <span v-if="showHeaderBtns" id="account-info">
        <v-btn color="blue" to="/account">
          <v-icon dark left>account_box</v-icon>
          <span class="d-none d-md-block">{{ greeting }}</span>
        </v-btn>
      </span>
    </v-app-bar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import formatterMixin from '@/_mixins/formatterMixin';
import { EventBus } from '@/_helpers';

export default {
  name: 'global-header',
  components: {

  },
  data () {
    return {

    };
  },
  beforeMount () {

  },
  mounted () {
    this.getCreditByUsername(this.account.user.username);
  },
  computed: {
    ...mapState({
      account: state => state.account,
      globalSettings: state => state.settings.settings,
      cartCount: state => state.cart.count,
      cartTotal: state => state.cart.price,
      balancesByUsername: state => state.credits.balancesByUsername
    }),
    ...mapGetters('account', ['userIsReadOnly']),
    minimumOrderQtyThreshold () {
      if (this.account.user && this.account.user.overrideSettings && this.account.user.overrideSettings.minimumOrderQtyThreshold && this.account.user.overrideSettings.minimumOrderQtyThreshold > 0) {
        return this.account.user.overrideSettings.minimumOrderQtyThreshold;
      }

      return this.globalSettings.minimumOrderQtyThreshold;
    },
    formattedCartCount () {
      return this.numberWithCommas(this.cartCount);
    },
    formattedCartPrice () {
      return this.formatCurrency(this.cartTotal);
    },
    greeting () {
      if (this.account.user && this.account.user.firstName && this.account.user.lastName) {
        return this.account.user.firstName + ' ' + this.account.user.lastName;
      }
      return '';
    },
    storeCredit () {
      const username = this.account.user.username;
      return this.balancesByUsername[username]?.balance || 0;
    },
    formattedCredit () {
      return this.formatCurrency(this.storeCredit);
    },
    creditColor () {
      if (this.storeCredit >= 0) {
        return 'green';
      } else {
        return 'red';
      }
    },
    username () {
      return this.account.username;
    },
    titleCenter () {
      if (this.userIsReadOnly) {
        return 'Your account is in Read-Only mode';
      } else {
        return `Free Shipping On Orders Over ${this.minimumOrderQtyThreshold}!`;
      }
    },
    titleLeft () {
      if (this.$route.meta && 'title' in this.$route.meta) {
        return this.$route.meta.title;
      }

      return 'GizmoTrader Wholesale';
    },
    showHeaderBtns () {
      if (this.$route.meta && 'showHeaderBtns' in this.$route.meta) {
        return this.$route.meta.showHeaderBtns;
      }

      return true;
    },
    showCenterTitle () {
      if (this.$route.meta && 'showCenterTitle' in this.$route.meta) {
        return this.$route.meta.showCenterTitle;
      }

      return true;
    }
  },
  methods: {
    ...mapActions('credits', ['getCreditByUsername']),
    toggleMenu () {
      EventBus.$emit('toggle-menu', {});
    }
  },
  watch: {
  },
  mixins: [
    formatterMixin
  ]
};

</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#cart-info {
  margin-left: 20px;
  margin-right: 20px;
}

#cart-price {
  margin-left: 8px;
  margin-right: 10px;
}

#menu-icon {
  // https://stackoverflow.com/questions/49434540/how-to-remove-spacing-from-googles-material-design-icons-exterior
  margin-left: -365px;
}

</style>
