import config from 'config';
import { authHeader } from '@/_helpers';

export const settingsService = {
  getAll,
  update
};

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/settings/`, requestOptions).then(handleResponse);
}

function update (settings) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(settings)
  };

  return fetch(`${config.apiUrl}/settings/${settings.id}`, requestOptions).then(handleResponse);
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // store.dispatch('alert/error', "Please login again!", { root: true }).then(() => {
        //     logout();
        //     location.reload(true);
        // });
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
