<template>
  <v-container fluid v-if="!userIsReadOnly">
    <v-row>
      <v-col grow>
        <div v-if="confirmedOrder !== null">
          <div id="confirmation-header">
            <h2>Thank you for your order!</h2>
            <h4>Order #: {{ orderIdFormatter(confirmedOrder._id) }}</h4>
            <div style="margin: 20px 0px 10px 0px;">
              You've selected to pay by Direct Payment, which will provide a <b>2% discount</b> on your order! Our preferred payment method is <b>Zelle</b> as it is free and instant for all parties. However, we also accept payment by ACH or Wire Transfer.
            </div>

            <div style="margin: 10px 0px;">
              Please send your direct payment in the amount <b>{{ formatCurrency(confirmedOrder.total) }}</b> using the following information:
            </div>

            <div style="margin: 10px 20px;">
              <table style="font-size: 0.95em;">
                <tr>
                  <td style="width: 260px"><b>ZELLE PAYMENT INFO:</b></td>
                  <td style="width: 310px"><b>ACH/WIRE PAYMENT INFO:</b></td>
                  <td style="width: 300px"></td>
                </tr>
                <tr>
                  <td>gizmotraderzelle@gmail.com</td>
                  <td>Receiving Bank Name:</td>
                  <td>Chase</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Receiving Bank Address:</td>
                  <td>315 S. Avalon Park Blvd., Orlando, FL 32828</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Name:</td>
                  <td>GizmoTrader LLC</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Address:</td>
                  <td>15236 E. Colonial Dr., Orlando, FL 32826</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Account #:</td>
                  <td>707607690</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Routing # (ACH Transfers):</td>
                  <td>267084131</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Beneficiary Routing # (Wire Transfers):</td>
                  <td>021000021</td>
                </tr>
                <tr>
                  <td></td>
                  <td>SWIFT Code (International Wire Payments):</td>
                  <td>CHASUS33</td>
                </tr>
              </table>
            </div>

            <div style="margin: 10px 0px;">
              <b>Important Note:</b> If proof of payment is not received within <b>3 business days</b>, this order may be canceled.
            </div>

            <div style="margin: 10px 0px;">
              <b>Please contact us at any time for assistance via WhatsApp at <span style="white-space: nowrap;">(321) 830-3841</span>.</b>
            </div>
          </div>
        </div>
        <div v-else-if="confirmedOrder === null">
          No active orders to display.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import formatterMixin from '@/_mixins/formatterMixin';

export default {
  name: 'confirmation',
  data () {
    return {
      drawer: true
    };
  },
  components: {
  },
  beforeMount () {

  },
  computed: {
    ...mapState({
      confirmedOrder: state => state.orders.confirmedOrder,
      username: state => state.account.username
    }),
    ...mapGetters('account', ['userIsReadOnly'])
  },
  created () {

  },
  mounted () {
    if (this.confirmedOrder === null) {
      this.loadingOverlay(true);
    }
  },
  updated () {

  },
  methods: {
    ...mapActions('orders', ['clearConfirmedOrder']),
    ...mapActions('credits', ['getCreditByUsername']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    })
  },
  beforeRouteLeave (to, from, next) {
    this.clearConfirmedOrder();
    next();
  },
  watch: {
    confirmedOrder (to, from) {
      if (this.confirmedOrder !== null) {
        if (this.username) {
          this.getCreditByUsername(this.username);
        }
        // this.loadingOverlay(false);
        setTimeout(() => {
          this.loadingOverlay(false);
        }, 100);
      }
    }
  },
  mixins: [
    formatterMixin
  ]
};

</script>

<style scoped>

#direct-payment-info {
  margin: 20px;
}

</style>
