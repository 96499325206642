import config from 'config';
import { authHeader } from '@/_helpers';

export const cartService = {
  addToCart,
  addToCartBulk,
  getAll,
  getAllByUsername,
  getInvalidByUsername,
  deleteAllByUsername,
  delete: _delete,
  synchronize
};

function addToCart (product) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(product)
  };

  return fetch(`${config.apiUrl}/cart/${product.sku}`, requestOptions).then(handleResponse);
}

function addToCartBulk (items) {
  const requestBody = { items: items };

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody)
  };

  return fetch(`${config.apiUrl}/cart`, requestOptions).then(handleResponse);
}

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/cart/all`, requestOptions).then(handleResponse);
}

function getAllByUsername () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/cart`, requestOptions).then(handleResponse);
}

function getInvalidByUsername () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/cart/invalid`, requestOptions).then(handleResponse);
}

function deleteAllByUsername () {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/cart`, requestOptions).then(handleResponse);
}

function _delete (sku) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/cart/${sku}`, requestOptions).then(handleResponse);
}

function synchronize () {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/cart/synchronize`, requestOptions).then(handleResponse);
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
