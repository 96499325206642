<template>
  <v-container fluid>
    <v-row>
      <v-col grow>
        <div v-if="confirmedOrder !== null">
          <div id="confirmation-header">
            <h2>Thank you for your order!</h2>
            <h3>Order ID: {{ orderIdFormatter(confirmedOrder._id) }}</h3>
            <br>
            <p>We’ll send a confirmation email when your order ships.</p>
            <p>For assistance with this order, please contact us on WhatsApp at <span style="white-space: nowrap;"><b>+1-321-830-3841 </b></span> or email us at <a href="mailto:wholesale@gizmotrader.com">wholesale@gizmotrader.com</a>.</p>
          </div>
        </div>
        <div v-else-if="confirmedOrder === null">
          No active orders to display.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import formatterMixin from '@/_mixins/formatterMixin';

export default {
  name: 'confirmation',
  data () {
    return {
      drawer: true
    };
  },
  components: {
  },
  beforeMount () {

  },
  computed: {
    ...mapState({
      confirmedOrder: state => state.orders.confirmedOrder
    })
  },
  created () {

  },
  mounted () {
    if (this.confirmedOrder === null) {
      this.loadingOverlay(true);
    }
  },
  updated () {

  },
  methods: {
    ...mapActions('orders', ['clearConfirmedOrder']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    })
  },
  beforeRouteLeave (to, from, next) {
    this.clearConfirmedOrder();
    next();
  },
  watch: {
    confirmedOrder (to, from) {
      if (this.confirmedOrder !== null) {
        setTimeout(() => {
          this.loadingOverlay(false);
        }, 100);
      }
    }
  },
  mixins: [
    formatterMixin
  ]
};

</script>

<style scoped>

</style>
