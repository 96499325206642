<template>
  <v-container fluid id="orders-page">

    <ProductsDialog
      :showDialog="showMediaDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="1000px"
      type="media"
      :product="mediaProduct"
      :productsBySkuProp="productsBySku"
      :mediaBySkuProp="mediaBySku"
      @updateDialog_media="showMediaDialog = $event"
      :showActions="false"
    />

    <ProductsDialog
      :showDialog="showFunctionalityDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="1200px"
      type="functionality"
      :productsBySkuProp="productsBySku"
      :mediaBySkuProp="mediaBySku"
      :product="mediaProduct"
      @updateDialog_functionality="showFunctionalityDialog = $event"
      :showActions="false"
    />

    <v-dialog v-model="invoiceDialog" width="1000px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-container class="invoice-product-header" fill-height>
          <v-row row align-center>
            <v-col cols="12">
              <span class="centered">
                <h1>
                  Order Invoice: {{ invoiceOrderId }}
                </h1>
              </span>
            </v-col>
          </v-row>
        </v-container>

        <v-container fill-height>
          <v-row row align-center>
              <v-col cols="12">
                <iframe height="700" width="956" :src="invoiceUrl" frameborder="0"></iframe>
              </v-col>
            </v-row>
          </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row align-center>
            <v-btn class="pb-2" color="primary" text @click="onCloseInvoiceDialogHandler">Close</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-if="accountUser.isAdmin && !$vuetify.breakpoint.mobile">
      <v-col cols="5" class="pb-0">
        <v-text-field
          :label=searchFilterLabel
          v-model="searchFilter"
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="pb-0"></v-col>

      <v-col cols="2" class="pb-0">
        <v-text-field
            name="usernameFilter"
            label="Search Users"
            type="text"
            v-model="searchUsersFilter"
            outlined
            clearable
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="pb-0"></v-col>

      <v-col cols="2" class="pb-0">
        <v-select
          class="mt-2"
          :items="filteredUsernames"
          filled
          :label=filteredUsernamesLabel
          v-model="selectedUsername"
          clearable
        ></v-select>
      </v-col>

      <v-col cols="1" class="pb-0">
        <span>
          <v-btn dark large color="pink" @click="resetUsersFilters">RESET
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-container id="mobileControls"
                 class="px-3 mt-2"
                 v-else-if="accountUser.isAdmin && $vuetify.breakpoint.mobile">
      <v-row class="px-3 pb-0">
        <v-text-field
          :label=searchFilterLabel
          v-model="searchFilter"
          outlined
          clearable
          dense
        ></v-text-field>
      </v-row>

      <v-row class="px-3 pb-0">
        <v-text-field
            name="usernameFilter"
            label="Search Users"
            type="text"
            v-model="searchUsersFilter"
            outlined
            clearable
            dense
        ></v-text-field>
      </v-row>

      <v-row class="px-3 pb-0">
        <v-select
          style="min-width: 100%;"
          :items="filteredUsernames"
          filled
          :label=filteredUsernamesLabel
          v-model="selectedUsername"
          clearable
          dense
        ></v-select>
      </v-row>

      <v-row class="px-3 mb-2">
        <v-btn dark color="pink" @click="resetUsersFilters" style="min-width: 100%">RESET</v-btn>
      </v-row>
    </v-container>

    <v-row v-else>
      <v-col cols="5" class="pb-0">
        <v-text-field
          label="Search Order IDs"
          v-model="searchFilter"
          outline
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="pb-0">
      </v-col>
      <v-col cols="1" class="pb-0">
        <span id="refresh-orders-btn">
          <v-btn fab dark small color="pink" @click="refreshOrders">
            <v-icon dark>refresh</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pt-0">
        <div id="orders-table"  v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
          <!-- <ag-grid-vue style="width: 100%; height: 100%;" class="ag-theme-material" -->
          <ag-grid-vue style="width: 100%; height: 100%;" class="ag-theme-material"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs"
            :masterDetail="true"
            :detailCellRendererParams="detailCellRendererParams"
            :detailRowAutoHeight="true"
            :rowData="rowData"
            @cell-clicked="onOrderCellClicked"
            @modelUpdated="onModelUpdated"
            @grid-ready="onGridReady"
            @first-data-rendered="onFirstDataRendered"
          ></ag-grid-vue>
        </div>
        <div id="orderCards"
             class="d-flex flex-column pr-5"
             :class="{cardsAdmin: accountUser.isAdmin, cardsNonAdmin: !accountUser.isAdmin}"
             v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
          <v-card class="mb-5" cols="12" v-for="(order, i) in rowData" :key="i">
            <v-card-title class="mb-3">
                ID: {{orderIdFormatter(order._id)}}
            </v-card-title>
            <v-card-subtitle class="mb-3">
              Date: {{dateFormatter(order.createdDate)}}
              Status: {{order.fulfillmentStatus}}
            </v-card-subtitle>
            <v-card-text>
              <v-row dense v-if="order.shipcarrier && order.shiptracking">
                <v-col>Tracking:
                  <a class="v-btn v-btn--flat v-btn--small theme--light primary--text tracking-button"
                     :href="createTrackingLinkUrl(order)"
                     target="_blank">
                    <i class="material-icons">local_shipping</i>
                  </a>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>Items: {{order.itemquantity}}</v-col>
              </v-row>
              <v-row dense>
                <v-col>Discount: {{discountCurrencyFormatter(order.discount)}}</v-col>
              </v-row>
              <v-row dense>
                <v-col>Subtotal: {{formatCurrency(order.itemtotal)}}</v-col>
              </v-row>
              <v-row dense>
                <v-col>Shipping: {{formatCurrency(order.shippingtotal)}}</v-col>
              </v-row>
              <v-row dense>
                <v-col>Taxes: {{formatCurrency(order.taxtotal)}}</v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col>Total: {{formatCurrency(order.total)}}</v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="onOrderExpand(order)"
              >
                <v-icon>{{ isExpanded(order._id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="isExpanded(order._id)">
                <v-divider></v-divider>
                <v-card-text>
                  <div id="products-cards"
                      class="d-flex flex-column mb-5"
                      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
                    <v-card class="mb-5" cols="12" v-for="(product, j) in expandedOrders[order._id]" :key="j">
                      <v-carousel
                        :cycle="cycle"
                        v-model="mobileSlider[product.sku]"
                        height="200px"
                        hide-delimiters
                        show-arrows-on-hover
                      >
                        <v-carousel-item
                          v-for="(item,j) in carouselItems(product)"
                          :key="j"
                          :src="item.src"
                          :transition="cycle"
                          :reverse-transition="cycle"
                          @error="onErrorCarouselItem(product)"
                        >
                          <v-container class="centered" fill-height v-if="isMediaMissing(item.src)">
                            <v-row align-center>
                              <v-col cols="12">
                                <h1>
                                  <span class="media-message">Photo No Longer Available</span>
                                </h1>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-carousel-item>
                      </v-carousel>
                      <v-card-title>
                        {{product.title}}
                      </v-card-title>
                      <v-card-subtitle>
                        <v-row>
                          Price:{{formatCurrency(product.price)}}
                          Quantity:{{product.quantity}}
                        </v-row>
                        <v-row>
                          Functional:<a class="theme--light primary--text functionality-button" href="#"
                                        @click="mediaProduct = product; showFunctionalityDialog = true;">{{product.functionality}}</a>
                        </v-row>
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          icon
                          @click="onProductExpand(product)"
                        >
                          <v-icon>{{ isExpanded(product.sku) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                      </v-card-actions>

                      <v-expand-transition>
                        <div v-show="isExpanded(product.sku)">
                          <v-divider></v-divider>

                          <v-card-text>
                            <v-row>
                              <v-col cols="6">Brand: {{product.brand}}</v-col>
                              <v-col cols="6">Model: {{product.model}}</v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">Color: {{product.color}}</v-col>
                              <v-col cols="6">Storage: {{product.storagecapacity}}</v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">Carrier: {{product.carrier}}</v-col>
                              <v-col cols="6">Condition: {{product.condition}}</v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">Lock Status: {{product.lockstatus}}</v-col>
                            </v-row>
                          </v-card-text>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </div>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import { router } from '@/router';
import formatterMixin from '@/_mixins/formatterMixin';

import ProductsDialog from '@/components/dialogs/ProductsDialog';

// import { PDFDocument } from '@/_resources/js/pdfkit.standalone';

const PDFDocument = require('@/_resources/js/pdfkit.standalone');
const blobStream = require('@/_resources/js/blob-stream');

export default {
  name: 'orders',
  data () {
    return {
      searchFilter: '', // The order ID search filter
      searchUsersFilter: '', // The username search filter
      selectedUsername: null, // The selected username to load orders for
      filterModel: {}, // The ag-grid filter model
      countSelected: 0,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      columnFields: null,
      detailCellRendererParams: null,
      rowData: null,
      mediaProduct: null,
      mediaProductRow: null,
      timer: null,
      slider: 0,
      items: [],
      itemErrors: [],
      showMediaDialog: false,
      showFunctionalityDialog: false,
      invoiceDialog: false,
      invoiceUrl: null,
      invoiceOrderId: null,
      productsBySku: {},
      mediaBySku: {},
      autosizingColumns: false,
      window: {
        width: 0,
        height: 0
      },
      expandedOrders: {},
      expandedProducts: [],
      carouselItemErrors: [],
      cycle: false,
      mobileSlider: {}
    };
  },
  components: {
    AgGridVue,
    ProductsDialog
  },
  created () {
  },
  destroyed () {
    // console.log("removing resize event listener")
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount () {
    this.loadingOverlay(true);
    // console.log("adding resize event listener")
    window.addEventListener('resize', this.handleResize);

    this.gridOptions = {};
    this.columnDefs = [
      { headerName: 'ID', field: '_id', sortable: true, filter: true, width: 240, valueFormatter: this.orderIdFormatter, cellRenderer: 'agGroupCellRenderer' },
      { headerName: 'Date', field: 'createdDate', sortable: true, sort: 'desc', filter: true, width: 240, valueFormatter: this.dateTimeFormatter },
      { headerName: 'Status', field: 'fulfillmentStatus', sortable: true, filter: true, width: 240 },
      { headerName: 'Items', field: 'itemquantity', sortable: true, filter: true, width: 100 },
      { headerName: 'Subtotal', field: 'itemtotal', sortable: true, filter: true, valueFormatter: this.currencyFormatter, cellStyle: { textAlign: 'left' } },
      { headerName: 'Discount', field: 'discount', sortable: true, filter: true, valueFormatter: this.discountCurrencyFormatter, cellStyle: { textAlign: 'left' } },
      { headerName: 'Shipping', field: 'shippingtotal', sortable: true, filter: true, valueFormatter: this.currencyFormatter, width: 120, cellStyle: { textAlign: 'left' } },
      { headerName: 'Taxes', field: 'taxtotal', sortable: true, filter: true, valueFormatter: this.currencyFormatter, cellStyle: { textAlign: 'left' } },
      { headerName: 'Store Credit Used', field: 'storecreditused', sortable: true, filter: true, valueFormatter: this.discountCurrencyFormatter, cellStyle: { textAlign: 'left' } },
      { headerName: 'Total', field: 'total', sortable: true, filter: true, valueFormatter: this.currencyFormatter, cellStyle: { textAlign: 'left' } },
      { headerName: 'Carrier', field: 'shipcarrier', hide: true },
      { headerName: 'Invoice', field: '_id', sortable: false, filter: false, colId: 'invoice', width: 90, cellRenderer: invoiceCellRenderer, suppressMenu: true },
      { headerName: 'Tracking', field: 'shiptracking', colId: 'shiptracking', sortable: true, filter: true, width: 90, suppressMenu: true, cellRenderer: trackingCellRenderer }
    ];

    this.columnFields = this.columnDefs.map(columnDef => columnDef.field);
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { headerName: 'Item', field: 'dbitemnum', sortable: true, filter: true, width: 120 },
          { headerName: 'Price', field: 'price', sortable: true, filter: true, valueFormatter: this.currencyFormatter, width: 120 },
          { headerName: 'Quantity', field: 'quantity', sortable: true, filter: true, width: 120 },
          { headerName: 'Total', field: 'total', sortable: true, filter: true, valueFormatter: this.currencyFormatter, width: 120 },
          { headerName: 'Brand', field: 'brand', sortable: true, filter: true, width: 120 },
          { headerName: 'Model', field: 'model', sortable: true, filter: true },
          { headerName: 'Color', field: 'color', sortable: true, filter: true, width: 120 },
          { headerName: 'Storage', field: 'storagecapacity', sortable: true, filter: true, width: 120 },
          { headerName: 'Carrier', field: 'carrier', sortable: true, filter: true, width: 140 },
          { headerName: 'Condition', field: 'condition', sortable: true, filter: true, width: 160 },
          { headerName: 'Lock Status', field: 'lockstatus', sortable: true, filter: true, width: 160 },
          { headerName: 'Functionality', field: 'functionality', sortable: true, filter: true, colId: 'functionality', width: 160, cellRenderer: functionalityCellRenderer },
          { headerName: 'Media', field: 'sku', sortable: false, filter: false, suppressSizeToFit: true, suppressAutoSize: true, suppressMenu: true, width: 90, colId: 'media', cellRenderer: mediaCellRenderer }
        ],
        rowClassRules: {
          'media-product-row': (params) => {
            if (params.data !== undefined && params.data.isMediaProductRow !== undefined) {
              return params.data.isMediaProductRow;
            }
            return '';
          }
        },
        onGridReady: params => {
          params.api.setDomLayout('autoHeight');
        },
        onCellClicked: event => {
          // Clear OLD highlighted row
          if ((event.column.getColId() === 'media' || event.column.getColId() === 'functionality') && event.data !== undefined) {
            // Clear OLD highlighted row
            if (this.mediaProductRow !== null && this.mediaProduct !== null) {
              this.mediaProduct.isMediaProductRow = false;
              this.gridApi.redrawRows({ rowNodes: [this.mediaProductRow] });
              this.mediaProductRow = null;
              this.mediaProduct = null;
            }

            // Highlight New Row
            this.mediaProductRow = event.node;
            this.mediaProduct = this.mediaProductRow.data;
            this.mediaProduct.isMediaProductRow = true;
            this.gridApi.redrawRows({ rowNodes: [this.mediaProductRow] });

            if (event.column.getColId() === 'media') {
              this.showMediaDialog = true;
            } else if (event.column.getColId() === 'functionality') {
              this.showFunctionalityDialog = true;
            }
          }
        },
        onFirstDataRendered (params) {
        }
      },
      getDetailRowData: (params) => {
        if (this.accountUser.isAdmin) {
          this.getOrderDetailsByIdUsernameParam({ orderId: params.data._id, username: this.selectedUsername }).then(response => {
            // PROCESS RESULTS
            const orderItems = response[0].items;
            const rowDataArr = this.processOrderItems(orderItems);
            params.successCallback(rowDataArr);
          });
        } else {
          this.getOrderDetailsById(params.data._id).then(response => {
            // PROCESS RESULTS
            const orderItems = response[0].items;
            const rowDataArr = this.processOrderItems(orderItems);
            params.successCallback(rowDataArr);
          });
        }
      }
    };
  },
  computed: {
    ...mapState({
      ordersData: state => state.orders.orders,
      accountUser: state => state.account.user
    }),
    ...mapState('users', ['usernames', 'users']),
    searchFilterLabel () {
      if (this.selectedUsername === null) {
        return 'Search Order IDs';
      } else {
        return 'Search Order IDs for ' + this.selectedUsername;
      }
    },
    filteredUsernames () {
      let filteredUsers = [];
      if (this.usernames !== null && this.usernames.length > 0) {
        // copy all users
        filteredUsers = this.usernames;

        // APPLY SEARCH FILTER IF SET
        if (this.searchUsersFilter !== undefined && this.searchUsersFilter !== null && this.searchUsersFilter !== '') {
          // remove all whitespace from search filter
          const whitespace = /\s/g;
          let search = this.searchUsersFilter.replaceAll(whitespace, '');

          // the search filter should be in the form of name|name2|name3 etc. This is valid regex
          // for searching for name or name2 or name3 etc. Create regex object from search string and
          // apply it to the array. the i flag is to make it case insensitive
          search = new RegExp(search, 'gi');

          filteredUsers = filteredUsers
            .filter((username) => {
              const results = username.match(search);
              return results ? results.length > 0 : false;
            })
            .sort((a, b) => a.toLowerCase() > b.toLowerCase()); // custom sort on lowercase version
        }
      }

      return filteredUsers;
    },
    filteredUsernamesLabel () {
      if (this.selectedUsername !== null) {
        return 'Displaying Orders For User';
      }

      if (this.searchUsersFilter === null || this.searchUsersFilter === undefined || this.searchUsersFilter === '') {
        return 'Select Username';
      }

      if (this.filteredUsernames.length === 1) {
        return '1 Matching User';
      }
      return this.filteredUsernames.length + ' Matching Users';
    },
    formattedOrdersCount () {
      return this.numberWithCommas(this.ordersData.length);
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    this.initializeOrdersData();
    this.selectedUsername = this.$route.params.username || this.selectedUsername;
  },
  updated () {

  },
  methods: {
    ...mapActions('orders', ['clearOrders', 'refreshOrders', 'getAllOrdersByUsernameParam', 'getOrderDetailsById', 'getOrderDetailsByIdUsernameParam']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    onFirstDataRendered (params) {
      // setTimeout(() => {
      //   this.autoSizeColumns("data first rendered"); // temp disabling
      // }, 100);
    },
    onGridReady (params) {
      // setTimeout(() => {
      //   this.autoSizeColumns("grid ready"); // temp disabling
      // }, 100);
    },
    // onRowGroupOpened(params) {
    //   setTimeout(() => {
    //     this.autoSizeColumns();
    //   }, 10);
    // },
    autoSizeColumns (source) {
      if (!this.autosizingColumns) {
        if (router.currentRoute.path === '/orders' || router.currentRoute.path === '/orders#') {
          this.autosizingColumns = true;
          // console.log("Auto sizing columns: " + source);

          setTimeout(() => {
            this.autoSizeAll(false);
          }, 100);

          // this.autoSizeAll(false);
          if (this.window.width === 0) {
            this.window.width = window.innerWidth;
          }
          if (this.window.height === 0) {
            this.window.height = window.innerHeight;
          }
          // if (this.window.width !== null && this.window.width >= 1920) {
          //   // console.log("Sizing to fit: " + source);
          //   console.log(this.window.width);
          //   this.sizeToFit();
          // }
          this.autosizingColumns = false;
        }
      }
    },
    autoSizeAll (skipHeader) {
      if (this.gridColumnApi) {
        this.gridColumnApi.autoSizeColumns(this.columnFields, skipHeader);
      }
    },
    sizeToFit () {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    handleResize () {
      // console.log("handle resize");
      if (router.currentRoute.path === '/orders' || router.currentRoute.path === '/orders#') {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        setTimeout(() => {
          this.autoSizeColumns('window resize');
        }, 100);
      }
    },
    processOrderItems (orderItems) {
      const rowDataArr = [];
      orderItems.forEach((obj) => {
        const rowDataObj = {};

        // if (obj["sku"].toLowerCase().includes("acc ")) {
        if (obj.sku.toLowerCase().substring(0, 3) === 'acc') {
          // Process Accessory product!
          rowDataObj.dbitemnum = obj.category3;

          rowDataObj.brand = obj.brand;
          rowDataObj.model = obj.storefrontmodel;
          rowDataObj.price = obj.wholesaleprice;

          let objQuantity = 1;
          if ('quantity' in obj && obj.quantity !== undefined) {
            objQuantity = parseInt(obj.quantity);
          }
          rowDataObj.quantity = objQuantity;
          rowDataObj.total = objQuantity * parseFloat(obj.wholesaleprice);

          rowDataObj.sku = obj.sku;

          rowDataObj.isMediaProductRow = false; // GOOD
        } else {
          rowDataObj.brand = obj.brand;
          // Use "storefrontmodel" instead of "model"
          rowDataObj.model = obj.storefrontmodel;

          if ('color' in obj) {
            rowDataObj.color = obj.color.toUpperCase();
          } else {
            rowDataObj.color = '';
          }

          rowDataObj.storagecapacity = obj.storagecapacity;
          rowDataObj.carrier = obj.originalcarrier;

          // Use "storefrontcondition" instead of "condition"
          rowDataObj.condition = obj.storefrontcondition;

          // Use "wholesaleprice" value
          rowDataObj.price = obj.wholesaleprice;

          let objQuantity = 1;
          if ('quantity' in obj && obj.quantity !== undefined) {
            objQuantity = parseInt(obj.quantity);
          }
          rowDataObj.quantity = objQuantity;
          rowDataObj.total = objQuantity * parseFloat(obj.wholesaleprice);

          rowDataObj.sku = obj.sku;
          rowDataObj.dbitemnum = obj.dbitemnum;
          rowDataObj.title = obj.title;

          // Lock Status:
          // if [sku] contains " UUU " (with spaces both before and after UUU) then display "GSM Unlocked"
          // if [sku] contains "LLL" then display "Locked"
          // if [sku] contains "UUUVZN" then display "Verizon Unlocked"
          // if [sku] contains "UUUSPT" then display "CDMA/GSM Unlocked" (edited)
          // if [sku] contains "MVNO" then display "MVNO Unlocked"
          // if [sku] contains "FACTUUU" then display "Factory Unlocked"
          let lockStatus = '';
          if (obj.sku.toUpperCase().includes(' UUU ')) {
            lockStatus = 'GSM Unlocked';
          } else if (obj.sku.toUpperCase().includes('LLL')) {
            lockStatus = 'Locked';
          } else if (obj.sku.toUpperCase().includes('UUUVZN')) {
            lockStatus = 'Verizon Unlocked';
          } else if (obj.sku.toUpperCase().includes('UUUSPT')) {
            lockStatus = 'CDMA/GSM Unlocked';
          } else if (obj.sku.toUpperCase().includes('MVNO')) {
            lockStatus = 'MVNO Unlocked';
          } else if (obj.sku.toUpperCase().includes('FACTUUU')) {
            lockStatus = 'Factory Unlocked';
          }
          rowDataObj.lockstatus = lockStatus;

          // Functionality:
          // if [sku] contains "pzn" then display "Issue"
          // if [sku] contains "lwi" then display "Defective"
          // if neither of the above is true, then display "Fully Functional"
          // can we make the  field a link that would open up the [notes] field?
          let functionality = 'Fully Functional';
          if (obj.sku.toLowerCase().includes('pzn')) {
            functionality = 'Issue';
          } else if (obj.sku.toLowerCase().includes('lwi')) {
            functionality = 'Defective';
          }
          rowDataObj.functionality = functionality;
          rowDataObj.isMediaProductRow = false; // GOOD
        }

        rowDataObj.expanded = false;
        this.mobileSlider[rowDataObj.sku] = 0;
        rowDataArr.push(rowDataObj);

        // STORE PRODUCT IN ON-GOING PRODUCTSBYSKU MAP
        this.productsBySku[obj.sku] = obj;

        // load media by sku
        const mediaArray = [];
        for (let i = 1; i <= 7; i++) {
          const mediaIdx = 'media' + i;
          if (mediaIdx in obj && obj[mediaIdx] !== undefined && obj[mediaIdx] !== '') {
            mediaArray.push({ src: obj[mediaIdx] });
          }
        }

        this.mediaBySku[obj.sku] = mediaArray;
      });
      return rowDataArr;
    },
    onOrderCellClicked (event) {
      if ((event.column.getColId() === 'invoice') && event.data !== undefined) {
        // Lookup full order info from vuex store data
        const order = this.ordersData.find(obj => {
          return obj.id === event.data._id;
        });

        // console.log(order);

        if (order !== undefined) {
          // First, load appropriate orders data
          if (this.accountUser.isAdmin) {
            this.getOrderDetailsByIdUsernameParam({ orderId: order.id, username: this.selectedUsername }).then(response => {
              // Process results
              const orderItems = response[0].items;
              order.orderItems = orderItems;

              this.createInvoice(order);
            });
          } else {
            this.getOrderDetailsById(order.id).then(response => {
              // Process results
              const orderItems = response[0].items;
              order.orderItems = orderItems;

              this.createInvoice(order);
            });
          }
        }
      }
    },
    onModelUpdated (event) {
      // if (this.autosizeColumns) {
      //    setTimeout(() => {
      //     this.autosizeColumns("model updated");
      //   }, 100);
      // }
      this.autoSizeColumns('model updated');
    },
    onViewOrderDetails () {
    },
    loadOrderData () {
      if (this.accountUser.isAdmin && this.selectedUsername !== null && this.selectedUsername !== '') {
        this.getAllOrdersByUsernameParam(this.selectedUsername);
      } else {
        this.refreshOrders();
      }
    },
    initializeOrdersData () {
      this.selectedUsername = this.accountUser.username;
      this.refreshData();
    },
    getFilteredUsers () {

    },
    resetUsersFilters () {
      this.searchUsersFilter = '';
      this.selectedUsername = null;

      this.clearOrders();
      // this.selectedUsername = this.accountUser.username;
    },
    refreshData () {
      this.loadingOverlay(true);

      if (this.searchFilter === null || this.searchFilter === '') {
        this.rowData = [...this.ordersData];
      } else {
        this.filterByValue(this.ordersData, this.searchFilter);
        this.rowData = this.filterByValue(this.ordersData, this.searchFilter);
      }

      if (this.gridApi) {
        // SAVE EXISTING FILTER MODEL
        this.filterModel = this.gridApi.getFilterModel();

        // RESTORE / APPLY FILTER MODEL
        if (Object.keys(this.filterModel).length !== 0) {
          setTimeout(() => {
            this.gridApi.setFilterModel(this.filterModel);
            this.gridApi.onFilterChanged();

            // SORT BY DATE DESC
            const sortModel = [
              { colId: 'createdDate', sort: 'desc' }
            ];
            if (this.columnApi) {
              this.columnApi.applyColumnState(sortModel);
            }

            setTimeout(() => {
              this.autosizeColumns('refreshing data, reapplying filter');
            }, 100);
          }, 100);
        } else {
          // Resize columns
          setTimeout(() => {
            // SORT BY DATE DESC
            const sortModel = [
              { colId: 'createdDate', sort: 'desc' }
            ];
            if (this.columnApi) {
              this.columnApi.applyColumnState(sortModel);
            }

            // setTimeout(() => {
            //   this.autosizeColumns("refreshing data, no filter");
            // }, 100);
          }, 100);
        }
      }

      setTimeout(() => {
        if (router.currentRoute.path === '/orders' || router.currentRoute.path === '/orders#') {
          this.loadingOverlay(false);
        }
      }, 100);
    },
    filterByValue (array, string) {
      // Search ID for value
      return array.filter((o) => {
        // Get right half of id to search
        const truncatedId = o._id.substring(12, 24).toLowerCase();

        // Format search string to lowercase without dashes
        const searchString = this.searchFilter.toLowerCase().replace(/-/g, '');

        return truncatedId.includes(searchString);
      });
    },
    onCloseInvoiceDialogHandler () {
      this.invoiceDialog = false;
    },
    // createInvoice(invoice, path) {
    createInvoice (order) {
      const doc = new PDFDocument({ margin: 50 });

      // pipe the document to a blob
      const stream = doc.pipe(blobStream());

      this.generateHeader(doc, order);
      const currentY = this.generateCustomerInformation(doc, order);
      this.generateInvoiceTable(doc, currentY, order);
      this.generateFooter(doc);

      doc.end();

      stream.on('finish', () => {
        // get a blob you can do whatever you like with
        // const blob = stream.toBlob('application/pdf');

        // or get a blob URL for display in the browser
        const url = stream.toBlobURL('application/pdf');
        this.invoiceOrderId = this.orderIdFormatter(order.id);
        this.invoiceUrl = url;
        this.invoiceDialog = true;
      });
    },
    generateHeader (doc, order) {
      const leftMargin = 50;

      // GizmoTrader Info (Top Left)
      doc
        .fillColor('#000000')
        .font('Helvetica-Bold')
        .fontSize(13)
        .text('GizmoTrader Wholesale', leftMargin, 80);

      doc
        .font('Helvetica')
        .fontSize(11)
        .text('509 S. Chickasaw Trail', leftMargin, 96)
        .text('Ste # 346 ', leftMargin, 108)
        .text('Orlando, Florida 32825', leftMargin, 120)
        .text('+1-321-830-3841 ', leftMargin, 132)
        .text('wholesale@gizmotrader.com', leftMargin, 144);

      // Basic Order Info (Top Right)
      const leftMarginRightBlock = 368;
      doc
        .fillColor('#000000')
        .font('Helvetica')
        .fontSize(11)
        .text('Order ID: ', leftMarginRightBlock, 84)
        .text(this.orderIdFormatter(order.id), leftMarginRightBlock, 84, { align: 'right' })
        .text('Order Placed: ', leftMarginRightBlock, 96)
        .text(this.dateFormatter(order.createdDate), leftMarginRightBlock, 96, { align: 'right' })
        .font('Helvetica-Bold')
        .text('Order Total: ', leftMarginRightBlock, 108)
        .text(this.formatCurrency(order.total), leftMarginRightBlock, 108, { align: 'right' });

      // let paymentMethod = ((order.payment === "directpayment") || (order.payment === "wiretransfer")) ? "Direct Payment" : "PayPal"
      const paymentMethod = (order.payment.toLowerCase() === 'paypal') ? 'PayPal' : 'Direct Payment';
      doc
        .font('Helvetica')
        .text('Method of Payment: ', leftMarginRightBlock, 120)
        .text(paymentMethod, leftMarginRightBlock, 120, { align: 'right' });

      doc
        .text('Status: ', leftMarginRightBlock, 132)
        .text(order.fulfillmentStatus, leftMarginRightBlock, 132, { align: 'right' });

      doc
        .text('Tracking: ', leftMarginRightBlock, 144)
        .text(order.shiptracking, leftMarginRightBlock, 144, { align: 'right' });
    },
    generateFooter (doc) {
      doc
        .fontSize(10)
        .font('Helvetica-Bold')
        .text('Thank you for your purchase from Wholesale.GizmoTrader.com! ', 50, 700, { align: 'center', width: 500 })
        .font('Helvetica')
        .text('If you have any questions or concerns about this order please send us an email at wholesale@gizmotrader.com.', 50, 720, { align: 'center', width: 500 });
    },
    generateCustomerInformation (doc, order) {
      // SHIP TO INFO BLOCK
      doc
        .rect(50, 180, 514, 20) // x, y, width, height
        .fillOpacity(0.8)
        .fillAndStroke('lightgray', '#444444') // Box color / outline
        .stroke()
        .fillColor('#000000'); // Text color

      doc
        .font('Helvetica-Bold')
        .text('Shipping Address', 60, 186)
        .font('Helvetica')
        .fontSize(11);

      // Populate order shipping info
      let shippingY = 210;
      const shippingOffset = 12;

      if (order.payment === 'paypal') {
        // USE SHIPPING DATA!
        doc.text(order.sfullname, 60, shippingY);
        shippingY += shippingOffset;

        doc.text(order.saddress1, 60, shippingY);
        shippingY += shippingOffset;

        if (order.saddress2 !== undefined && order.saddress2 !== '') {
          doc.text(order.saddress2, 60, shippingY);
          shippingY += shippingOffset;
        }
        if (order.saddress3 !== undefined && order.saddress3 !== '') {
          doc.text(order.saddress3, 60, shippingY);
          shippingY += shippingOffset;
        }
        doc.text(order.scity + ', ' + order.sstate + ' ' + order.szip, 60, shippingY);
        shippingY += shippingOffset;
      } else {
        // USE BILLING DATA!
        if (order.bbusiness !== undefined && order.bbusiness !== '') {
          doc.text(order.bbusiness, 60, shippingY);
          shippingY += shippingOffset;
        }

        doc.text(order.bfullname, 60, shippingY);
        shippingY += shippingOffset;

        doc.text(order.baddress1, 60, shippingY);
        shippingY += shippingOffset;

        if (order.baddress2 !== undefined && order.baddress2 !== '') {
          doc.text(order.baddress2, 60, shippingY);
          shippingY += shippingOffset;
        }
        if (order.baddress3 !== undefined && order.baddress3 !== '') {
          doc.text(order.baddress3, 60, shippingY);
          shippingY += shippingOffset;
        }
        doc.text(order.bcity + ', ' + order.bstate + ' ' + order.bzip, 60, shippingY);
        shippingY += shippingOffset;
      }

      return shippingY;
    },
    generateTableRow (doc, y, c1, c2, c3, c4, c5, c6) {
      doc
        .fontSize(9)
        .text(c1, 60, y, { width: 210, align: 'left' }) // SKU
        .text(c2, 270, y, { width: 60, align: 'left' }) // Brand
        .text(c3, 330, y, { width: 80, align: 'left' }) // Model
        .text(c4, 410, y, { width: 50, align: 'center' }) // Quantity
        .text(c5, 460, y, { width: 40, align: 'right' }) // Price
        .text(c6, 500, y, { width: 60, align: 'right' }); // Total
    },
    generateInvoiceTable (doc, currentY, order) {
      const firstPageMax = 30; // Number of line items that can be on first page before wrapping to next page
      const subsequentPageMax = 40; // Number of line items that can be on subsequent pages before wrapping to next page

      const boxY = currentY + 12;
      const headingsY = boxY + 6;

      let position;
      // LINE ITEM TABLE HEADER
      doc
        .rect(50, boxY, 514, 20) // x, y, width, height
        .fillOpacity(0.8)
        .fillAndStroke('lightgray', '#444444') // Box color / outline
        .stroke()
        .fillColor('#000000'); // Text color

      doc
        .font('Helvetica-Bold')
        .text('SKU', 60, headingsY)
        .text('Brand', 270, headingsY)
        .text('Model', 330, headingsY)
        .text('Qty', 410, headingsY, { width: 50, align: 'center' })
        .text('Price', 460, headingsY, { width: 40, align: 'right' })
        .text('Total', 500, headingsY, { width: 60, align: 'right' })
        .font('Helvetica');

      // LINE ITEM TABLE ROWS
      let i;
      let invoiceTableTop = boxY + 16;

      let rowIndex = 0;
      for (i = 0; i < order.orderItems.length; i++) {
        const item = order.orderItems[i];
        // If we're on page one, limit to firstPageMax and continue on new page.
        // If we're AFTER page one, limit to subsequentPageMax and continue on new page
        if (i === (firstPageMax - 1) || rowIndex === subsequentPageMax) {
          doc.addPage();
          rowIndex = 0;
          invoiceTableTop = 60;
        }

        rowIndex++;
        position = invoiceTableTop + rowIndex * 14;

        this.generateTableRow(
          doc,
          position,
          item.sku,
          item.brand,
          item.storefrontmodel,
          item.quantity,
          this.formatCurrency(item.wholesaleprice),
          this.formatCurrency(item.total)
        );
      }

      // TODO: Fix this position when we are not on the first page
      // let subtotalPosition = invoiceTableTop + (order.orderItems.length + 1) * 14 + 2;
      let subtotalPosition = position + 16;
      doc.moveTo(50, subtotalPosition) // this is your starting position of the line
        .lineTo(562, subtotalPosition) // this is the end point the line
        .stroke();

      subtotalPosition += 9;

      doc
        .font('Helvetica-Bold')
        .fontSize(11)
        .text('Subtotal:', 400, subtotalPosition, { width: 80, align: 'right' })
        .text(this.formatCurrency(order.itemtotal), 480, subtotalPosition, { width: 80, align: 'right' })
        .font('Helvetica')

        .text('Discount:', 400, subtotalPosition + (1 * 15), { width: 80, align: 'right' })
        .text(this.discountCurrencyFormatter(order.discount), 480, subtotalPosition + (1 * 15), { width: 80, align: 'right' })

        .text('Store Credit Used:', 360, subtotalPosition + (2 * 15), { width: 120, align: 'right' })
        .text(this.discountCurrencyFormatter(order.storecreditused), 480, subtotalPosition + (2 * 15), { width: 80, align: 'right' })

        .text('Shipping:', 400, subtotalPosition + (3 * 15), { width: 80, align: 'right' })
        .text(this.formatCurrency(order.shippingtotal), 480, subtotalPosition + (3 * 15), { width: 80, align: 'right' })

        .text('Taxes:', 400, subtotalPosition + (4 * 15), { width: 80, align: 'right' })
        .text(this.formatCurrency(order.taxtotal), 480, subtotalPosition + (4 * 15), { width: 80, align: 'right' })

        .font('Helvetica-Bold')
        .text('Order Total:', 400, subtotalPosition + (5 * 15), { width: 80, align: 'right' })
        .text(this.formatCurrency(order.total), 480, subtotalPosition + (5 * 15), { width: 80, align: 'right' });
    },
    createTrackingLinkUrl (order) {
      return getTrackingUrl(order.shipcarrier, order.shiptracking);
    },
    onInvoiceClick (event) {
      // Lookup full order info from vuex store data
      const order = this.ordersData.find(obj => {
        return obj.id === event._id;
      });

      if (order !== undefined) {
        const generateInvoice = (response) => {
          // Process results
          const orderItems = response[0].items;
          order.orderItems = orderItems;

          this.createInvoice(order);
        };

        // First, load appropriate orders data
        if (this.accountUser.isAdmin) {
          this.getOrderDetailsByIdUsernameParam({ orderId: order.id, username: this.selectedUsername }).then(generateInvoice.bind(this));
        } else {
          this.getOrderDetailsById(order.id).then(generateInvoice.bind(this));
        }
      }
    },
    onOrderExpand (order) {
      const self = this;
      const orderItems = self.expandedOrders[order._id];

      if (orderItems) {
        delete self.expandedOrders[order._id];
        self.refreshData();
      } else {
        self.getOrderItems(order).then(itemRowData => {
          self.expandedOrders[order._id] = itemRowData;
          self.refreshData();
        });
      }
    },
    onProductExpand (product) {
      const index = this.expandedProducts.indexOf(product.sku);

      if (index === -1) {
        this.expandedProducts.push(product.sku);
      } else {
        this.expandedProducts.splice(index, 1);
      }

      this.refreshData();
    },
    isExpanded (id) {
      return this.expandedOrders[id] !== undefined || this.expandedProducts.includes(id);
    },
    getOrderItems (order) {
      const self = this;
      return new Promise((resolve, reject) => {
        const process = (response) => {
          // Process results
          order.orderItems = response[0].items;
          const productRowData = self.processOrderItems(order.orderItems);
          resolve(productRowData);
        };

        // First, load appropriate orders data
        if (this.accountUser.isAdmin) {
          self.getOrderDetailsByIdUsernameParam({ orderId: order.id, username: self.selectedUsername }).then(process.bind(self));
        } else {
          self.getOrderDetailsById(order.id).then(process.bind(self));
        }
      });
    },
    onErrorCarouselItem (event) {
      this.carouselItemErrors.push(event);
    },
    isMediaMissing (url) {
      return this.carouselItemErrors.includes(url);
    },
    carouselItems (product) {
      if (product !== null && 'sku' in product) {
        return this.mediaBySku[product.sku];
      }
      return [];
    }
  },
  watch: {
    ordersData (newValue, oldValue) {
      if (this.gridApi) {
        // Clear any previously applied column filters
        this.gridApi.setFilterModel(null);
      }

      this.refreshData();
    },
    searchFilter (newValue) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refreshData();
      }, 300);
    },
    selectedUsername (to, from) {
      if (to === undefined || to === null) {
        this.clearOrders();
      } else if (to !== undefined && to !== from) {
        // LOAD ORDERS FOR NEWLY SELECTED USER
        this.loadOrderData();
      }
    }
  },
  mixins: [
    formatterMixin
  ]
};

function mediaCellRenderer (params) {
  if (params !== undefined && params.value !== undefined) {
    return '<a class="v-btn v-btn--flat v-btn--small theme--light primary--text media-button" href="#"><i class="material-icons">photo_library</i></a>';
  }
  return '';
};

function functionalityCellRenderer (params) {
  if (params !== undefined && params.value !== undefined) {
    return '<a class="theme--light primary--text functionality-button" href="#">' + params.value + '</a>';
  }
  return '';
}

function trackingCellRenderer (params) {
  const trackingUrl = getTrackingUrl(params.data.shipcarrier, params.data.shiptracking);
  if (trackingUrl !== undefined && trackingUrl !== '') {
    return '<a class="v-btn v-btn--flat v-btn--small theme--light primary--text tracking-button" href="' + trackingUrl + '" target="_blank"><i class="material-icons">local_shipping</i></a>';
  }
  return params.data.shiptracking;
}

function invoiceCellRenderer (params) {
  if (params !== undefined && params.value !== undefined) {
    return '<a class="v-btn v-btn--flat v-btn--small theme--light primary--text invoice-button" href="#"><i class="material-icons">description</i></a>';
  }
  return '';
}

function getTrackingUrl (shipcarrier, shiptracking) {
  let formattedTrackingUrl = '';
  if (shipcarrier !== undefined && shipcarrier !== '' && shiptracking !== undefined && shiptracking !== '') {
    switch (shipcarrier.toLowerCase()) {
      case 'ups':
        formattedTrackingUrl = 'https://www.ups.com/track?loc=en_US&tracknum=' + shiptracking;
        break;
      case 'usps':
        formattedTrackingUrl = 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=' + shiptracking;
        break;
      case 'fedex':
        formattedTrackingUrl = 'https://www.fedex.com/fedextrack/?trknbr=' + shiptracking;
        break;
    }
  }
  return formattedTrackingUrl;
}

</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#orders-header {
  height: 50px;
}

#orders-table {
  height: calc(100vh - 250px);
}

#refresh-orders-btn {
  float: right;
}

.functionality-button {
  text-decoration: none;
}

.centered {
  text-align: center;
}

.invoice-button, .tracking-button {
  min-width: 0;
}

.media-button {
  min-width: 0;
}

.invoice-product-header {
  padding: 7px;

  position: relative;
  top: 12px;
}

.media-product-header {
  padding: 7px;
}

.media-product-price {
  margin-left: 20px;
}

.media-cart-button {
  margin-left: 30px;
}

.media-product-row {
  background-color: #BBDEFB !important;
}

.media-message {
  z-index: -1000 !important;
}

.col > .v-select {
  min-width: fit-content;
}

#orderCards {
  position: fixed;
  width: 100%;
  overflow: auto;
}

.cardsAdmin {
  top: 350px;
  height: 54%;
}

.cardsNonAdmin {
  top: 150px;
  height: 80%;
}
</style>
