import { settingsService } from '@/_services';

const state = {
  settings: []
};

const actions = {
  getAllSettings () {
    return state.settings;
  },
  refreshSettings ({ commit }) {
    settingsService.getAll()
      .then(
        settings => commit('setSettingsRequest', settings)
        // error => commit('getAllFailure', error)
      );
  },
  update ({ dispatch, commit }, settings) {
    dispatch('alert/loading', true, { root: true });
    settingsService.update(settings)
      .then(
        (settings) => {
          dispatch('alert/loading', false, { root: true });
          dispatch('alert/success', 'Settings successfully updated!', { root: true });
          dispatch('refreshSettings');
        }
        // error => commit('updateFailure', { id, error: error.toString() })
      );
  }
};

const mutations = {
  setSettingsRequest (state, settings) {
    state.settings = settings[0];
  },
  clearSettingsRequest (state) {
    state.settings = [];
  }
};

export const settings = {
  namespaced: true,
  state,
  actions,
  mutations
};
