import { countries } from 'countries-list';

export default {
  data () {
    return {
      countriesList: getCountriesList(),
      countriesMap: countries
    };
  }
};

function getCountriesList () {
  // sort countries alphabetically
  const sortedCountries = Object.keys(countries)
    .map(key => {
      return {
        value: key,
        text: countries[key].name
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text));

  // Put US at the front of the list and then add the sorted list
  const list = [];
  list.push({ value: 'US', text: 'United States' });
  return list.concat(sortedCountries);
}
