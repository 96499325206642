import config from 'config';
import { authHeader } from '@/_helpers';

export const productService = {
  getAll,
  getAllInStock,
  getAllAccessories,
  getAllAccessoriesInStock,
  getAllProductsAccessoriesInStock
};

function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/products/all`, requestOptions).then(handleResponse);
}

function getAllInStock () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/products`, requestOptions).then(handleResponse);
}

function getAllAccessories () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/products/accessories/all`, requestOptions).then(handleResponse);
}

function getAllAccessoriesInStock () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/products/accessories`, requestOptions).then(handleResponse);
}

function getAllProductsAccessoriesInStock () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/products/products-accessories-in-stock`, requestOptions).then(handleResponse);
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
