import config from 'config';
import { authHeader } from '@/_helpers';

export const resetPasswordService = {
  resetPasswordRequest,
  resetPassword
};

function resetPasswordRequest (body) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(`${config.apiUrl}/resetPassword/resetPasswordRequest`, requestOptions).then(handleResponse);
}

function resetPassword (body) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(`${config.apiUrl}/resetPassword/resetPassword`, requestOptions).then(handleResponse);
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
