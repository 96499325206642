<template>
    <span>
      {{ minutes }}:{{ seconds }}
    </span>
</template>

<script>
import { mapState } from 'vuex';
import formatterMixin from '@/_mixins/formatterMixin';
import { EventBus } from '@/_helpers';

export default {
  name: 'timer',
  props: {
    // initialTimeSeconds: Number
  },
  data () {
    return {
      timer: null,
      totalTime: 0,
      isTimerRunning: false
    };
  },
  components: {
  },
  beforeMount () {

  },
  computed: {
    ...mapState({
      checkoutOrder: state => state.orders.checkoutOrder,
      checkoutTimeSeconds: state => state.orders.checkoutTimeSeconds
    }),
    minutes: function () {
      const minutes = Math.floor(this.totalTime / 60);
      // return this.padTime(minutes);
      return minutes;
    },
    seconds: function () {
      const seconds = this.totalTime - (this.minutes * 60);
      return this.padTime(seconds);
    }
  },
  created () {

  },
  mounted () {
    this.totalTime = this.checkoutTimeSeconds;
    clearInterval(this.timer);
    this.isTimerRunning = true;
    this.timer = setInterval(() => this.countdown(), 1000);

    // Listen to the event.
    EventBus.$on('stop-checkout-timer', this.stopCheckoutTimerHandler);
  },
  updated () {

  },
  methods: {
    // ...mapActions({
    //   clearAlert: "alert/clear",
    //   successAlert: "alert/success",
    //   infoAlert: "alert/info",
    //   errorAlert: "alert/error",
    //   loadingOverlay: "alert/loading",
    //   lockScreenOverlay: "alert/lockScreen"
    // }),
    startTimer: function () {
      this.timer = setInterval(() => this.countdown(), 1000);
    },
    stopTimer: function () {
      clearInterval(this.timer);
      this.timer = null;
    },
    resetTimer: function () {
      this.totalTime = (25 * 60);
      clearInterval(this.timer);
      this.timer = null;
    },
    padTime: function (time) {
      return (time < 10 ? '0' : '') + time;
    },
    countdown: function () {
      if (this.isTimerRunning) {
        if (this.totalTime >= 1) {
          this.totalTime--;
        } else {
          this.totalTime = 0;
          this.stopTimer();
          this.emitGlobalCheckoutTimedOutEvent();
        }
      }
    },
    emitGlobalCheckoutTimedOutEvent () {
      // Send the event on a channel (checkout-timed-out) with a payload
      if (this.isTimerRunning) {
        EventBus.$emit('checkout-timed-out', {});
      }
    },
    stopCheckoutTimerHandler () {
      this.stopTimer();
    }
  },
  watch: {
    checkoutTimeSeconds (to, from) {
      if (to) {
        this.totalTime = to;
      }
    }
  },
  mixins: [
    formatterMixin
  ]
};

</script>

<style scoped>

</style>
