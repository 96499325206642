/**
*  Recursive function that will crawl the grid and set expanded on nodes that were
*  previously expanded
*
* Takes in a RowNode object and currentExpandedRows object
*/
export function crawlAndExpandRows (rowNode, currentExpandedRows) {
  // Make sure the rowNode is a group node and then check to see if we are tracking that key
  // in our expanded row object
  if (rowNode.group && 'key' in rowNode && currentExpandedRows[rowNode.key]) {
    // Note: not calling rowNode.setExpanded(true) because that will update the ui. Instead
    // we are doing a bulk update when the recursion is done
    rowNode.expanded = true;
  }

  // get children groups and recurse on each child. Each child is a rowNode object
  const childrenAfterGroup = rowNode.childrenAfterGroup;
  if (childrenAfterGroup && Array.isArray(childrenAfterGroup)) {
    childrenAfterGroup.forEach(childRowNode => crawlAndExpandRows(childRowNode, currentExpandedRows));
  }
};
