<template>
  <v-container fluid class="mt-16 mx-auto">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>GizmoTrader Wholesale</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <p class="text-h5 text--primary">
              Check your email!
            </p>
            <div class="text--primary confirmation-content">
              If the account info you entered is valid, we sent an email to the email address on file with instructions to reset your password.
            </div>
            <div class="mt-2" align="center">
              <router-link to="/login">Return to login</router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
export default {
  props: {
    source: String
  }
};
</script>
