const state = {
  display: false,
  loading: false,
  type: null,
  message: null,
  lockScreen: {
    enabled: false,
    type: 'info',
    message: 'Screen is locked!'
  }
};

const actions = {
  success ({ commit }, message) {
    commit('success', message);
  },
  error ({ commit }, message) {
    commit('error', message);
  },
  info ({ commit }, message) {
    commit('info', message);
  },
  loading ({ commit }, displayLoading) {
    commit('loading', displayLoading);
  },
  clear ({ commit }, message) {
    commit('clear', message);
  },
  lockScreen ({ commit }, payload) {
    commit('lockScreen', payload);
  }
};

const mutations = {
  success (state, message) {
    state.type = 'success';
    state.message = message;
    state.display = true;
  },
  error (state, message) {
    state.type = 'error';
    state.message = message;
    state.display = true;
  },
  info (state, message) {
    state.type = 'info';
    state.message = message;
    state.display = true;
  },
  loading (state, displayLoading) {
    state.loading = displayLoading;
  },
  clear (state) {
    state.display = false;
    state.type = null;
    state.message = null;
  },
  lockScreen (state, { displayLockScreen, type, message }) {
    state.lockScreen.enabled = displayLockScreen;
    state.lockScreen.type = type;
    state.lockScreen.message = message;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
