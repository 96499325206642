import { ordersService } from '@/_services';

const state = {
  orders: [],
  allOrders: {},
  checkoutOrder: null,
  confirmedOrder: null,
  initialized: false,
  checkoutTimeSeconds: 300, // Default: 300
  defaultCheckoutTimeSeconds: 300 // Default: 300
};

const actions = {
  refreshOrders ({ commit }) {
    ordersService.getAllByUsername()
      .then(
        orders => commit('setOrdersRequest', orders)
        // error => commit('getAllFailure', error)
      );
  },
  getAllOrders ({ commit }) {
    ordersService.getAll()
      .then(
        orders => commit('setAllOrders', orders)
      );
  },
  getOrderDetailsById ({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      ordersService.getById(orderId).then(response => {
        // http success, call the mutator and change something in state
        // commit('setOrderDetailsRequest', response);
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      });
    });
  },
  getOrderDetailsByIdUsernameParam ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ordersService.getByIdUsernameParam(payload.orderId, payload.username).then(response => {
        // http success, call the mutator and change something in state
        // commit('setOrderDetailsRequest', response);
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      });
    });
  },
  getAllOrdersByUsernameParam ({ commit }, username) {
    ordersService.getAllByUsernameParam(username)
      .then(
        orders => commit('setOrdersRequest', orders)
      // error => commit('getAllFailure', error)
      );
  },
  deleteAllPendingByUsername (_, usernames) {
    return new Promise((resolve, reject) => {
      ordersService.deleteAllPendingByUsername(usernames).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  },
  getAllOrdersByStatus ({ commit }, orderStatus) {
    return new Promise((resolve, reject) => {
      ordersService.getAllByStatus(orderStatus).then(response => {
        // http success, call the mutator and change something in state
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      });
    });
  },
  createOrder ({ commit }, orderParam) {
    ordersService.create(orderParam)
      .then(
        (order) => {
          commit('addOrderRequest', order);
        }
      );
  },
  createOrderDirect ({ commit }, orderParam) {
    return new Promise((resolve, reject) => {
      ordersService.createDirect(orderParam).then((response) => {
        commit('addOrderRequest', response);
        // http success, call the mutator and change something in state
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      });
    });
  },
  updateOrderStatus ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ordersService.updateStatus(payload.orderId, payload.orderStatus).then((response) => {
        commit('addOrderRequest', response.order);
        // http success, call the mutator and change something in state
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        reject(error);
      });
    });
  },
  createPayPalTransaction ({ commit }, payload) {
    return ordersService.createPayPalTransaction(payload.orderId, payload.useStoreCredit);
  },
  capturePayPalTransaction ({ commit }, payload) {
    return ordersService.capturePayPalTransaction(payload.orderId, payload.paypalOrderId);
  },
  loadVerifiedOrder ({ commit }, order) {
    commit('verifyOrderRequest', order);
  },
  verifyOrder ({ commit }, orderParam) {
    ordersService.verify(state.checkoutOrder.id, orderParam)
      .then(
        order => commit('verifyOrderRequest', order)
      );
  },
  verifyOrderDirect ({ commit }, orderParam) {
    ordersService.verifyDirect(state.checkoutOrder.id, orderParam)
      .then(
        order => commit('verifyOrderRequest', order)
      );
  },
  cancelCheckoutOrder ({ commit }, orderStatus) {
    return new Promise((resolve, reject) => {
      ordersService.cancelCheckoutOrder(state.checkoutOrder.id, orderStatus)
        .then(
          (response) => {
            if (state.checkoutOrder != null) {
              commit('removeOrderRequest', state.checkoutOrder.id);
            }
            commit('setCheckoutTimeRequest', state.defaultCheckoutTimeSeconds);
            resolve(response); // Let the calling function know that http is done. You may send some data back
          }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
          });
    });
  },
  cancelPendingCheckoutOrder ({ commit }) {
    return new Promise((resolve, reject) => {
      ordersService.cancelPendingCheckoutOrder(state.checkoutOrder.id)
        .then(
          (response) => {
            commit('removeOrderRequest', state.checkoutOrder.id);
            commit('setCheckoutTimeRequest', state.defaultCheckoutTimeSeconds);
            resolve(response); // Let the calling function know that http is done. You may send some data back
          }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
          });
    });
  },
  loadCheckoutOrder ({ commit }) {
    commit('clearInitialized');
    ordersService.getAllByStatus('pending-payment')
      .then(
      // SHOULD ONLY BE ONE ORDER IF ANY AT ALL
        (response) => {
          if (response.orders.length > 0) {
            const order = response.orders[0];
            const currentServerDate = new Date(response.current_timestamp);
            const checkoutOrderDate = new Date(order.createdDate);
            const difference = Math.abs((currentServerDate - checkoutOrderDate) / 1000);
            let updatedCheckoutTimeSeconds = (state.defaultCheckoutTimeSeconds - difference);
            if (updatedCheckoutTimeSeconds > 0) {
              updatedCheckoutTimeSeconds = (Math.round(updatedCheckoutTimeSeconds * 100) / 100).toFixed(0);
            } else {
              updatedCheckoutTimeSeconds = 0;
            }

            commit('setCheckoutTimeRequest', updatedCheckoutTimeSeconds);
            commit('setCheckoutOrderRequest', order);
            commit('setInitialized');
          } else {
            ordersService.getAllByStatus('pending-paypal-checkout')
              .then(
                // SHOULD ONLY BE ONE ORDER IF ANY AT ALL
                (response) => {
                  if (response.orders.length > 0) {
                    const order = response.orders[0];
                    // let currentServerDate = new Date(response.current_timestamp);
                    const checkoutOrderDate = new Date(order.createdDate);
                    const currentServerDate = new Date(order.updatedDate);
                    const difference = Math.abs((currentServerDate - checkoutOrderDate) / 1000);
                    let updatedCheckoutTimeSeconds = (state.defaultCheckoutTimeSeconds - difference);
                    if (updatedCheckoutTimeSeconds > 0) {
                      updatedCheckoutTimeSeconds = (Math.round(updatedCheckoutTimeSeconds * 100) / 100).toFixed(0);
                    } else {
                      updatedCheckoutTimeSeconds = 0;
                    }

                    commit('setCheckoutTimeRequest', updatedCheckoutTimeSeconds);
                    commit('setCheckoutOrderRequest', order);
                  } else {
                    // NO PENDING_PAYMENT || PENDING_PAYPAL_CHECKOUT ORDERS
                    commit('setCheckoutOrderRequest', null);
                  }
                  commit('setInitialized');
                }
              );
          }
        }
      );
  },
  clearCheckoutOrder ({ commit }) {
    commit('clearCheckoutOrder');
  },
  clearConfirmedOrder ({ commit }) {
    commit('clearConfirmedOrder');
  },
  clearOrders ({ commit }) {
    commit('clearOrdersRequest');
  }
};

const mutations = {
  addOrderRequest (state, order) {
    state.orders.push(order);
    state.checkoutOrder = order;
  },
  verifyOrderRequest (state, order) {
    // check if object already exists and update / push accordingly
    const index = state.orders.findIndex((e) => e.id === order.id);

    if (index === -1) {
      state.orders.push(order);
    } else {
      state.orders[index] = order;
    }

    state.checkoutOrder = null;
    state.confirmedOrder = order;
  },
  setOrdersRequest (state, orders) {
    // DEBUG SET ORDER INFO
    // orders = orders.map(obj=> ({ ...obj, shiptracking: '1ZW6897XYW00098770', shipcarrier: 'UPS' }))
    // orders = orders.map(obj=> ({ ...obj, baddress2: "STE 436", baddress3: "THE ORANGE UNIT", bbusiness: "ABC, INC" }))
    state.orders = orders;
  },
  setAllOrders (state, orders) {
    state.allOrders = orders;
  },
  setOrdersGroup (state, orders) {
    state.ordersGrouped = orders;
  },
  setCheckoutOrderRequest (state, order) {
    state.checkoutOrder = order;
  },
  setCheckoutTimeRequest (state, checkoutTimeSeconds) {
    state.checkoutTimeSeconds = checkoutTimeSeconds;
  },
  setOrderDetailsRequest (state, order) {
    // state.orders = orders;
  },
  removeOrderRequest (state, orderId) {
    state.orders.splice(state.orders.findIndex((item) => { return item.id === orderId; }), 1);
  },
  setInitialized (state) {
    state.initialized = true;
  },
  clearInitialized (state) {
    state.initialized = false;
  },
  clearCheckoutOrder (state) {
    state.checkoutOrder = null;
  },
  clearConfirmedOrder (state) {
    state.confirmedOrder = null;
  },
  clearOrdersRequest (state) {
    state.orders = [];
  }
};

export const orders = {
  namespaced: true,
  state,
  actions,
  mutations
};
