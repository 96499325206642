<template>
  <v-container fluid class="mt-16 mx-auto">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>GizmoTrader Wholesale Forgot Password</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <span>Enter your username or email address</span>
            <v-text-field
                name="username"
                label="Username/Email"
                type="text"
                v-model="usernameEmail"
            ></v-text-field>
            <div>
              <v-btn style="width:100%" class="ma-0" color="primary" @click="resetPassword">Send Password Reset Instructions</v-btn>
            </div>
            <div class="mt-2" align="center">
              <router-link to="/login">Return to login</router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapActions } from 'vuex';
import { resetPasswordService } from '@/_services';
import { router } from '@/router';

export default {
  props: {
    source: String
  },
  data () {
    return {
      usernameEmail: ''
    };
  },
  computed: {
  },
  created () {
    // reset login status
    this.clearAlert();
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    resetPassword () {
      if (this.usernameEmail === '') {
        return;
      }

      const body = {
        usernameEmail: this.usernameEmail
      };

      resetPasswordService.resetPasswordRequest(body)
        .then(res => {
          router.push('/password-request-sent');
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>
