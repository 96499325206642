<template>
  <v-container fluid>
    <v-row align-top justify-left>
      <v-col cols="12" md="5">

        <v-form
          @keyup.native.enter="handleSubmit()"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Global Settings</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                  name="cartMaxLimit"
                  label="Shopping Cart Max Limit"
                  type="text"
                  v-model="settingsData.cartMaxLimit"
                  :rules="rules.cartMaxLimit"
                  required
              ></v-text-field>
              <v-text-field
                  name="directPaymentMaxLimit"
                  label="Direct Payment Max Limit"
                  type="text"
                  v-model="settingsData.directPaymentMaxLimit"
                  :rules="rules.directPaymentMaxLimit"
                  required
              ></v-text-field>
              <v-text-field
                  name="minimumOrderQtyThreshold"
                  label="Minimum Order Quantity Threshold"
                  type="text"
                  v-model="settingsData.minimumOrderQtyThreshold"
                  :rules="rules.minimumOrderQtyThreshold"
                  required
              ></v-text-field>
              <v-text-field
                  name="minimumOrderQtyShppingFee"
                  label="Minimum Order Quantity Shipping Fee"
                  type="text"
                  v-model="settingsData.minimumOrderQtyShppingFee"
                  :rules="rules.minimumOrderQtyShppingFee"
                  required
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="resetForm()">Reset <span class="d-none d-md-block">Form</span></v-btn>
                <v-btn @click="clearErrors()">Clear <span class="d-none d-md-block">Errors</span></v-btn>
                <v-btn color="primary" @click="handleSubmit()">Save <span class="d-none d-md-block">Settings</span></v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'settings',
  components: {
  },
  data () {
    return {
      valid: true,
      // DEFAULT VALUES:
      settingsData: {
        cartMaxLimit: 0,
        directPaymentMaxLimit: 0,
        minimumOrderQtyThreshold: 0,
        minimumOrderQtyShppingFee: 0
      },
      rules: {
        cartMaxLimit: [
          v => !!v || 'Direct Payment Max Limit is required. Example: 10000.00'
        ],
        directPaymentMaxLimit: [
          v => !!v || 'Direct Payment Max Limit is required. Example: 10000.00'
        ],
        minimumOrderQtyThreshold: [
          v => !!v || 'Minimum Order Quantity Threshold is required. Example: 300.00'
        ],
        minimumOrderQtyShppingFee: [
          v => !!v || 'Minimum Order Quantity Shipping Fee is required. Example: 10.00'
        ]
      },
      submitted: false
    };
  },
  created () {

  },
  mounted () {
    this.refreshSettings();
  },
  computed: {
    ...mapState('account', ['status']),
    ...mapState({
      settings: state => state.settings.settings
    })
  },
  methods: {
    // ...mapActions("account", ["register"]),
    // ...mapActions("users", ["update", "delete", "loadUsers"]),
    ...mapActions('settings', ['update', 'refreshSettings']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    resetForm () {
      this.resetValidation();
      // this.$refs.form.reset();
      this.loadSettingsData();
    },
    clearErrors () {
      this.clearAlert();
      this.resetValidation();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    loadSettingsData () {
      this.settingsData = { ...this.settings };
    },
    handleSubmit (e) {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          // alert("Submitting Settings!");
          this.update(this.settingsData);
        }
      });
    }

  },
  watch: {
    settings (newValue, oldValue) {
      this.loadSettingsData();
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

</style>
