<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog.displayed"
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>

        <v-card-text>
          {{ dialog.text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="onDialogClickHandler(false)"
          >
            No
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="onDialogClickHandler(true)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" class="pa-4">
        <v-form @keyup.native.enter="handleSubmit()">

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Users</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col class="py-0">
                  <span>Filters</span>
                </v-col>
              </v-row>
              <v-row row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                      name="searchFilter"
                      label="Search Users"
                      type="text"
                      v-model="searchFilter"
                      :disabled="selectedUsername !== null"
                      outlined
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col md="1" class="py-0"></v-col>
                <v-col cols="12" md="5" class="py-0">
                  <v-select
                    style="min-width: 100%;"
                    name="accountStatusFilter"
                    label="Account Status"
                    id="accountStatusFilter"
                    :items="availableStatuses"
                    v-model="accountStatusFilter"
                    :disabled="selectedUsername !== null"
                    outlined
                    clearable
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <span>Select User</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="9"  class="py-0">
                  <v-select
                    style="min-width: 100%;"
                    :items="filteredUsernames"
                    filled
                    :label=filteredUsernamesLabel
                    v-model="selectedUsername"
                    clearable
                  ></v-select>
                </v-col>
                <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
                <v-col cols="12" md="1"  class="py-0">
                  <v-btn dark large color="pink" @click="loadUserData">RESET</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>User Information</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                        class="mr-3"
                        name="firstName"
                        label="First Name"
                        type="text"
                        v-model="user.firstName"
                        :disabled="selectedUsername === null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                        class="ml-3"
                        name="company"
                        label="Company"
                        type="text"
                        v-model="user.company"
                        :disabled="selectedUsername === null"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                        class="mr-3"
                        name="lastName"
                        label="Last Name"
                        type="text"
                        v-model="user.lastName"
                        :disabled="selectedUsername === null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                        class="ml-3"
                        name="phone"
                        label="Phone"
                        type="text"
                        v-model="user.phone"
                        :disabled="selectedUsername === null"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                        class="mr-3"
                        name="email"
                        label="Email"
                        type="text"
                        v-model="user.email"
                        :disabled="selectedUsername === null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-text-field
                        class="ml-3"
                        name="whatsAppNumber"
                        label="WhatsApp Number"
                        type="text"
                        v-model="user.whatsAppNumber"
                        :disabled="selectedUsername === null"
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-card-text>
          </v-card>

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Address Information</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="6" class="pr-3" style="border-right: 1px solid black">
                  <h2 style="text-align: center">Business</h2>
                  <v-text-field
                      name="address1"
                      label="Address 1"
                      type="text"
                      v-model="user.address1"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="address2"
                      label="Address 2"
                      type="text"
                      v-model="user.address2"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="address3"
                      label="Address 3"
                      type="text"
                      v-model="user.address3"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="city"
                      label="City"
                      type="text"
                      v-model="user.city"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="state"
                      label="State"
                      type="text"
                      v-model="user.state"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="zip"
                      label="Zip"
                      type="text"
                      v-model="user.zip"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-autocomplete
                      name="country"
                      id="businessCountry"
                      label="Country"
                      v-model="user.country"
                      required
                      :disabled="selectedUsername === null"
                      :items="countriesList"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="pl-3" style="border-left: 1px solid black">
                  <h2 style="text-align: center">Shipping</h2>
                  <v-text-field
                      name="shippingAddress1"
                      label="Address 1"
                      type="text"
                      v-model="user.shippingAddress1"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="shippingAddress2"
                      label="Address 2"
                      type="text"
                      v-model="user.shippingAddress2"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="shippingAddress3"
                      label="Address 3"
                      type="text"
                      v-model="user.shippingAddress3"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="shippingCity"
                      label="City"
                      type="text"
                      v-model="user.shippingCity"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="shippingState"
                      label="State"
                      type="text"
                      v-model="user.shippingState"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-text-field
                      name="shippingZip"
                      label="Zip"
                      type="text"
                      v-model="user.shippingZip"
                      :disabled="selectedUsername === null"
                  ></v-text-field>
                  <v-autocomplete
                      name="shippingCountry"
                      id="shippingCountry"
                      label="Country"
                      v-model="user.shippingCountry"
                      :disabled="selectedUsername === null"
                      required
                      :items="countriesList"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Wholesale Account Settings</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="4">

                  <v-checkbox
                    v-model="user.taxExempt"
                    label="Tax Exempt"
                    :disabled="selectedUsername === null"
                  ></v-checkbox>
                  <v-checkbox
                    color="success"
                    v-model="user.directPaymentEnabled"
                    :disabled="selectedUsername === null"
                  >
                    <template v-slot:label>
                      <div v-if="selectedUsername !== null"><strong class="success--text">Direct Payment Enabled</strong></div>
                      <div v-else>Direct Payment Enabled</div>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    color="success"
                    v-model="user.payPalEnabled"
                    :disabled="selectedUsername === null"
                  >
                    <template v-slot:label>
                      <div v-if="selectedUsername !== null"><strong class="success--text">PayPal Payment Enabled</strong></div>
                      <div v-else>PayPal Payment Enabled</div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    v-model="user.accountEnabled"
                    label="Account Enabled"
                    :disabled="selectedUsername === null"
                  ></v-checkbox>
                  <v-checkbox
                    color="error"
                    v-model="user.isAdmin"
                    :disabled="selectedUsername === null"
                  >
                    <template v-slot:label>
                      <div v-if="selectedUsername !== null"><strong class="error--text">Wholesale Site Administrator</strong></div>
                      <div v-else>Wholesale Site Administrator</div>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-select
                    name="accountStatus"
                    label="Account Status"
                    id="accountStatus"
                    :items="availableStatuses"
                    v-model="user.accountStatus"
                    :disabled="selectedUsername === null"
                  ></v-select>
                  <v-text-field
                    name="taxRate"
                    label="Tax Rate %"
                    type="text"
                    v-model="user.taxRate"
                    :disabled="isTaxRateDisabled"
                  ></v-text-field>
                  <p class="text-h5">Store Credit Balance: {{ this.formattedCredit }}</p>
                </v-col>
              </v-row>

              <v-text-field
                  name="password"
                  label="Set New Password"
                  id="password"
                  type="password"
                  v-model="user.password"
                  :disabled="selectedUsername === null"
              ></v-text-field>
              <v-text-field
                  name="confirmPassword"
                  label="Confirm New Password"
                  id="confirmPassword"
                  type="password"
                  v-model="user.confirmPassword"
                  :disabled="selectedUsername === null"
              ></v-text-field>

            </v-card-text>

          </v-card>

          <v-card class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Override Global Settings</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                    name="cartMaxLimit"
                    label="Shopping Cart Max Limit"
                    type="text"
                    v-model="user.overrideSettings.cartMaxLimit"
                    :disabled="selectedUsername === null"
                ></v-text-field>
                <v-text-field
                    name="directPaymentMaxLimit"
                    label="Direct Payment Max Limit"
                    type="text"
                    v-model="user.overrideSettings.directPaymentMaxLimit"
                    :disabled="selectedUsername === null"
                ></v-text-field>
                <v-text-field
                    name="minimumOrderQtyThreshold"
                    label="Minimum Order Quantity Threshold"
                    type="text"
                    v-model="user.overrideSettings.minimumOrderQtyThreshold"
                    :disabled="selectedUsername === null"
                ></v-text-field>
                <v-text-field
                    name="minimumOrderQtyShppingFee"
                    label="Minimum Order Quantity Shipping Fee"
                    type="text"
                    v-model="user.overrideSettings.minimumOrderQtyShppingFee"
                    :disabled="selectedUsername === null"
                ></v-text-field>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-text>
              <v-card-actions>
                <v-btn color="error" @click="deleteUser()" :disabled="selectedUsername === null">Delete User</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="clearUser()" :disabled="selectedUsername === null">Cancel</v-btn>
                <v-btn color="primary" @click="handleSubmit()" :disabled="selectedUsername === null">Save User</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import countriesMixin from '@/_mixins/countriesMixin';
import formatterMixin from '@/_mixins/formatterMixin';

export default {
  name: 'users',
  components: {
  },
  mixins: [
    countriesMixin,
    formatterMixin
  ],
  data () {
    return {
      searchFilter: '',
      accountStatusFilter: '',
      filteredUsernames: [],
      user: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        isAdmin: false,
        taxExempt: true,
        taxRate: '0.0',
        directPaymentEnabled: false,
        payPalEnabled: false,
        overrideSettings: {
          directPaymentMaxLimit: null,
          cartMaxLimit: null,
          minimumOrderQtyThreshold: null,
          minimumOrderQtyShppingFee: null
        },
        password: '',
        confirmPassword: ''
      },
      selectedUsername: null,
      submitted: false,
      timer: null,
      dialog: {
        displayed: false,
        title: '',
        text: ''
      }
    };
  },
  created () {

  },
  mounted () {
    this.loadUserData();

    // Workaround for a bug in vuetify. Normally vuetify puts autocomplete: off on v-autocomplete
    // components but chrome changed the word to "nope" so this is just setting that attribute
    document.getElementById('businessCountry').setAttribute('autocomplete', 'nope');
    document.getElementById('shippingCountry').setAttribute('autocomplete', 'nope');

    this.selectedUsername = this.$route.params.username || null;
  },
  computed: {
    ...mapState('account', ['status']),
    ...mapState('users', ['usernames', 'users']),
    ...mapGetters('users', ['availableStatuses']),
    ...mapState('credits', ['balancesByUsername']),
    administratorLabel () {
      return "<strong class='success--text'>Administrator</strong>";
    },
    filteredUsernamesLabel () {
      if ((this.searchFilter === null || this.searchFilter === undefined || this.searchFilter === '') && (this.accountStatusFilter === null || this.accountStatusFilter === undefined || this.accountStatusFilter === '')) {
        return 'All Users';
      }

      if (this.filteredUsernames.length === 1) {
        return 'Filters Applied: 1 Matching User';
      }
      return 'Filters Applied: ' + this.filteredUsernames.length + ' Matching Users';
    },
    isTaxRateDisabled () {
      return this.selectedUsername === null || this.user.taxExempt === true;
    },
    isDirectPaymentEnabled () {
      return this.selectedUsername !== null && this.user.directPaymentEnabled === true;
    },
    isPayPalEnabled () {
      return this.selectedUsername !== null && this.user.payPalEnabled === true;
    },
    storeCredit () {
      const username = this.user.username;
      return this.balancesByUsername[username]?.balance || 0;
    },
    formattedCredit () {
      return this.formatCurrency(this.storeCredit);
    }
  },
  methods: {
    ...mapActions('account', ['register']),
    ...mapActions('users', ['update', 'delete', 'loadUsers']),
    ...mapActions('credits', ['getCreditByUsername']),
    onDialogClickHandler (confirm) {
      this.dialog.displayed = false;
      if (confirm) {
        this.delete(this.user.id).then(() => {
          this.clearUser();
        });
      }
    },
    deleteUser () {
      if (this.selectedUsername !== null) {
        this.dialog.title = 'Delete User?';
        this.dialog.text = "Are you sure you want to delete the user '" + this.selectedUsername + "'?";
        this.dialog.displayed = true;
      }
    },
    clearUser () {
      this.selectedUsername = null;
    },
    clearFilters () {
      this.searchFilter = '';
      this.accountStatusFilter = '';
    },
    loadUserData () {
      // alert("Fetching User List from Server...");
      this.clearFilters();
      this.clearUser();
      this.loadUsers();
    },
    getFilteredUsers () {
      // Start with array of all users and apply search filters one at a time
      let filteredUsers = Object.values(this.users);

      // APPLY ACCOUNT STATUS FILTER IF SET
      if (this.accountStatusFilter !== undefined && this.accountStatusFilter !== null && this.accountStatusFilter !== '') {
        filteredUsers = this.filterByAccountStatus(filteredUsers, this.accountStatusFilter);
      }

      // APPLY SEARCH FILTER IF SET
      if (this.searchFilter !== undefined && this.searchFilter !== null && this.searchFilter !== '') {
        // SEARCH FILTER: RETURN FILTERED RESULTS
        const delimiter = '|';

        // Split searchFilter on delimiter
        const splits = this.searchFilter.split(delimiter);

        for (const split in splits) {
          // Trim searchFilterItem
          const searchFilterItem = splits[split].trim();

          if (searchFilterItem !== '') {
            filteredUsers = this.filterByValue(filteredUsers, searchFilterItem);
          }
        }
      }

      return filteredUsers.map(o => o.username);
    },
    refreshData () {
      // GET A SORTED LIST OF USERNAMES THAT MEET CURRENT FILTER CRITERIA
      this.filteredUsernames = this.getFilteredUsers().sort();
    },
    handleSubmit (e) {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.update(this.user);
        }
      });
    },
    filterByAccountStatus (array, accountStatus) {
      // Search accountStatus property for value
      return array.filter(o => o.accountStatus.toLowerCase() === accountStatus.toLowerCase());
    },
    filterByValue (array, string) {
      // Search all properties for value
      return array.filter(o =>
        Object.keys(o).some(
          (k) => {
            if (typeof o[k] === 'string' || o[k] instanceof String) {
              return o[k].toLowerCase().includes(string.toLowerCase());
            }
          }
        ));
    }
  },
  watch: {
    users (to, from) {
      this.refreshData();
    },
    searchFilter (newValue) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.refreshData();
      }, 300);
    },
    accountStatusFilter (newValue) {
      this.refreshData();
    },
    selectedUsername (to, from) {
      if (to === null) {
        this.user = {
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          address1: '',
          address2: '',
          address3: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
          isAdmin: false,
          taxExempt: true,
          taxRate: '0.0',
          directPaymentEnabled: false,
          payPalEnabled: false,
          overrideSettings: {
            directPaymentMaxLimit: null,
            cartMaxLimit: null,
            minimumOrderQtyThreshold: null,
            minimumOrderQtyShppingFee: null
          },
          password: '',
          confirmPassword: ''
        };
      } else {
        this.user = this.users[to];
        this.getCreditByUsername(this.user.username);
      }
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#refresh-users-btn {
  margin-left: 20px;
}

.v-card__text, .v-card__title {
  word-break: normal !important;
}

</style>
