// /plugins/socket.js
import io from '@/_resources/js/socket.io.min';
let webSocket = null;

export default {
  // eslint-disable-next-line no-unused-vars
  install (Vue, options) {
    Vue.prototype.$openSocket = (username) => {
      // console.log('IN SOCKET PLUGIN: OPEN SOCKET');
      webSocket = io(process.env.VUE_APP_WS_SOCKET_URL);
      webSocket.on('connect', () => {
        webSocket.emit('join', { username: username, socketId: webSocket.id }, (response) => {
          // console.log(response);
        });
      });
    };

    Vue.prototype.$closeSocket = () => {
      if (webSocket !== null) {
        webSocket.disconnect();
      }
    };

    // Vue.prototype.$socket = webSocket;
    Vue.prototype.$getSocket = () => {
      return webSocket;
    };
  }
};
