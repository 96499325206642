<template>
  <v-container fluid class="mt-16 mx-auto">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>GizmoTrader Wholesale</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form @keyup.native.enter="handleSubmit()">
              <v-text-field
                prepend-icon="person"
                name="login"
                label="Username/Email"
                type="text"
                v-model="usernameEmail"
              ></v-text-field>
              <v-text-field
                prepend-icon="lock"
                name="password"
                label="Password"
                id="password"
                type="password"
                v-model="password"
              ></v-text-field>
            </v-form>
            <div>
              <v-btn style="width:100%" class="ma-0" color="primary" @click="handleSubmit()">Login</v-btn>
            </div>
            <div class="mt-4" align="center">
              <router-link to="/forgot-password">Forgot Password?</router-link>
            </div>
            <div class="mt-2" align="center">
              <span>Don't have a GizmoTrader Wholesale Account? </span>
              <router-link to="/apply">Apply Now</router-link>
            </div>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    source: String
  },
  data () {
    return {
      usernameEmail: '',
      password: '',
      submitted: false
    };
  },
  computed: {
    ...mapState('account', ['status'])
  },
  created () {
    // reset login status
    this.clearAlert();
    this.logout();
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    handleSubmit () {
      this.submitted = true;
      const { usernameEmail, password } = this;
      if (usernameEmail && password) {
        this.login({ usernameEmail, password });
      }
    }
  }
};
</script>
