<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <div id="credits-table">
        <ag-grid-vue
          style="width: 100%; height: 100%;"
          class="ag-theme-material"
          id="ag-grid-credit"
          :rowData="rowData"
          @grid-ready="onGridReady"
          @first-data-rendered="onFirstDataRendered"
          @row-group-opened="onRowGroupOpened"
          :gridOptions="gridOptions"
          rowSelection="multiple"
          groupSelectsChildren="true"
          animateRows="true"
          suppressRowClickSelection="true"
        ></ag-grid-vue>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import { router } from '@/router';
import formatterMixin from '@/_mixins/formatterMixin';

export default {
  name: 'creditstable',
  components: {
    AgGridVue
  },
  props: {
    source: String
  },
  mixins: [
    formatterMixin
  ],
  data () {
    return {
      gridColumnApi: null,
      columnFields: null,
      window: {
        width: 0,
        height: 0
      }
    };
  },
  created () {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize);
  },
  activated () {
    setTimeout(() => {
      this.autosizeColumns();
    }, 10);
  },
  beforeMount () {
    this.columnFields = this.gridOptions.columnDefs.map(columnDef => columnDef.field);
    this.columnFields.push('ag-Grid-AutoColumn');
  },
  mounted () {
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  computed: {
    ...mapState('credits', ['balancesByUsername', 'allCredits']),
    ...mapState('account', ['user']),
    rowData () {
      return this.allCredits;
    },
    gridOptions () {
      return {
        rememberGroupStateWhenNewData: true,
        suppressScrollOnNewData: true,
        columnDefs: [
          {
            headerName: 'Date',
            field: 'createdDate',
            sortable: false,
            sort: 'desc',
            filter: true,
            width: 240,
            valueFormatter: this.dateTimeFormatter
          },
          {
            headerName: 'Username',
            field: 'username',
            sortable: false,
            filter: true,
            rowGroup: this.user.isAdmin
          },
          {
            headerName: 'Action',
            field: 'action',
            sortable: false,
            filter: true
          },
          {
            headerName: 'Amount',
            field: 'amount',
            sortable: false,
            filter: true,
            cellStyle: this.storeCreditCellStyle,
            cellRenderer: this.storeCreditCellRenderer
          },
          {
            headerName: 'Balance',
            field: 'balance',
            sortable: false,
            filter: false,
            cellRenderer: (cell) => {
              let value;
              if (cell.node.group) {
                const username = cell.node.key;
                value = this.balancesByUsername[username]?.balance || 0;
              } else {
                value = cell.data.balance;
              }

              return value < 0 ? `-${this.formatCurrency(value * -1)}` : this.formatCurrency(value);
            },
            cellStyle: (cell) => {
              if (cell.node.group) {
                const username = cell.node.key;
                const balance = this.balancesByUsername[username]?.balance || 0;
                return this.creditBalanceCellStyle(balance);
              } else {
                return null;
              }
            }
          },
          {
            headerName: 'Details',
            field: 'details',
            wrapText: true,
            autoHeight: true,
            maxWidth: 500,
            sortable: false,
            filter: true
          },
          {
            headerName: 'Order ID',
            field: 'orderId',
            sortable: false,
            filter: true
          }
        ]
      };
    }
  },
  methods: {
    handleResize () {
      if (router.currentRoute.path === '/credit' || router.currentRoute.path === '' || router.currentRoute.path === '/') {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;

        setTimeout(() => {
          this.autosizeColumns();
        }, 100);
      }
    },
    onFirstDataRendered (params) {
      setTimeout(() => {
        this.autosizeColumns();
      }, 10);
    },
    onGridReady (params) {
      setTimeout(() => {
        this.autosizeColumns();
      }, 10);
    },
    autosizeColumns () {
      if (router.currentRoute.path === '/credit' || router.currentRoute.path === '' || router.currentRoute.path === '/') {
        this.autoSizeAll(false);
        if (this.window.width === 0) {
          this.window.width = window.innerWidth;
        }
        if (this.window.height === 0) {
          this.window.height = window.innerHeight;
        }
      }
    },
    autoSizeAll (skipHeader) {
      if (this.gridColumnApi) {
        this.gridColumnApi.autoSizeColumns(this.columnFields, skipHeader);
      }
    },
    onRowGroupOpened (params) {
      setTimeout(() => {
        this.autosizeColumns();
      }, 10);
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

#credits-table {
  margin-top: 10px;
  height: 100%;
  height: calc(100vh - 250px);
}

#ag-grid-credit .ag-cell[col-id="details"] {
  --ag-line-height: 20px;
}

</style>
