<template>
  <v-navigation-drawer clipped fixed v-model="drawer" app width="180" :temporary="$vuetify.breakpoint.mobile" :permanent="!$vuetify.breakpoint.mobile">
    <v-list dense>
      <v-list-item v-for="item in menuItems" :key="item.title" :to="item.path">
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
        <a href="#" id="cart-total"><v-chip v-if="item.icon === 'shopping_cart' && cartProductsCount > 0" color="pink" text-color="white" small>{{ formattedCartProductsCount }}</v-chip></a>
        <a href="#" id="account-total"><v-chip v-if="item.icon === 'supervisor_account' && pendingApprovalCount > 0" color="pink" text-color="white" small>{{ formattedPendingApprovalCount }}</v-chip></a>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import formatterMixin from '@/_mixins/formatterMixin';
import { EventBus } from '@/_helpers';

export default {
  data () {
    return {
      drawer: null,
      refreshCart: false
    };
  },
  created () {
    this.drawer = true;
  },
  mounted () {
    // Listen to the event.
    EventBus.$on('toggle-menu', this.toggleMenu);
  },
  methods: {
    toggleMenu () {
      this.drawer = !this.drawer;
    }
  },
  computed: {
    ...mapState({
      cartProductsCount: state => state.cart.count,
      pendingApprovalCount: state => state.users.pendingApprovalCount,
      account: state => state.account.user
    }),
    ...mapGetters('account', ['menuItems']),
    formattedPendingApprovalCount () {
      return this.numberWithCommas(this.pendingApprovalCount);
    },
    formattedCartProductsCount () {
      return this.numberWithCommas(this.cartProductsCount);
    }
  },
  mixins: [
    formatterMixin
  ]
};
</script>

<style>

#cart-total, #account-total {
  text-decoration-color: #E91E63;
}

#cart-total span:hover {
  cursor: pointer;
}

#account-total span:hover {
  cursor: pointer;
}

</style>
