import config from 'config';
import { authHeader } from '@/_helpers';

export const creditsService = {
  getCreditBalanceByUserName,
  createCredit,
  getBalances,
  getAllCredits
};

function getCreditBalanceByUserName (username) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/credits/${username}/latest`, requestOptions).then(handleResponse);
}

function createCredit (credit) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(credit)
  };

  return fetch(`${config.apiUrl}/credits`, requestOptions).then(handleResponse);
}

function getBalances () {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/credits/balances`, requestOptions).then(handleResponse);
}

function getAllCredits () {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(`${config.apiUrl}/credits`, requestOptions).then(handleResponse);
}

function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
