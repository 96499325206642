import { userService } from '@/_services';

const state = {
  users: {},
  usersById: {},
  usernames: [],
  pendingApprovalCount: 0,

  // if the users account is enabled, we resort to the status to determine their permissions
  approvedStatuses: ['APPROVED', 'SERVICE_ACCOUNT'], // login no issues
  pendingStatuses: ['PENDING_APPROVAL'], // cant login, show notification for admins
  reviewStatuses: ['REVIEW_CERT', 'REVIEW_RSLR', 'REVIEW_INTL', 'REVIEW_FFWD', 'REVIEW_BRAZIL'], // cant login, no admin notifiaction
  readOnlyStatuses: ['READ_ONLY'] // can login, no cart or checkout
};

const actions = {
  loadUsers ({ commit }) {
    userService.getAll()
      .then(
        users => commit('loadUsersSuccess', users),
        error => commit('loadUsersFailure', error)
      );
  },
  delete ({ dispatch, commit }, id) {
    // commit('deleteRequest', id);
    dispatch('alert/loading', true, { root: true });
    userService.delete(id)
      .then(
        (user) => {
          dispatch('alert/loading', false, { root: true });
          dispatch('alert/success', 'User successfully deleted!', { root: true });
          dispatch('loadUsers');
        },
        error => commit('deleteFailure', { id, error: error.toString() })
      );
  },
  deleteAllByIds ({ dispatch, commit }, ids) {
    dispatch('alert/loading', true, { root: true });
    userService.deleteAllByIds(ids)
      .then(
        (user) => {
          dispatch('alert/loading', false, { root: true });
          dispatch('alert/success', 'Users successfully deleted!', { root: true });
          dispatch('loadUsers');
        },
        error => commit('deleteFailure', { ids, error: error.toString() })
      );
  },
  update ({ dispatch, commit }, user) {
    dispatch('alert/loading', true, { root: true });
    userService.update(user)
      .then(
        (response) => {
          dispatch('alert/loading', false, { root: true });
          dispatch('alert/success', 'User successfully updated!', { root: true });
          dispatch('loadUsers');
        },
        // error => commit('deleteFailure', { id, error: error.toString() })
        (error) => {
          // console.log(error.toString());
          commit('registerFailure', error);
          dispatch('alert/error', 'Error updating user.', { root: true });
        }
      );
  },
  updateFields ({ dispatch, commit }, user) {
    dispatch('alert/loading', true, { root: true });
    userService.updateFields(user)
      .then(
        (response) => {
          dispatch('alert/loading', false, { root: true });
          dispatch('alert/success', 'User successfully updated!', { root: true });
          dispatch('loadUsers');
        },
        // error => commit('deleteFailure', { id, error: error.toString() })
        (error) => {
          // console.log(error.toString());
          commit('registerFailure', error);
          dispatch('alert/error', 'Error updating user.', { root: true });
        }
      );
  },
  updateByUsername ({ dispatch, commit }, user) {
    dispatch('alert/loading', true, { root: true });
    userService.updateByUsername(user)
      .then(
        (user) => {
          dispatch('alert/loading', false, { root: true });
          dispatch('alert/success', 'Account successfully updated!', { root: true });
          dispatch('loadUsers');
        },
        error => commit('deleteFailure', { user, error: error.toString() })
      );
  },
  register ({ dispatch, commit }, user) {
    commit('registerRequest', user);

    userService.register(user)
      .then(
        user => {
          commit('registerSuccess', user);
          // router.push('/login');
          dispatch('alert/success', 'User successfully created!', { root: true });
          dispatch('loadUsers');
        },
        error => {
          commit('registerFailure', error);
          dispatch('alert/error', error, { root: true });
        }
      );
  },
  submitApplication ({ dispatch, commit }, user) {
    return new Promise((resolve, reject) => {
      userService.submitApplication(user).then(response => {
        // http success, call the mutator and change something in state
        dispatch('alert/success', 'Application successfully submitted!', { root: true });
        resolve(response); // Let the calling function know that http is done. You may send some data back
      }, error => {
        // http failed, let the calling function know that action did not work out
        dispatch('alert/error', error, { root: true });
        reject(error);
      });
    });
  }
};

const mutations = {
  loadUsersSuccess (state, users) {
    // Store as map for rapid lookup later
    state.users = arrayToObject(users, 'username');
    state.usersById = arrayToObject(users, 'id');
    state.usernames = Object.keys(state.users).sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    // Sum 'price' field for all selected rows
    const count = users.reduce((a, b) => {
      return state.pendingStatuses.includes(b.accountStatus.toUpperCase()) ? a + 1 : a;
    }, 0);

    state.pendingApprovalCount = count;
  },
  loadUsersFailure (state, error) {
    // state.users = { error };
    state.users = {};
  },
  registerRequest (state, user) {
    state.status = { registering: true };
  },
  registerSuccess (state, user) {
    state.status = {};
  },
  registerFailure (state, error) {
    state.status = {};
  }
};

const getters = {
  availableStatuses (state) {
    return [...state.approvedStatuses, ...state.pendingStatuses, ...state.reviewStatuses, ...state.readOnlyStatuses];
  }
};

const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
