import { productService } from '@/_services';

const state = {
  productsBySku: {},
  accessoriesBySku: {},
  mediaBySku: {}
};

const actions = {
  loadProducts ({ dispatch, commit }) {
    dispatch('alert/loading', true, { root: true });

    productService.getAllProductsAccessoriesInStock().then((results) => {
      // Process results, store in state
      commit('setProductsBySkuRequest', results.productsMap);
      commit('setAccessoriesBySkuRequest', results.accessoriesMap);
      commit('setMediaBySkuRequest', results.mediaMap);
      dispatch('alert/loading', false, { root: true });
    }, () => {
      console.log('Error in product/accessories promise');
    });
  }
};

const mutations = {
  setProductsBySkuRequest (state, productsBySku) {
    state.productsBySku = productsBySku;
  },
  setAccessoriesBySkuRequest (state, accessoriesBySku) {
    state.accessoriesBySku = accessoriesBySku;
  },
  setMediaBySkuRequest (state, mediaBySku) {
    state.mediaBySku = mediaBySku;
  }
};

const getters = {
  /**
     * Get all items, accessories and products (smartphones)
     * @returns map of {sku: item}
     */
  allItemsBySku: function (state) {
    // merge the two maps
    return {
      ...state.productsBySku,
      ...state.accessoriesBySku
    };
  }
};

export const products = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
