<template>
    <v-dialog v-model="dialogState" :width="width" :fullscreen="fullscreen">
      <v-card>
       <v-card-actions height="style: 90px">
          <v-container class="media-product-header" fill-height>
            <v-row>
              <v-col cols="12" md="6">
                <span class="centered">
                  <h1>
                    {{ productTitle }} <span class="media-product-price">{{ productPrice }}</span>
                  </h1>
                </span>
              </v-col>
              <v-col cols="6" md="2" v-if="showActions">
                <v-select
                  :items="productStockList"
                  label="Quantity"
                  type="number"
                  v-model="productQuantity"
                  @change="updateTotal"
                ></v-select>
              </v-col>
              <v-col cols="6" md="4" v-if="!userIsReadOnly && showActions">
                <span class="media-cart-button">
                  <v-btn
                    large
                    color="pink"
                    class="white--text"
                    @click="addToCartFromDialog"
                  >
                    <span class="d-none d-md-flex">{{ addToCartText }}</span>
                    <span class="d-flex d-md-none" id="shopping-cart-icon"><v-icon left>add_shopping_cart</v-icon>{{ formattedPriceSelected }}</span>
                  </v-btn>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>

        <v-carousel
          :cycle="cycle"
          v-model="slider"
          height="667px"
          v-if="type === 'media'"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
            :transition="cycle"
            :reverse-transition="cycle"
            @error="onErrorCarouselItem"
          >
            <v-container class="centered" fill-height v-if="isMediaMissing(item.src)">
              <v-row align-center>
                <v-col cols="12">
                  <h1>
                    <span class="media-message">Photo No Longer Available</span>
                  </h1>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>

        <v-container v-if="type === 'functionality'">
          <v-row align-center>
            <v-col cols="1"></v-col>
            <v-col cols="10">
              <span class="centered">
                {{ productNotes }}
              </span>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
        </v-container>

        <v-card-actions class="pa-0">
          <v-container>
            <v-row>
              <v-col cols="12" align="center">
                <v-btn color="primary" text @click="dialogState = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import formatterMixin from '@/_mixins/formatterMixin';
import cartMixin from '@/_mixins/cartMixin';

export default {
  name: 'ProductsDialog',
  props: {
    showDialog: Boolean,
    width: String,
    fullscreen: Boolean,
    type: String,
    product: Object,
    showActions: {
      default: true,
      type: Boolean
    },
    productsBySkuProp: {
      type: Object,
      default: null
    },
    mediaBySkuProp: {
      type: Object,
      default: null
    }
  },
  mixins: [
    formatterMixin,
    cartMixin
  ],
  data () {
    return {
      cycle: false,
      slider: 0,
      itemErrors: [],
      priceSelected: 0.0
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
      mediaBySkuVuex: state => state.products.mediaBySku,
      productsBySkuVuex: state => state.products.productsBySku,
      cartProducts: state => state.cart.products,
      cartTotal: state => state.cart.price,
      globalSettings: state => state.settings.settings
    }),
    ...mapGetters('account', ['userIsReadOnly']),
    productsBySku () {
      return !this.productsBySkuProp || Object.entries(this.productsBySkuProp).length === 0 ? this.productsBySkuVuex : this.productsBySkuProp;
    },
    mediaBySku () {
      return !this.mediaBySkuProp || Object.entries(this.mediaBySkuProp).length === 0 ? this.mediaBySkuVuex : this.mediaBySkuProp;
    },
    dialogState: {
      get: function () {
        return this.showDialog;
      },
      set: function (value) {
        this.$emit('updateDialog_' + this.type, value);

        // If we are closing the dialog, do anything else that we need
        // to clean up
        if (value === false) {
          this.closeDialogHandler();
        }
      }
    },
    productTitle () {
      let title = '';
      if (this.product !== null) {
        if (this.product.model) { // Smartphones have models and item numbers, use that for title
          title += this.product.model;
          if (this.product.dbitemnum) {
            title += ' (' + this.product.dbitemnum + ')';
          }
        } else if (this.product.description) { // accessories only have description
          title = this.product.description;
        }
      }
      return title;
    },
    productQuantity: {
      get () {
        if (this.product !== null && 'quantity' in this.product) {
          return this.product.quantity;
        } else {
          return 1;
        }
      },
      set (value) {
        if (this.product !== null && 'sku' in this.product) {
          this.$emit('updateQuantity', { sku: this.product.sku, quantity: value });
        }
      }
    },
    productPrice () {
      if (this.product !== null) {
        return this.formatCurrency(this.product.price);
      }
      return 0;
    },
    productStock () {
      if (this.product !== null) {
        return this.product.stock;
      }
      return 0;
    },
    productStockList () {
      return [...Array(this.productStock + 1).keys()].slice(1);
    },
    productNotes () {
      if (this.product !== null && 'notes' in this.product) {
        return this.product.notes;
      }

      if (this.product !== null && this.productsBySku[this.product.sku]) {
        return this.productsBySku[this.product.sku].notes;
      }

      return '';
    },
    items () {
      if (this.product !== null && 'sku' in this.product) {
        return this.mediaBySku[this.product.sku];
      }
      return [];
    },
    addToCartText () {
      if (this.productQuantity === 1) {
        return 'Add 1 Item To Cart: ' + this.formattedPriceSelected;
      }
      return 'Add ' + this.productQuantity + ' Items To Cart: ' + this.formattedPriceSelected;
    },
    formattedPriceSelected () {
      return this.formatCurrency(this.priceSelected);
    }
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    addToCartFromDialog () {
      if (this.userIsReadOnly) {
        return;
      }

      // this function takes an array of objects with sku and qty
      this.addToCart([{ sku: this.product.sku, qty: this.productQuantity }]);
      this.dialogState = false;
    },
    closeDialogHandler () {
      this.slider = 0;
      this.itemErrors = [];
    },
    onErrorCarouselItem (event) {
      this.itemErrors.push(event);
    },
    isMediaMissing (url) {
      return this.itemErrors.includes(url);
    },
    updateTotal (newVal, oldValue) {
      if (this.product) {
        this.priceSelected = newVal * this.product.price;
      }
    }
  },
  watch: {
    product (newVal, oldVal) {
      if (newVal) {
        this.priceSelected = newVal.price;
      }
    }
  }
};
</script>
<style scoped>
.v-select {
  min-width: fit-content;
}
</style>
