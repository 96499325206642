import { userService } from '@/_services';
import { router } from '@/router';

const user = JSON.parse(localStorage.getItem('user'));

const state =
    user
      ? {
        status: { loggedIn: true },
        username: user.username,
        user
      }
      : {
        status: {},
        username: null,
        user: null
      };

const actions = {
  loadUser ({ dispatch, commit, getters }, username) {
    userService.getByUsername(username).then(
      (user) => {
        // If ADMIN, load ADMIN resources
        if (user !== null && user.isAdmin) {
          dispatch('orders/getAllOrders', null, { root: true });
          dispatch('users/loadUsers', null, { root: true })
            .then(

            );
        }
        commit('loginSuccess', user);

        // Check account status and display appropriate response if necessary
        if (getters.userIsInReview) {
          router.push('/account-in-review');
        } else if (getters.userIsPending) {
          router.push('/account-pending');
        } else {
          dispatch('alert/success', 'Successfully logged in!', { root: true });
          router.push('/');
        }

        dispatch('alert/success', 'Successfully logged in!', { root: true });
      }
    );
  },
  login ({ dispatch, commit, getters }, { usernameEmail, password }) {
    commit('loginRequest', { usernameEmail });

    userService.login(usernameEmail, password)
      .then(
        user => {
          // If ADMIN, load ADMIN resources
          if (user !== null && user.isAdmin) {
            dispatch('users/loadUsers', null, { root: true })
              .then(

              );
          }
          commit('loginSuccess', user);

          // Check account status and display appropriate response if necessary
          if (getters.userIsInReview) {
            router.push('/account-in-review');
          } else if (getters.userIsPending) {
            router.push('/account-pending');
          } else {
            dispatch('alert/success', 'Successfully logged in!', { root: true });
            router.push('/');
          }
        },
        error => {
          commit('loginFailure', error);
          dispatch('alert/error', error, { root: true });
          dispatch('alert/loading', false, { root: true });
        }
      );
  },
  logout ({ commit }) {
    userService.logout();
    commit('logout');
  }
};

const mutations = {
  loginRequest (state, user) {
    state.status = { loggingIn: true };
    state.user = user;
    state.username = user.username;
  },
  loginSuccess (state, user) {
    state.status = { loggedIn: true };
    state.user = user;
    state.username = user.username;
  },
  loginFailure (state) {
    state.status = {};
    state.user = null;
    state.username = null;
  },
  logout (state) {
    state.status = {};
    state.user = null;
    state.username = null;
  }
};

const getters = {
  userIsReadOnly: function (state, getters, rootState) {
    const user = state.user;
    if (user && 'accountStatus' in user) {
      // check to see if users account status is in our read only list of statuses
      return rootState.users.readOnlyStatuses.includes(user.accountStatus.toUpperCase());
    } else {
      return false;
    }
  },
  userIsInReview: function (state, getters, rootState) {
    const user = state.user;
    if (user && 'accountStatus' in user) {
      // check to see if users account status is in our review list of statuses
      return rootState.users.reviewStatuses.includes(user.accountStatus.toUpperCase());
    } else {
      return false;
    }
  },
  userIsPending: function (state, getters, rootState) {
    const user = state.user;
    if (user && 'accountStatus' in user) {
      // check to see if users account status is in our pending list of statuses
      return rootState.users.pendingStatuses.includes(user.accountStatus.toUpperCase());
    } else {
      return false;
    }
  },
  userIsAdmin: function (state) {
    const user = state.user;
    if (user && 'isAdmin' in user) {
      return user.isAdmin;
    } else {
      return false;
    }
  },
  menuItems: function (state, getters) {
    // Icons are material icons https://fonts.google.com/icons?icon.set=Material+Icons
    // Load Menu Items based on User Authorization Level
    let allMenuItems = [
      { title: 'Smartphones', icon: 'phone_iphone', path: '/smartphones' },
      { title: 'Accessories', icon: 'power', path: '/accessories' }
    ];

    // if user is not read only, add cart to menu
    if (!getters.userIsReadOnly) {
      allMenuItems.push({ title: 'Cart', icon: 'shopping_cart', path: '/cart' });
    }

    allMenuItems.push({ title: 'Orders', icon: 'history', path: '/orders' });

    // if user is admin, add admin pages
    if (state.user && 'isAdmin' in state.user && state.user.isAdmin) {
      allMenuItems = [
        ...allMenuItems,
        { title: 'Users', icon: 'supervisor_account', path: '/users' },
        { title: 'Users Table', icon: 'recent_actors', path: '/users-table' },
        { title: 'Settings', icon: 'settings', path: '/settings' }
      ];
    }

    return [
      ...allMenuItems,
      { title: 'Profile', icon: 'account_box', path: '/account', adminRequired: false }, // TODO: Decide if account_box or account_circle
      { title: 'Store Credit', icon: 'payments', path: '/credit' },
      { title: 'FAQs / Terms', icon: 'help', path: '/faq' },
      { title: 'Logout', icon: 'input', path: '/login', adminRequired: false }
    ];
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
