import { usersTableService } from '@/_services/usersTable.service';

const state = {
  orderInfoGrouped: {}
};

const actions = {
  getAllOrdersGrouped ({ commit }) {
    return new Promise((resolve, reject) => {
      usersTableService.getAllGroup().then(
        (response) => {
          // http success, call the mutator and change something in state
          commit('setOrdersGrouped', response);
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  setOrdersGrouped (state, orders) {
    state.orderInfoGrouped = Object.assign({}, orders);
  }
};

export const usersTable = {
  namespaced: true,
  state,
  actions,
  mutations
};
