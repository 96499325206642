<template>
  <v-container fluid class="mt-16 mx-auto">
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card>
          <v-card-text>
            <div>
              <p class="text-h5 text--primary">
                Wholesale Account Application Pending!
              </p>
              <div class="text--primary confirmation-content">
                We've received your information and are currently reviewing your account.  We will reach out to you by email as soon as the account is ready for purchasing.
              </div>
              <div class="text--primary confirmation-content">
                In the meantime, if you have any questions please feel free to contact us on WhatsApp at <span style="white-space: nowrap;"><b>+1-321-830-3841 </b></span> or email us at <a href="mailto:wholesale@gizmotrader.com">wholesale@gizmotrader.com</a>. We're looking forward to working with you!
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'account-pending-page',
  components: {

  },
  data () {
    return {};
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    })
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

.confirmation-content {
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-card__text, .v-card__title {
  word-break: normal !important;
}

</style>
