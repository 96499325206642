import Vue from 'vue';
import Router from 'vue-router';

import LoginPage from '@/components/login/LoginPage';
import ResetPassword from '@/components/login/ResetPassword';
import ForgotPassword from '@/components/login/ForgotPassword';
import PasswordRequestSent from '@/components/login/PasswordRequestSent';
import AccountPendingPage from '@/components/login/AccountPendingPage';
import AccountInReviewPage from '@/components/login/AccountInReviewPage';
import ApplicationPage from '@/components/register/ApplicationPage';
import ApplicationConfirmationPage from '@/components/register/ApplicationConfirmationPage';
import RegisterPage from '@/components/register/RegisterPage';
import UsersPage from '@/components/users/UsersPage';
import AccountPage from '@/components/users/AccountPage';
import CartPage from '@/components/cart/CartPage';
import ProductsPage from '@/components/products/ProductsPage';
import AccessoriesPage from '@/components/accessories/AccessoriesPage';
import OrdersPage from '@/components/orders/OrdersPage';
import CheckoutPage from '@/components/checkout/CheckoutPage';
import CheckoutDirectPage from '@/components/checkout/CheckoutDirectPage';
import ConfirmationPage from '@/components/checkout/ConfirmationPage';
import ConfirmationDirectPage from '@/components/checkout/ConfirmationDirectPage';
import SettingsPage from '@/components/settings/SettingsPage';
import UsersTable from '@/components/usersTable/UsersTable';
import CreditPage from '@/components/credit/CreditPage';
import FAQPage from '@/components/faq/FAQPage';

Vue.use(Router);

// In newer version of vue router, they will throw errors for
// certain navigations. This ignores the error for duplcate navigation
// globally. IE someone is on /products and then there is a router.push('/products)
// Vue router will internally "ignore" the push and throw an error. This just dismisses
// that error
const originalPush = Router.prototype.push;
const { isNavigationFailure, NavigationFailureType } = Router;

Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((error) => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
      throw Error(error);
    }
  });
};

/**
 * Meta data for router
 * title: string -> left title on the header; default 'GizmoTrader Wholesale
 * showMenu: bool -> shows left nav menu; default true
 * showCenterTitle: bool -> shows center title on top header bar where it reads free shipping etc; default true
 * showHeaderBtns: bool -> show action buttons on header such as account and cart; default true
 */
export const router = new Router({
  mode: 'history',
  routes: [
    // { path: '/', component: HomePage2 },
    { path: '/smartphones', component: ProductsPage, meta: { title: 'Smartphones' } },
    { path: '/login', component: LoginPage, meta: { showMenu: false, showHeader: false } },
    { path: '/reset-password', component: ResetPassword, meta: { showMenu: false, showHeader: false } },
    { path: '/forgot-password', component: ForgotPassword, meta: { showMenu: false, showHeader: false } },
    { path: '/password-request-sent', component: PasswordRequestSent, meta: { showMenu: false, showHeader: false } },
    {
      path: '/account-pending',
      component: AccountPendingPage,
      meta: {
        showMenu: false,
        showCenterTitle: false,
        showHeaderBtns: false
      }
    },
    {
      path: '/account-in-review',
      component: AccountInReviewPage,
      meta: {
        showMenu: false,
        showCenterTitle: false,
        showHeaderBtns: false
      }
    },
    { path: '/register', component: RegisterPage, meta: { title: 'Create New User' } },
    { path: '/cart', component: CartPage, meta: { title: 'Shopping Cart' } },
    { path: '/accessories', component: AccessoriesPage, meta: { title: 'Accessories' } },
    { path: '/orders', name: 'orders', component: OrdersPage, meta: { title: 'Orders History' } },
    {
      path: '/apply',
      component: ApplicationPage,
      meta: {
        title: 'GizmoTrader Wholesale Account Application',
        showMenu: false,
        showCenterTitle: false,
        showHeaderBtns: false
      }
    },
    {
      path: '/apply-success',
      component: ApplicationConfirmationPage,
      meta: {
        title: 'GizmoTrader Wholesale Account Application',
        showMenu: false,
        showCenterTitle: false,
        showHeaderBtns: false
      }
    },
    // { path: '/register', component: RegisterPage, meta: { title: '' } }, // todo verify
    { path: '/users', name: 'users', component: UsersPage, meta: { title: 'Manage Users' } },
    { path: '/credit', name: 'credit', component: CreditPage, meta: { title: 'Store Credit' } },
    { path: '/account', component: AccountPage, meta: { title: 'Manage Account' } },
    { path: '/checkout', component: CheckoutPage, meta: { title: 'Checkout' } },
    { path: '/checkout-direct', component: CheckoutDirectPage, meta: { title: 'Checkout' } },
    { path: '/confirmation', component: ConfirmationPage, meta: { title: 'Confirmation' } },
    { path: '/confirmation-direct', component: ConfirmationDirectPage, meta: { title: 'Confirmation' } },
    { path: '/settings', component: SettingsPage, meta: { title: 'Settings' } },
    { path: '/users-table', component: UsersTable, meta: { title: 'Users Table' } },
    {
      path: '/faq',
      component: FAQPage,
      meta: {
        title: 'FAQs / Terms',
        showMenu: false,
        showCenterTitle: false,
        showHeaderBtns: false,
        showWhenLoggedIn: true
      }
    },
    { path: '/', component: ProductsPage, meta: { title: 'Smartphones' } },

    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // const publicPages = ['/login', '/register'];
  const publicPages = ['/login', '/apply', '/apply-success',
    '/reset-password', '/forgot-password', '/password-request-sent', '/account-pending', '/account-in-review', '/faq'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (to.path === '/reset-password') {
    if ('uuid' in to.query) {
      next();
    } else {
      return next('/login');
    }
  }

  next();
});
