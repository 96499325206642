<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <Header v-if="showHeader"></Header>

    <Menu v-if="showMenu"></Menu>

    <v-main id="inspire">
      <v-snackbar
        v-model="alert.display"
        :color="alert.type"
        :timeout="timeout"
        :top="top"
      >
          {{ alert.message }}
          <template v-slot:action="{}">
            <v-btn
              dark
              text
              @click="onClose"
            >
              Close
            </v-btn>
          </template>
      </v-snackbar>

      <v-dialog v-model="alert.loading" fullscreen transition="false">
        <v-container fluid fill-height style="background-color: rgba(128, 128, 128, 0.5);">
          <v-row justify="center" align="center">
            <v-progress-circular
              indeterminate
              color="primary"
              size="64">
            </v-progress-circular>
          </v-row>
        </v-container>
      </v-dialog>

      <v-dialog v-model="alert.lockScreen.enabled" fullscreen transition="false" persistent>
        <v-container fluid fill-height style="background-color: rgba(128, 128, 128, 0.5);">
          <v-row justify="center" align="center">
            <v-alert
              :value="alert.lockScreen.enabled"
              :type="alert.lockScreen.type"
              transition="scale-transition"
            >
              {{ alert.lockScreen.message }}
            </v-alert>
          </v-row>
        </v-container>
      </v-dialog>

      <keep-alive :include="['products', 'accessories', 'orders', 'credits', 'faq']">
        <router-view></router-view>
      </keep-alive>
    </v-main>

    <v-footer app dark padless height="32px" color="primary">
      <v-col class="text-center pa-0">
        <span style="font-family: Roboto,sans-serif;">&copy; 2024 GizmoTrader, All Rights Reserved.</span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { router } from '@/router';

import Menu from '@/components/menu/Menu';
import Header from '@/components/header/Header';

export default {
  name: 'app',
  components: {
    Menu,
    Header
  },
  data () {
    return {
      drawer: true,
      snackbar: false,
      top: true,
      timeout: 6000, // Alerts Timeout (default 6000)
      initializeAttempts: 0,
      maxInitializeAttempts: 100
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      username: state => state.account.username,
      account: state => state.account.user,
      checkoutOrder: state => state.orders.checkoutOrder,
      checkoutOrderInitialized: state => state.orders.initialized
    }),
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },
    showMenu () {
      if (this.$route.meta && 'showMenu' in this.$route.meta) {
        // show the menu if we want to show it when logged in
        if ('showWhenLoggedIn' in this.$route.meta) {
          return this.$route.meta.showWhenLoggedIn && this.username !== null;
        }

        return this.$route.meta.showMenu;
      }

      return true;
    },
    showHeader () {
      if (this.$route.meta && 'showHeader' in this.$route.meta) {
        return this.$route.meta.showHeader;
      }

      return true;
    }
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading',
      lockScreenOverlay: 'alert/lockScreen'
    }),
    ...mapActions('cart', ['clearCartProducts', 'refreshCartProducts', 'removeAllCartProducts']),
    ...mapActions('settings', ['refreshSettings']),
    ...mapActions('orders', ['clearOrders', 'refreshOrders', 'loadCheckoutOrder', 'clearCheckoutOrder', 'loadVerifiedOrder']),
    ...mapActions('products', ['loadProducts']),
    ...mapActions('account', ['loadUser']),
    ...mapActions('credits', ['getCreditByUsername', 'getAllCredits']),
    openSocket () {
      // Open socket
      this.$openSocket(this.username);

      // Register INFO event handlers
      this.$getSocket().on('info', (...args) => {
        // console.log(args[0]);
      });

      // Register ORDER event handlers
      this.$getSocket().on('order', (...args) => {
        const eventObject = args[0];

        if (eventObject.operation === 'CREATE') {
          if (this.checkoutOrder === null) {
            this.infoAlert('Checkout initiated!');
            this.loadCheckoutOrder();
          }
        } else if (eventObject.operation === 'DELETE') {
          this.clearCheckoutOrder();
          router.push('/cart');
          this.errorAlert('Checkout canceled!');
        } else if (eventObject.operation === 'UPDATE') {
          this.loadCheckoutOrder();
        } else if (eventObject.operation === 'COMPLETE') {
          this.loadCheckoutOrder();

          if (router.currentRoute.path !== '/confirmation' || router.currentRoute.path !== '/confirmation-direct') {
            this.loadVerifiedOrder(eventObject.order);
            this.removeAllCartProducts();
            this.loadProducts(); // THIS IS RELOADING PRODUCTS AFTER AN ORDER HAS BEEN PLACED. NECESSARY?

            if (eventObject.order.payment === 'paypal') {
              router.push('/confirmation');
            } else if (eventObject.order.payment === 'directpayment') {
              router.push('/confirmation-direct');
            }
          }
        }
      });

      // Register CART event handlers
      this.$getSocket().on('cart', (...args) => {
        const eventObject = args[0];
        this.refreshCartProducts(eventObject);
      });

      // Register credit event handler
      this.$getSocket().on('credit', (...args) => {
        this.getCreditByUsername(this.username);
        this.getAllCredits();
      });
    },
    closeSocket () {
      // Close socket
      this.$closeSocket();
    },
    initializeUser () {
      if (this.initializeAttempts < this.maxInitializeAttempts && this.username) {
        this.initializeAttempts++;
        // wait until we know the browser has successfully loaded the logged in user
        if (this.username && this.account.token) {
          // Fetch user info from server
          this.loadUser(this.username).then(() => {
            // Force vuex store to refresh products with server cart database
            this.refreshSettings();
            this.loadProducts(); // THIS IS THE INITIAL PRODUCT LOAD FOR THE USER.
            this.refreshCartProducts();
            this.refreshOrders();

            // Fetch PENDING_PAYMENT || PENDING_PAYPAL_CHECKOUT order to see if this user is currently
            // in the checkout process from a different browsing session.
            this.loadCheckoutOrder();

            // Open socket connection for synchronizing order status info with server
            this.openSocket();
          });

          this.initializeAttempts = 0;
        } else {
          delay(100).then(() => {
            this.initializeUser();
          });
        }
      } else {
        this.initializeAttempts = 0;
        this.loadingOverlay(false);
      }
    },
    processCheckoutOrder () {
      if (this.checkoutOrder !== null) {
        // if (this.checkoutOrder.fulfillmentStatus.toUpperCase() === "PENDING_PAYPAL_CHECKOUT") {
        //   this.lockScreenOverlay({ displayLockScreen: true, type: "success", message: "Please complete active PayPal checkout process in browser where it was initiated!" });
        // } else {

        // DETERMINE IF THIS SHOULD GO TO CHECKOUT OR CHECKOUT-DIRECT!
        // For now the only way to get a DISCOUNT is to use DIRECT checkout so we can check that field. But will update this later!
        if ('discount' in this.checkoutOrder && this.checkoutOrder.discount !== null && this.checkoutOrder.discount > 0) {
          router.push('/checkout-direct');
        } else {
          router.push('/checkout');
        }

        // }
      }
    },
    onClose () {
      this.clearAlert();
    }
  },
  watch: {
    $route (to, from) {
      // clear alert on location change
      // this.clearAlert();
    },
    username (to, from) {
      this.clearCartProducts();
      this.clearOrders();
      this.closeSocket();
      if (to) {
        this.loadingOverlay(true);
        this.initializeUser();
      }
    },
    checkoutOrderInitialized (to, from) {
      if (to) {
        this.processCheckoutOrder();
      }
    }
  },
  mounted () {
    // Clear cart
    this.clearCartProducts();

    // Clear oders
    this.clearOrders();

    // Check if we have a valid logged in username.
    if (this.username) {
      this.initializeUser();
    }
  }
};

function delay (ms) {
  return new Promise(function (resolve, reject) {
    setTimeout(resolve, ms);
  });
}

</script>

<style>
html {
    overflow: auto !important;
}

main.v-main {
  /* width: 100vw; */
  height: calc(100vh - 250px);
  /* flex-direction: column; */
  overflow: auto;
  /* margin-top: 64px;
  padding-top: 0 !important; */
}

/* Overriding vue-tables-2 pagination styling */
.VuePagination {
  position: absolute;
  top: 30px;
  left: 330px;
}

/* prevent footer from overlaping anything on the page */
.v-footer {
  position: relative !important;
}
</style>
