
export default class MobileFilterModel {
  constructor () {
    this.clear();
  }

  clear() {
    this.brands = [];
    this.functionalities = [];
    this.accTypes = [];
    this.colors = [];
    this.models = [];
    this.storage = [];
    this.carriers = [];
    this.conditions = [];
    this.lockStatus = [];
    this.priceRange = [];
    this.availabilityRange = [];
    this.priceRangeIsSet = false;
    this.availabilityRangeIsSet = false;
    this.appliedFilters = 0;
    this.filterFunc = (products, _) => products; // default method
  }
}