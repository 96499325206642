<template>
    <!-- Invalid Products Dialog -->
    <v-dialog
      v-model="showDialog"
      max-width="800"
      :fullscreen="fullscreen"
    >
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>

        <v-card-text>
            <v-list two-line>
            <template v-for="(item) in items">
              <v-list-tile
                :key="item.title"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-html="item.title"></v-list-tile-title>
                  <v-list-tile-sub-title v-html="item.subtitle"></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            @click="showDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'InvalidProductsDialog',
  props: {
    invalidProducts: Object,
    fullscreen: Boolean
  },
  computed: {
    title () {
      if (this.invalidProducts !== null && 'title' in this.invalidProducts) {
        return this.invalidProducts.title;
      }
      return '';
    },
    items () {
      if (this.invalidProducts !== null && 'items' in this.invalidProducts) {
        return this.invalidProducts.items;
      }
      return '';
    },
    showDialog: {
      get () {
        if (this.invalidProducts !== null && 'displayed' in this.invalidProducts) {
          return this.invalidProducts.displayed;
        }
        return false;
      },
      set (value) {
        this.$emit('updateDialog_invalidProducts', value);
      }

    }
  }
};
</script>
