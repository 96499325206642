import Vue from 'vue';
import App from './components/app/App.vue';
import { router } from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
import { LicenseManager } from 'ag-grid-enterprise';
import VeeValidate from 'vee-validate';
import Socket from './plugins/socket';

Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(Socket);

// Set ag-grid enterprise license key
LicenseManager.setLicenseKey('CompanyName=Gizmotrader.com,LicensedApplication=gizmotrader.wholesale,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-029591,SupportServicesEnd=25_July_2023_[v2]_MTY5MDIzOTYwMDAwMA==414c9702646b1cb96dbce9c88c25825c');

window.vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
