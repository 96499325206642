<template>
    <div>
        <div id="register-page">
          <v-main>
            <v-container fluid class="mt-16 mx-auto">
                <v-row align-top justify-left>
                <v-col cols="12" sm8 md4>
                    <v-card class="elevation-12">
                    <v-card-text>
                        <v-form @keyup.native.enter="handleSubmit()">
                          <v-text-field
                              name="login"
                              label="Username"
                              type="text"
                              v-model="user.username"
                          ></v-text-field>
                          <v-text-field
                              name="firstName"
                              label="First Name"
                              type="text"
                              v-model="user.firstName"
                          ></v-text-field>
                          <v-text-field
                              name="lastName"
                              label="Last Name"
                              type="text"
                              v-model="user.lastName"
                          ></v-text-field>
                          <v-text-field
                              name="email"
                              label="Email"
                              type="text"
                              v-model="user.email"
                          ></v-text-field>
                          <v-text-field
                              name="company"
                              label="Company"
                              type="text"
                              v-model="user.company"
                          ></v-text-field>
                          <v-text-field
                              name="address1"
                              label="Address Line 1"
                              type="text"
                              v-model="user.address1"
                          ></v-text-field>
                          <v-text-field
                              name="address2"
                              label="Address Line 2"
                              type="text"
                              v-model="user.address2"
                          ></v-text-field>
                          <v-text-field
                              name="address3"
                              label="Address Line 3"
                              type="text"
                              v-model="user.address3"
                          ></v-text-field>
                          <v-text-field
                              name="city"
                              label="City"
                              type="text"
                              v-model="user.city"
                          ></v-text-field>
                          <v-text-field
                              name="state"
                              label="State"
                              type="text"
                              v-model="user.state"
                          ></v-text-field>
                          <v-text-field
                              name="zip"
                              label="Zip"
                              type="text"
                              v-model="user.zip"
                          ></v-text-field>
                          <v-text-field
                              name="phone"
                              label="Phone"
                              type="text"
                              v-model="user.phone"
                          ></v-text-field>
                          <v-checkbox
                            v-model="user.taxExempt"
                            label="Tax Exempt"
                            disabled
                          ></v-checkbox>
                          <v-checkbox
                            v-model="user.directPaymentEnabled"
                            label="Direct Payment Enabled"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="user.isAdmin"
                            label="Wholesale Site Administrator"
                          ></v-checkbox>
                          <v-text-field
                              name="password"
                              label="Password"
                              id="password"
                              type="password"
                              v-model="user.password"
                          ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="clearUser()">Reset</v-btn>
                        <v-btn color="primary" @click="handleSubmit()">Create New User</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-col>
                </v-row>
            </v-container>
          </v-main>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'register',
  components: {
  },
  data () {
    return {
      user: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        isAdmin: false,
        taxExempt: true,
        directPaymentEnabled: false,
        password: ''
      },
      submitted: false
    };
  },
  computed: {

  },
  methods: {
    ...mapActions('users', ['register', 'loadUsers']),
    clearUser () {
      this.user = {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        isAdmin: false,
        taxExempt: true,
        directPaymentEnabled: false,
        password: ''
      };
    },
    handleSubmit (e) {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.register(this.user).then(() => {
            this.clearUser();
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";

</style>
