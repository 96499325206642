<template>
    <v-dialog
      :value="displayed" @input="emitOutput"
      max-width="500"
    >
        <v-card>
            <v-card-title class="headline">{{ title }}</v-card-title>

            <v-card-text>
                {{ text }}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="primary darken-1"
                    text
                    @click="onNo"
                >
                    No
                </v-btn>

                <v-btn
                    color="primary darken-1"
                    text
                    @click="onYes"
                >
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: [
    'title',
    'text',
    'displayed',
    'onYes',
    'onNo'
  ],
  methods: {
    emitOutput (value) {
      this.$emit('input', value);
    }
  }
};
</script>
