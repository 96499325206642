export const ADDITION_ACTIONS = ['PAYMENT', 'CREDIT', 'CANCELATION', 'REFUND', 'CORRECTION+'];
export const SUBTRACTION_ACTIONS = ['FINANCE_FEE', 'PAYMENT_FEE', 'ORDER', 'STORE_CREDIT_USE', 'CHARGE', 'CORRECTION-'];

export function isPositiveAction (action) {
  return ADDITION_ACTIONS.includes(action);
}

export function isNegativeAction (action) {
  return SUBTRACTION_ACTIONS.includes(action);
}
