import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { account } from './account.module';
import { users } from './users.module';
import { cart } from './cart.module';
import { products } from './products.module';
import { orders } from './orders.module';
import { settings } from './settings.module';
import { usersTable } from './usersTable.module';
import { credits } from './credits.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    account,
    users,
    cart,
    products,
    orders,
    settings,
    usersTable,
    credits
  }
});
