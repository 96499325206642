<template>
    <v-dialog
      v-model="dialogState"
      max-width="800"
      :fullscreen="fullscreen"
      persistent
      scrollable
    >
      <v-card v-if="modelCopy">
        <v-card-title class="headline">{{ title }}
          <v-spacer></v-spacer>
          <v-btn v-if="modelsFiltersUpdated && modelCopy.models.length > 0" @click="onSetModelFilter()" color="success" style="z-index: 1">Set Model Filter</v-btn>
        </v-card-title>
        <v-card-text>
          <v-container v-if="isNotEmpty(brands)">
            <v-row class="font-weight-bold">Shop by Brand</v-row>
            <v-item-group multiple :value="brandModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(brand, index) in brands" :key="index" cols="4">
                    <v-item v-slot="{ toggle }">
                      <div
                        v-if="brand === 'Apple'"
                        @click="onToggle('brands', brand, toggle)"
                        style="text-align:center;">
                          <v-img src="@/assets/apple.svg"></v-img>
                          <span>{{brand}}</span>
                      </div>
                      <div
                        v-else-if="brand === 'Google'"
                        @click="onToggle('brands', brand, toggle)"
                        style="text-align:center;">
                          <v-img src="@/assets/google.svg"></v-img>
                          <span>{{brand}}</span>
                      </div>
                      <div
                        v-else-if="brand === 'Samsung'"
                        @click="onToggle('brands', brand, toggle)"
                        style="text-align:center;">
                          <v-img src="@/assets/samsung.svg"></v-img>
                          <span>{{brand}}</span>
                      </div>
                      <div
                        v-else
                        @click="onToggle('brands', brand, toggle)"
                        style="text-align:center;">
                          {{brand}}
                      </div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
            <v-divider></v-divider>
          </v-container>
          <v-container v-if="isNotEmpty(functionalities)">
            <v-row class="font-weight-bold">Shop by Funtionality</v-row>
            <v-item-group multiple :value="functionalityModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(functionality, index) in functionalities" :key="index">
                    <v-item v-slot="{ toggle }">
                      <div @click="onToggle('functionalities', functionality, toggle)">{{functionality}}</div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
            <v-divider></v-divider>
          </v-container>
          <v-container v-if="isNotEmpty(accTypes)">
            <v-row class="font-weight-bold">Shop by Type</v-row>
            <v-item-group multiple :value="accTypesModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(accType, index) in accTypes" :key="index">
                    <v-item v-slot="{ toggle }">
                      <div @click="onToggle('accTypes', accType, toggle)">{{accType}}</div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
            <v-divider></v-divider>
          </v-container>
          <v-container v-if="isNotEmpty(conditions)">
            <v-row class="font-weight-bold">Shop by Condition</v-row>
            <v-item-group multiple :value="conditionModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(condition, index) in conditions" :key="index">
                    <v-item v-slot="{ toggle }">
                      <div @click="onToggle('conditions', condition, toggle)">{{condition}}</div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
            <v-divider></v-divider>
          </v-container>
          <v-container class="mt-2" v-if="isNotEmpty(models)">
            <v-row
              @click="onClearModelFilter()">
              <v-autocomplete
                :class="!modelsFiltersSet ? 'mt-2' : 'mt-2 isActive'"
                v-model="modelCopy.models"
                :items="models"
                chips
                deletable-chips
                multiple
                small-chips
                @change="onModelsChange"
                :menu-props="{ closeOnClick: true, closeOnContentClick: false }"
                :disabled=modelsFiltersSet
              ></v-autocomplete>
            </v-row>
          <v-divider></v-divider>
          </v-container>
          <v-container v-if="isNotEmpty(storage)">
            <v-row class="font-weight-bold">Shop by Storage Capacity</v-row>
            <v-item-group multiple :value="storageModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(capacity, index) in storage" :key="index">
                    <v-item v-slot="{ toggle }">
                      <div @click="onToggle('storage', capacity, toggle)">{{capacity}}</div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          <v-divider></v-divider>
          </v-container>
          <v-container v-if="isNotEmpty(lockStatus)">
            <v-row class="font-weight-bold">Shop by Lock Status</v-row>
            <v-item-group multiple :value="lockStatusModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(status, index) in lockStatus" :key="index">
                    <v-item v-slot="{ toggle }">
                      <div @click="onToggle('lockStatus', status, toggle)">{{status}}</div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          <v-divider></v-divider>
          </v-container>
          <v-container v-if="isNotEmpty(colors)">
            <v-row class="font-weight-bold">Shop by Color</v-row>
            <v-item-group multiple :value="colorModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(color, index) in colors" :key="index">
                    <v-item v-slot="{ toggle }">
                      <div @click="onToggle('colors', color, toggle)">{{color}}</div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          <v-divider></v-divider>
          </v-container>
          <v-container>
            <v-row class="font-weight-bold">Shop by Price</v-row>
            <v-row>
              <v-col cols="12">
                <v-range-slider
                  v-model="priceSliderRange"
                  :max="100"
                  :min="0"
                  class="align-center"
                  @end="onPriceChange()"
                >
                  <template v-slot:prepend>
                    <span class="mt-2">$</span>
                    <v-text-field
                      :value="modelCopy.priceRange[0]"
                      class="mt-0 pt-0"
                      hide-details
                      hide-spin-buttons
                      single-line
                      type="number"
                      style="width: 60px"
                      disabled
                    ></v-text-field>
                  </template>
                  <template v-slot:append>
                    <span class="mt-2">$</span>
                    <v-text-field
                      :value="modelCopy.priceRange[1]"
                      class="mt-0 pt-0"
                      hide-details
                      hide-spin-buttons
                      single-line
                      type="number"
                      style="width: 60px"
                      disabled
                    ></v-text-field>
                  </template>
                </v-range-slider>
              </v-col>
            </v-row>
          <v-divider></v-divider>
          </v-container>
          <v-container>
            <v-row class="font-weight-bold">Shop by Availability</v-row>
            <v-row>
              <v-col cols="12">
                <v-range-slider
                  v-model="availabilitySliderRange"
                  :max="100"
                  :min="0"
                  class="align-center"
                  @end="onAvailabilityChange()"
                >
                  <template v-slot:prepend>
                    <v-text-field
                      :value="modelCopy.availabilityRange[0]"
                      class="mt-0 pt-0"
                      hide-details
                      hide-spin-buttons
                      single-line
                      type="number"
                      style="width: 60px"
                      disabled
                    ></v-text-field>
                  </template>
                  <template v-slot:append>
                    <v-text-field
                      :value="modelCopy.availabilityRange[1]"
                      class="mt-0 pt-0"
                      hide-details
                      hide-spin-buttons
                      single-line
                      type="number"
                      style="width: 60px"
                      disabled
                    ></v-text-field>
                  </template>
                </v-range-slider>
              </v-col>
            </v-row>
          <v-divider></v-divider>
          </v-container>
          <v-container v-if="isNotEmpty(carriers)">
            <v-row class="font-weight-bold">Shop by Carrier</v-row>
            <v-item-group multiple :value="carrierModel" active-class="isActive">
              <v-container>
                <v-row>
                  <v-col v-for="(carrier, index) in carriers" :key="index">
                    <v-item v-slot="{ toggle }">
                      <div @click="onToggle('carriers', carrier, toggle)">{{carrier}}</div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          <v-divider></v-divider>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn
            color="primary darken-1"
            @click="onApply()"
            :disabled="actionButtonsDisabled"
          >
            Show {{filteredProductsQuantity}} Results
          </v-btn>
          <v-btn
            color="secondary darken-1"
            @click="onClear(false)"
            :disabled="actionButtonsDisabled"
          >
            Clear
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="onCancel()"
            :disabled="actionButtonsDisabled"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import clone from 'clone';
import { mapActions } from 'vuex';

export default {
  name: 'FilterProductsDialog',
  props: {
    title: String,
    showDialog: Boolean,
    width: String,
    fullscreen: Boolean,
    products: Array,
    filterModel: Object,
    type: String
  },
  mixins: [
  ],
  data () {
    return {
      modelCopy: null,
      modelFilterApplied: false,
      filteredProductsQuantity: 0,
      brands: [],
      functionalities: [],
      accTypes: [],
      colors: [],
      models: [],
      storage: [],
      carriers: [],
      conditions: [],
      lockStatus: [],
      minPrice: 0,
      maxPrice: 0,
      minAvailability: 0,
      maxAvailability: 0,
      filterUpdateDelay: 1000,
      filterUpdateTimer: undefined,
      modelsFiltersUpdated: false,
      modelsFiltersSet: false,
      priceSliderRange: [0, 100],
      availabilitySliderRange: [0, 100],
      actionButtonsDisabled: false
    };
  },
  beforeMount () {
    this.filterModel.filterFunc = this.getFilteredProducts;
    this.modelCopy = clone(this.filterModel);
    this.modelCopy.filterFunc = this.getFilteredProducts;

    if (this.type === 'product') {
      this.processProducts();
    } else if (this.type === 'accessories') {
      this.processAccessories();
    } else {
      console.log('[ERROR] Unknow filter dialog type');
    }

    if (this.modelCopy.models.length > 0) {
      this.modelFilterApplied = true;
      this.modelsFiltersSet = true;
    }

    if (!this.modelCopy.priceRangeIsSet) {
      this.modelCopy.priceRange[0] = this.minPrice;
      this.modelCopy.priceRange[1] = this.maxPrice;
    }

    if (!this.modelCopy.availabilityRangeIsSet) {
      this.modelCopy.availabilityRange[0] = this.minAvailability;
      this.modelCopy.availabilityRange[1] = this.maxAvailability;
    }

    this.updatedFilterOptions();
  },
  beforeUnmount () {
    if (this.filterUpdateTimer) {
      clearTimeout(this.filterUpdateTimer);
    }
  },
  computed: {
    dialogState: {
      get: function () {
        return this.showDialog;
      },
      set: function () {}
    },
    brandModel () {
      return this.modelCopy.brands.map(brand => this.brands.indexOf(brand));
    },
    functionalityModel () {
      return this.modelCopy.functionalities.map(functionality => this.functionalities.indexOf(functionality));
    },
    accTypesModel () {
      return this.modelCopy.accTypes.map(accType => this.accTypes.indexOf(accType));
    },
    colorModel () {
      return this.modelCopy.colors.map(color => this.colors.indexOf(color));
    },
    storageModel () {
      return this.modelCopy.storage.map(storage => this.storage.indexOf(storage));
    },
    carrierModel () {
      return this.modelCopy.carriers.map(carrier => this.carriers.indexOf(carrier));
    },
    conditionModel () {
      return this.modelCopy.conditions.map(condition => this.conditions.indexOf(condition));
    },
    lockStatusModel () {
      return this.modelCopy.lockStatus.map(status => this.lockStatus.indexOf(status));
    }
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      successAlert: 'alert/success',
      infoAlert: 'alert/info',
      errorAlert: 'alert/error',
      loadingOverlay: 'alert/loading'
    }),
    onToggle (modelKey, value, toggle) {
      toggle();

      const index = this.modelCopy[modelKey].indexOf(value);
      if (index === -1) {
        this.modelCopy[modelKey].push(value);
        this.modelCopy.appliedFilters++;
      } else {
        this.modelCopy[modelKey].splice(index, 1);
        this.modelCopy.appliedFilters--;
      }

      this.updatedFilterOptions(this.filterUpdateDelay);
    },
    isNotEmpty (arr) {
      return arr && arr.length > 0 && arr.filter(item => item && item !== '')[0];
    },
    onAvailabilityChange () {
      if ((this.availabilitySliderRange[0] !== 0 ||
          this.availabilitySliderRange[1] !== 100) &&
          !this.modelCopy.availabilityRangeIsSet) {
        this.modelCopy.appliedFilters++;
        this.modelCopy.availabilityRangeIsSet = true;
      } else if (this.availabilitySliderRange[0] === 0 &&
          this.availabilitySliderRange[1] === 100 &&
          this.modelCopy.availabilityRangeIsSet) {
        this.modelCopy.appliedFilters--;
        this.modelCopy.availabilityRangeIsSet = false;
      }

      this.updatedFilterOptions(this.filterUpdateDelay);
    },
    onPriceChange () {
      if ((this.priceSliderRange[0] !== 0 ||
          this.priceSliderRange[1] !== 100) &&
          !this.modelCopy.priceRangeIsSet) {
        this.modelCopy.appliedFilters++;
        this.modelCopy.priceRangeIsSet = true;
      } else if (this.priceSliderRange[0] === 0 &&
          this.priceSliderRange[1] === 100 &&
          this.modelCopy.priceRangeIsSet) {
        this.modelCopy.priceRangeIsSet = false;
        this.modelCopy.appliedFilters--;
      }

      this.updatedFilterOptions(this.filterUpdateDelay);
    },
    onModelsChange (newVal) {
      if (newVal.length === 0) {
        this.modelCopy.appliedFilters--;
        this.modelFilterApplied = false;
      } else if (newVal.length > 0 && !this.modelFilterApplied) {
        // We only want to count a model change once.
        // Don't treat each selected model as a seperate filter
        this.modelCopy.appliedFilters++;
        this.modelFilterApplied = true;
      }
      this.modelsFiltersUpdated = true;
    },
    onApply () {
      this.dialogState = false;
      // Ensure the number of applied filters is never < 0
      this.modelCopy.appliedFilters = this.modelCopy.appliedFilters < 0 ? 0 : this.modelCopy.appliedFilters;
      const model = this.modelCopy;
      const filteredProducts = this.getFilteredProducts();
      this.$emit('filterDialog_apply', {
        products: filteredProducts,
        model: model
      });
      this.modelCopy = null;
    },
    onClear (close) {
      this.dialogState = !close;
      if (close) {
        this.modelCopy = null;
        this.$emit('filterDialog_clear');
      } else {
        this.modelCopy.clear();
        this.modelsFiltersSet = false;
        this.modelCopy.filterFunc = this.getFilteredProducts;
        (this.type === 'product') ? this.processProducts() : this.processAccessories();

        this.modelCopy.priceRange[0] = this.minPrice;
        this.modelCopy.priceRange[1] = this.maxPrice;
        this.modelCopy.availabilityRange[0] = this.minAvailability;
        this.modelCopy.availabilityRange[1] = this.maxAvailability;

        this.priceSliderRange[0] = 0;
        this.priceSliderRange[1] = 100;
        this.availabilitySliderRange[0] = 0;
        this.availabilitySliderRange[1] = 100;

        this.updatedFilterOptions();
      }
    },
    onCancel () {
      this.dialogState = false;
      this.modelCopy = null;
      this.$emit('filterDialog_cancel');
    },
    processAccessories (accessories) {
      const accTypes = new Set();
      const colors = new Set();
      const models = new Set();
      const conditions = new Set();
      this.minPrice = 0;
      this.maxPrice = 0;
      this.minAvailability = 0;
      this.maxAvailability = 0;

      const accessoriesToProcess = (accessories && accessories.length > 0) ? accessories : this.products;
      accessoriesToProcess.forEach(product => {
        if (product.type) {
          accTypes.add(product.type);
        }

        if (product.color) {
          colors.add(product.color.toUpperCase());
        }

        if (product.condition) {
          conditions.add(product.condition);
        }

        if (product.model) {
          models.add(product.model);
        }

        if (!this.minAvailability || product.stock < this.minAvailability) {
          this.minAvailability = product.stock;
        }

        if (!this.maxAvailability || product.stock > this.maxAvailability) {
          this.maxAvailability = product.stock;
        }

        product.price = parseFloat(product.price);
        if (!this.minPrice || product.price < this.minPrice) {
          this.minPrice = product.price;
        }

        if (!this.maxPrice || product.price > this.maxPrice) {
          this.maxPrice = product.price;
        }
      });

      this.accTypes = [...accTypes].sort();
      this.colors = [...colors].sort();
      this.models = [...models].sort();
      this.conditions = [...conditions].sort();

      if (this.modelCopy && !this.modelCopy.priceRangeIsSet) {
        this.modelCopy.priceRange[0] = this.minPrice;
        this.modelCopy.priceRange[1] = this.maxPrice;
      }

      if (this.modelCopy && !this.modelCopy.availabilityRangeIsSet) {
        this.modelCopy.availabilityRange[0] = this.minAvailability;
        this.modelCopy.availabilityRange[1] = this.maxAvailability;
      }
    },
    processProducts (products) {
      const brands = new Set();
      const functionalities = new Set();
      const colors = new Set();
      const models = new Set();
      const storageCapacties = new Set();
      const carriers = new Set();
      const conditions = new Set();
      const lockStatusSet = new Set();
      this.minPrice = 0;
      this.maxPrice = 0;
      this.minAvailability = 0;
      this.maxAvailability = 0;

      const productsToProcess = (products && products.length > 0) ? products : this.products;
      productsToProcess.forEach(product => {
        if (product.brand) {
          brands.add(product.brand);
        }

        // Use "storefrontmodel" instead of "model"
        const model = product.model;
        if (model) {
          const i = model.indexOf('(');
          if (i >= 0) {
            // Grouping the models by removing the specific type ie Galaxy Note 9 (N960F) -> Galaxy Note 9
            models.add(model.slice(0, i - 1).trim());
          } else {
            models.add(model);
          }
        }

        if (product.color) {
          colors.add(product.color.toUpperCase());
        }

        if (product.storagecapacity) {
          storageCapacties.add(product.storagecapacity);
        }

        if (product.carrier) {
          carriers.add(product.carrier);
        }

        if (product.condition) {
          conditions.add(product.condition);
        }

        // Lock Status:
        // if [sku] contains " UUU " (with spaces both before and after UUU) then display "GSM Unlocked"
        // if [sku] contains "LLL" then display "Locked"
        // if [sku] contains "UUUVZN" then display "Verizon Unlocked"
        // if [sku] contains "UUUSPT" then display "CDMA/GSM Unlocked" (edited)
        // if [sku] contains "MVNO" then display "MVNO Unlocked"
        // if [sku] contains "FACTUUU" then display "Factory Unlocked"
        let lockStatus = '';
        if (product.sku.toUpperCase().includes(' UUU ')) {
          lockStatus = 'GSM Unlocked';
        } else if (product.sku.toUpperCase().includes('LLL')) {
          lockStatus = 'Locked';
        } else if (product.sku.toUpperCase().includes('UUUVZN')) {
          lockStatus = 'Verizon Unlocked';
        } else if (product.sku.toUpperCase().includes('UUUSPT')) {
          lockStatus = 'CDMA/GSM Unlocked';
        } else if (product.sku.toUpperCase().includes('MVNO')) {
          lockStatus = 'MVNO Unlocked';
        } else if (product.sku.toUpperCase().includes('FACTUUU')) {
          lockStatus = 'Factory Unlocked';
        }

        if (lockStatus) {
          lockStatusSet.add(lockStatus);
        }

        // Functionality:
        // if [sku] contains "pzn" then display "Issue"
        // if [sku] contains "lwi" then display "Defective"
        // if neither of the above is true, then display "Fully Functional"
        // can we make the  field a link that would open up the [notes] field?
        let functionality = 'Fully Functional';
        if (product.sku.toLowerCase().includes('pzn')) {
          functionality = 'Issue';
        } else if (product.sku.toLowerCase().includes('lwi')) {
          functionality = 'Defective';
        }

        if (functionality) {
          functionalities.add(functionality);
        }

        if (!this.minAvailability || product.stock < this.minAvailability) {
          this.minAvailability = product.stock;
        }

        if (!this.maxAvailability || product.stock > this.maxAvailability) {
          this.maxAvailability = product.stock;
        }

        product.price = parseFloat(product.price);
        if (!this.minPrice || product.price < this.minPrice) {
          this.minPrice = product.price;
        }

        if (!this.maxPrice || product.price > this.maxPrice) {
          this.maxPrice = product.price;
        }
      });

      this.brands = [...brands].sort();
      this.functionalities = [...functionalities].sort();
      this.colors = [...colors].sort();
      this.models = [...models].sort();
      this.storage = [...storageCapacties].sort((a, b) => {
        const re = /[0-9]+/;
        const storageA = a.match(re);
        const storageB = b.match(re);
        const numA = Number(storageA);
        const numB = Number(storageB);

        if (numA > numB) {
          return 1;
        } else if (numA < numB) {
          return -1;
        }

        return 0;
      });
      this.carriers = [...carriers].sort();
      this.conditions = [...conditions].sort();
      this.lockStatus = [...lockStatusSet].sort();

      if (this.modelCopy && !this.modelCopy.priceRangeIsSet) {
        this.modelCopy.priceRange[0] = this.minPrice;
        this.modelCopy.priceRange[1] = this.maxPrice;
      }

      if (this.modelCopy && !this.modelCopy.availabilityRangeIsSet) {
        this.modelCopy.availabilityRange[0] = this.minAvailability;
        this.modelCopy.availabilityRange[1] = this.maxAvailability;
      }
    },
    getFilteredProducts (products, model) {
      if (!this.products && !products) {
        return;
      }

      let filteredProducts = (products) ? [...products] : [...this.products];

      if (model) {
        this.modelCopy = clone(model);
      }

      const mobileFilterByKeyValues = (products, key, values) => {
        return products.filter(product => values.includes(product[key]));
      };

      const mobileGroupFilterByKeyValues = (products, key, values, excludeSet) => {
        return products.filter(product => {
          for (const i in values) {
            if (product[key].includes(values[i]) && !excludeSet.has(product[key])) {
              return product;
            }
          }
        });
      };

      const mobileFilterByRange = (products, key, min, max) => {
        return products.filter(product => product[key] >= min && product[key] <= max);
      };

      this.filteredProductsQuantity = 0;

      if (this.modelCopy.brands && this.modelCopy.brands.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'brand', this.modelCopy.brands);
      }

      if (this.modelCopy.priceRange && this.modelCopy.priceRangeIsSet) {
        filteredProducts = mobileFilterByRange(filteredProducts, 'price', this.modelCopy.priceRange[0], this.modelCopy.priceRange[1]);
      }

      if (this.modelCopy.availabilityRange && this.modelCopy.availabilityRangeIsSet) {
        filteredProducts = mobileFilterByRange(filteredProducts, 'stock', this.modelCopy.availabilityRange[0], this.modelCopy.availabilityRange[1]);
      }

      if (this.modelCopy.functionalities && this.modelCopy.functionalities.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'functionality', this.modelCopy.functionalities);
      }

      if (this.modelCopy.accTypes && this.modelCopy.accTypes.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'type', this.modelCopy.accTypes);
      }

      if (this.modelCopy.colors && this.modelCopy.colors.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'color', this.modelCopy.colors);
      }

      if (this.modelCopy.models && this.modelCopy.models.length > 0) {
        let exclued = [];
        const models = filteredProducts.map(product => product.model);
        exclued = new Set(models.filter(model => {
          let exclude = false;
          this.modelCopy.models.forEach(selectedModel => {
            exclude = model.includes(selectedModel) && model !== selectedModel;
          });

          return exclude;
        }));
        filteredProducts = mobileGroupFilterByKeyValues(filteredProducts, 'model', this.modelCopy.models, exclued);
      }

      if (this.modelCopy.storage && this.modelCopy.storage.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'storagecapacity', this.modelCopy.storage);
      }

      if (this.modelCopy.carriers && this.modelCopy.carriers.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'carrier', this.modelCopy.carriers);
      }

      if (this.modelCopy.conditions && this.modelCopy.conditions.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'condition', this.modelCopy.conditions);
      }

      if (this.modelCopy.lockStatus && this.modelCopy.lockStatus.length > 0) {
        filteredProducts = mobileFilterByKeyValues(filteredProducts, 'lockstatus', this.modelCopy.lockStatus);
      }

      filteredProducts.forEach(product => {
        this.filteredProductsQuantity += product.stock;
      });

      return filteredProducts;
    },
    updatedFilterOptions (delay) {
      const updateFunc = () => {
        this.loadingOverlay(true);
        const filteredProducts = this.getFilteredProducts();
        (this.type === 'product') ? this.processProducts(filteredProducts) : this.processAccessories(filteredProducts);
        this.loadingOverlay(false);
        this.actionButtonsDisabled = false;
      };

      if (this.filterUpdateTimer) {
        clearTimeout(this.filterUpdateTimer);
      }

      this.actionButtonsDisabled = true;

      if (delay === undefined) {
        updateFunc.apply(this);
      } else {
        this.filterUpdateTimer = setTimeout(updateFunc.bind(this), this.filterUpdateDelay);
      }
    },
    onSetModelFilter () {
      this.modelsFiltersSet = true;
      this.modelsFiltersUpdated = false;
      this.updatedFilterOptions();
    },
    onClearModelFilter () {
      if (this.modelsFiltersSet) {
        this.modelCopy.appliedFilters--;
        this.modelsFiltersSet = false;
        this.modelsFiltersUpdated = false;
        this.modelCopy.models.splice(0);
        this.updatedFilterOptions();
      }
    }
  },
  watch: {
    products (newValue, oldValue) {
      this.products = newValue;
      (this.type === 'product') ? this.processProducts() : this.processAccessories();
    },
    priceSliderRange (newValue, oldValue) {
      const delta = this.maxPrice - this.minPrice;
      const minPercentage = this.priceSliderRange[0] / 100;
      const maxPercentage = this.priceSliderRange[1] / 100;

      if (newValue[0] !== oldValue[0]) {
        this.modelCopy.priceRange[0] = Math.ceil(this.minPrice + (delta * minPercentage));
      }

      if (newValue[1] !== oldValue[1]) {
        this.modelCopy.priceRange[1] = Math.ceil(this.minPrice + (delta * maxPercentage));
      }
    },
    availabilitySliderRange (newValue, oldValue) {
      const delta = this.maxAvailability - this.minAvailability;
      const minPercentage = this.availabilitySliderRange[0] / 100;
      const maxPercentage = this.availabilitySliderRange[1] / 100;

      if (newValue[0] !== oldValue[0]) {
        this.modelCopy.availabilityRange[0] = Math.ceil(this.minAvailability + (delta * minPercentage));
      }

      if (newValue[1] !== oldValue[1]) {
        this.modelCopy.availabilityRange[1] = Math.ceil(this.minAvailability + (delta * maxPercentage));
      }
    }
  }
};
</script>
<style scoped>
.isActive {
  border: 3px solid #e91e63;
}

.v-select {
  min-width: 100%;
}
</style>
