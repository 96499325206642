import Vue from 'vue';
import { creditsService } from '@/_services';

const state = {
  balancesByUsername: {},
  allCredits: []
};

const actions = {
  getCreditByUsername ({ commit }, username) {
    return new Promise((resolve, reject) => {
      creditsService.getCreditBalanceByUserName(username).then(
        (response) => {
          // http success, call the mutator and change something in state
          commit('setCreditByUsername', { credit: response.credit });
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  createCredit ({ commit }, credit) {
    return new Promise((resolve, reject) => {
      creditsService.createCredit(credit).then(
        (response) => {
          console.log(response);
          // http success, call the mutator and change something in state
          commit('setCreditByUsername', { credit: response.credit });
          commit('addCredit', { credit: response.credit });
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  getBalances ({ commit }) {
    return new Promise((resolve, reject) => {
      creditsService.getBalances().then(
        (response) => {
          console.log(response);
          // http success, call the mutator and change something in state
          commit('setBalances', { balances: response.balances });
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  },
  getAllCredits ({ commit }) {
    return new Promise((resolve, reject) => {
      creditsService.getAllCredits().then(
        (response) => {
          // http success, call the mutator and change something in state
          commit('setAllCredits', { credits: response.credits });
          resolve(response); // Let the calling function know that http is done. You may send some data back
        },
        (error) => {
          // http failed, let the calling function know that action did not work out
          reject(error);
        }
      );
    });
  }
};

const mutations = {
  setCreditByUsername (state, { credit }) {
    Vue.set(state.balancesByUsername, credit.username, credit);
  },
  setBalances (state, { balances }) {
    state.balancesByUsername = balances;
  },
  setAllCredits (state, { credits }) {
    state.allCredits = credits;
  },
  addCredit (state, { credit }) {
    state.allCredits.push(credit);
  }
};

export const credits = {
  namespaced: true,
  state,
  actions,
  mutations
};
